import {format, isToday} from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {isLastWeek} from 'tools';
import CallCard from 'view/components/CallCard';
import {Call} from 'types/calls';
import Loader from 'view/components/Loader';

type CallsListProps = {
  calls: Call[];
  fetchNextCalls: () => void;
  allCallsLoaded: boolean;
  onClickRemoveCall: (call: Call) => void;
};

export default function CallsList({
  calls,
  fetchNextCalls,
  allCallsLoaded,
  onClickRemoveCall,
}: CallsListProps) {
  if (!calls?.length) {
    return <Typography variant="body2">No calls yet.</Typography>;
  }

  const callsGroupedByDate = calls.reduce((acc, call) => {
    if (isToday(new Date(call.createdAt))) {
      (acc['today'] ||= []).push(call);
      return acc;
    }
    if (isLastWeek(new Date(call.createdAt))) {
      (acc['last week'] ||= []).push(call);
      return acc;
    }
    (acc[format(new Date(call.createdAt), 'MMMM, d, yyyy')] ||= []).push(call);
    return acc;
  }, {} as {[date: string]: Call[]});

  const sortCallsByDate = (calls: string[]) => {
    return [
      ...calls.filter((date) => date === 'today'),
      ...calls.filter((date) => date === 'last week'),
      ...calls.filter((date) => date !== 'today' && date !== 'last week'),
    ];
  };

  const handleSummaryName = (date: string) => {
    if (date === 'today' || date === 'last week') {
      return date;
    }
    return format(new Date(date), 'MMMM, d');
  };

  return (
    <InfiniteScroll
      dataLength={calls.length}
      next={fetchNextCalls}
      hasMore={allCallsLoaded}
      loader={
        <Box my={2}>
          <Loader />
        </Box>
      }
      style={{overflow: 'visible'}}
    >
      <Box mb={5}>
        {sortCallsByDate(Object.keys(callsGroupedByDate))
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
          .map((dateName: string) => (
            <Box mb={2} key={dateName}>
              <Typography variant="subtitle1" mb={0.5}>
                {handleSummaryName(dateName)}
              </Typography>
              {callsGroupedByDate[dateName]
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime(),
                )
                .map((call, index, calls) => (
                  <CallCard
                    key={call.id}
                    call={call}
                    onClickRemoveCall={() => onClickRemoveCall(call)}
                    divider={calls.length > index + 1}
                  />
                ))}
            </Box>
          ))}
      </Box>
    </InfiniteScroll>
  );
}
