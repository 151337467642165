import {HeadCell} from 'view/components/Table/components/TableHeader';

export interface Data {
  firstName: string;
  username: string;
  id: string;
  createdAt: string;
}

export const headCells: HeadCell<Data>[] = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'User & status',
    isSortable: true,
    width: 350,
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Username',
    isSortable: true,
    width: 300,
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Registration date',
    isSortable: true,
    width: 170,
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'User ID',
    isSortable: true,
    width: 310,
  },
];
