import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import {green, purple} from 'view/theme/colors';
import {ReactNode} from 'react';

export const StyledCircle = styled(Box)`
  &.MuiBox-root {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    min-width: 2rem;
    padding: 0.25rem;
    background: ${purple};
    outline: 8px solid rgba(72, 83, 231, 0.24);
    cursor: pointer;

    &.on-the-call {
      background: ${green};
      outline: 8px solid rgba(81, 168, 115, 0.24);
    }

    // Responsive square
    :after {
      content: '';
      padding-bottom: 100%;
    }
  }
`;

type ChipProps = {
  children: ReactNode;
  isOnCall: boolean;
  onClick: () => void;
};

export default function Circle({children, onClick, isOnCall}: ChipProps) {
  return (
    <StyledCircle onClick={onClick} className={isOnCall ? 'on-the-call' : ''}>
      {children}
    </StyledCircle>
  );
}
