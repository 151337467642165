import React, {SetStateAction} from 'react';
import Drawer from 'view/components/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {getFullname, getLastArrayElement} from 'tools';
import UserDetails from 'view/pages/AdminUsers/components/UserDetails';
import capitalize from 'lodash.capitalize';
import {renderUserStatusCell} from 'view/pages/AdminDevices/components/DevicesTable';
import DeviceDetails from 'view/pages/AdminDevices/components/DeviceDetails';
import useAdmin from 'hooks/admin';
import {DeviceWithUser} from 'types/devices';
import {PaginatedResponse} from 'services/api/types';
import {HistoryType} from 'types/admin';
import Button from 'view/components/Button';
import TrashIcon from 'view/components/icons/Trash';
import {User} from 'types/users';

type AdminDrawerProps = {
  setDevices?: (
    device: SetStateAction<PaginatedResponse<DeviceWithUser>>,
  ) => void;
  setUserToDelete?: (user: User) => void;
  onEditUser?: (user: User) => void;
};
export default function AdminDrawer({
  setDevices,
  setUserToDelete,
  onEditUser,
}: AdminDrawerProps) {
  const {drawerHistory} = useAdmin();

  const {selectedItem} = getLastArrayElement(drawerHistory) || {};

  if (!selectedItem) {
    return <></>;
  }
  const selectedUser =
    selectedItem?.drawerType === HistoryType.USER && selectedItem;
  const selectedDevice =
    selectedItem?.drawerType === HistoryType.DEVICE && selectedItem;

  if (selectedUser) {
    return (
      <Drawer
        variant="temporary"
        marginTop="0"
        height="100vh"
        width={'calc(100% - 20rem)'}
        top={'3.5rem'}
        open
        title={
          <>
            <Box mr={3} sx={{minWidth: '4rem', overflowWrap: 'break-word'}}>
              <Typography variant="h1" mr={2}>
                {getFullname(selectedUser)}, @{selectedUser.username}
              </Typography>
            </Box>
            <Button
              label="Delete user"
              variant="text"
              color="error"
              endIcon={<TrashIcon />}
              onClick={
                setUserToDelete
                  ? () => setUserToDelete(selectedUser)
                  : undefined
              }
            />
          </>
        }
        action={<UserDetails user={selectedUser} onEditUser={onEditUser} />}
      />
    );
  }
  if (selectedDevice) {
    return (
      <Drawer
        variant="temporary"
        marginTop="0"
        height="100vh"
        width={'calc(100% - 20rem)'}
        top={'3.5rem'}
        open
        title={
          <>
            <Typography variant="h1" mr={2}>
              {capitalize(selectedDevice.deviceType)}, {selectedDevice.id}
            </Typography>
            {selectedDevice.user &&
              renderUserStatusCell(selectedDevice.user.status)}
          </>
        }
        action={
          <DeviceDetails device={selectedDevice} setDevices={setDevices} />
        }
      />
    );
  }
  return <></>;
}
