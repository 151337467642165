import {Box} from '@mui/material';
import {GoogleMap} from '@react-google-maps/api';
import React, {useMemo, useState} from 'react';
import {MapType} from 'types/map';
import {containerStyle} from '../../styled';
import DevicesMarker from '../Markers';
import {MapTheme} from '../Theme/styled';

const Map = ({origins}: MapType) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [Id, setId] = useState<string | undefined>();
  const [activeClaraModal, setClaraModal] = useState(null);
  const [selectedOriginId, setSelectedOriginId] = React.useState<any>('');

  const mapRef = React.useRef<any>(undefined);

  const onLoad = React.useCallback(function callback(map?: any) {
    mapRef.current = map;
  }, []);

  const onUnmount = React.useCallback(function callback(map?: any) {
    mapRef.current = undefined;
  }, []);

  const defaultOptions = {
    panControl: false,
    zoomControl: false,
    zoomControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM,
    },
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    clickableIcons: false,
    keyboardShortcuts: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    fullscreenControl: false,
    styles: MapTheme,
    gestureHandling: 'greedy',
    minZoom: 2.43,
    maxZoom: 2.53,
  };

  const centerLocation = useMemo(() => {
    return {
      lat: 41.365429,
      lng: 128.288819,
    };
  }, []);

  // CLARA
  function clearRoute() {
    setSelectedOriginId('');
  }
  // END

  return (
    <Box
      sx={{
        height: '100%',
        // hide "This page can't load Google Maps correctly." popup
        '#map > div:nth-child(2)': {display: 'none !important'},
      }}
    >
      <GoogleMap
        id="map"
        mapContainerStyle={containerStyle}
        center={centerLocation}
        options={defaultOptions}
        zoom={2.4}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={() => {
          setClaraModal(null);
        }}
      >
        {drawerOpen ? (
          <Box
            style={{
              position: 'absolute',
              height: 'calc(100vh - 4rem)',
              width: 'calc(100% )',
              backgroundColor: '#00000043',
            }}
          />
        ) : null}

        <>
          <DevicesMarker
            Id={Id}
            setId={setId}
            origins={origins}
            drawerOpen={drawerOpen}
            clearRoute={clearRoute}
            setClaraModal={setClaraModal}
            setDrawerOpen={setDrawerOpen}
            selectedOriginId={selectedOriginId}
            setSelectedOriginId={setSelectedOriginId}
            activeClaraModal={activeClaraModal}
          />
        </>
      </GoogleMap>
    </Box>
  );
};

export default Map;
