import {purple} from 'view/theme/colors';

export default function KeyIcon() {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none">
      <path
        d="M28.7375 0.470215C20.5946 0.470215 13.9695 7.09534 13.9695 15.2366C13.9695 17.0387 14.3042 18.7807 14.9315 20.4382L0.962957 34.6391C0.663957 34.943 0.496582 35.3525 0.496582 35.7782V41.9028C0.496582 42.7998 1.22296 43.5278 2.12158 43.5278H11.9772C12.8758 43.5278 13.6022 42.7998 13.6022 41.9028V38.1686H17.3365C18.2351 38.1686 18.9615 37.4406 18.9615 36.5436V32.5217H23.6528C24.5515 32.5217 25.2778 31.7937 25.2778 30.8967V29.5756C26.404 29.847 27.5577 29.9997 28.7375 29.9997C36.8787 29.9997 43.5022 23.3762 43.5022 15.2333C43.5022 7.09047 36.8787 0.470215 28.7375 0.470215V0.470215ZM17.2211 15.2366C17.2211 8.88609 22.3886 3.72022 28.7391 3.72022C35.088 3.72022 40.2538 8.88609 40.2538 15.2366C40.2538 21.5871 35.088 26.753 28.7391 26.753C27.1953 26.753 25.7003 26.454 24.2915 25.8641C24.0965 25.7812 23.8803 25.7341 23.6545 25.7341C22.7558 25.7341 22.0295 26.4621 22.0295 27.3591V29.275H17.3381C16.4395 29.275 15.7131 30.003 15.7131 30.9V34.9218H11.9788C11.0802 34.9218 10.3538 35.6498 10.3538 36.5468V40.2811H3.74821V36.4461L17.72 22.2403C17.72 22.2403 18.8233 21.3385 18.0758 19.5981C17.1951 17.5473 17.2211 15.2366 17.2211 15.2366V15.2366ZM31.5633 6.59484C28.3296 6.59484 25.6987 9.22734 25.6987 12.4611C25.6987 15.6948 28.3296 18.3273 31.5633 18.3273C34.8003 18.3273 37.4328 15.6948 37.4328 12.4611C37.4328 9.22734 34.8003 6.59484 31.5633 6.59484V6.59484ZM31.5633 15.079C30.1203 15.079 28.9487 13.9057 28.9487 12.4627C28.9487 11.0197 30.122 9.84647 31.5633 9.84647C33.008 9.84647 34.1828 11.0197 34.1828 12.4627C34.1828 13.9057 33.0063 15.079 31.5633 15.079V15.079Z"
        fill={purple}
      />
    </svg>
  );
}
