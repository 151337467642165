import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type FormHeaderProps = {
  title: string;
  description: string;
  mb?: number;
  mt?: number;
};

export default function FormHeader({
  title,
  description,
  mt,
  mb,
}: FormHeaderProps) {
  return (
    <Box mb={mb} mt={mt}>
      <Typography variant="h2">{title}</Typography>
      <Typography variant="body2">{description}</Typography>
    </Box>
  );
}
