import {OverlayView, Polyline} from '@react-google-maps/api';
import includes from 'lodash.includes';
import {AdminContext} from 'contexts/admin';
import {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import Drawer from 'view/components/Drawer';
import MapDeviceDetails from 'view/components/MapDrawer';
import Circle from '../Circle';
import {mapSettings} from '../Polyline';
import {getGroupedMarkersByLocation, getMapPixelPositionOffset} from 'tools';
import Typography from '@mui/material/Typography';
import {white} from 'view/theme/colors';
import GroupedMarker from 'view/pages/AdminMap/components/GroupedMarker';
import {MapOrigin, MapOriginCall, PolylineType} from 'types/map';
import {DeviceLocation, DEVICE_CALL_STATUS} from 'types/devices';
import {mapMarkersGroupsAccuracy} from 'view/constants';

type DevicesMarkerProps = {
  setClaraModal: Dispatch<SetStateAction<null>>;
  activeClaraModal: string | null;
  setSelectedOriginId: Dispatch<SetStateAction<string>>;
  selectedOriginId: string;
  origins: MapOrigin[];
  drawerOpen: boolean;
  setDrawerOpen: (arg: boolean) => void;
  clearRoute: () => void;
  setId: any;
  Id: any;
};

const DevicesMarker = ({
  setClaraModal,
  activeClaraModal,
  origins,
  setSelectedOriginId,
  selectedOriginId,
  setDrawerOpen,
  clearRoute,
  drawerOpen,
  setId,
  Id,
}: DevicesMarkerProps) => {
  const params = new URLSearchParams(window.location.search);
  const urlDeviceId = params.get('deviceId');
  const {selectMapDeviceId, deviceInfoMap} = useContext(AdminContext);
  const [selectedDeviceInterval, setSelectedDeviceInterval] =
    useState<NodeJS.Timer>();
  const [selectedGroupedMarker, setSelectedGroupedMarker] = useState('');

  useEffect(() => {
    if (urlDeviceId) {
      openDrawerOrigin(urlDeviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlDeviceId]);

  const handleActiveMarker = (marker?: any) => {
    if (marker === activeClaraModal) {
      return;
    }
    setClaraModal(marker);
  };

  const closeDrawerOrigin = () => {
    setSelectedOriginId('');
    setSelectedGroupedMarker('');
    setDrawerOpen(false);
    setClaraModal(null);
    setId('');
    selectMapDeviceId('');
    // remove deviceId url param
    window.history.replaceState(null, '', window.location.pathname);
  };

  const openDrawerOrigin = (id?: string) => {
    handleActiveMarker(id);
    setId(id);
    setDrawerOpen(true);
  };

  const callDevices = deviceInfoMap?.call?.devices.map((e) => e?.id);

  const destinations: MapOrigin[] = callDevices
    ? callDevices.reduce((acc, callDevice) => {
        const origin = origins.find(({id}) => callDevice === id);
        if (origin) {
          return [...acc, origin];
        }
        return acc;
      }, [] as MapOrigin[])
    : [];

  const selectedOrigin = origins.find(
    ({id}: MapOrigin) => selectedOriginId === id,
  );

  const getLatLngPolyline = ({
    origin,
    destination,
  }: PolylineType): [DeviceLocation, DeviceLocation] => [
    {lat: origin.lat, lng: origin.lng},
    {lat: destination.lat, lng: destination.lng},
  ];

  useEffect(() => {
    if (!Id) {
      return selectedDeviceInterval && clearInterval(selectedDeviceInterval);
    }

    const interval = setInterval(() => {
      selectMapDeviceId(Id);
    }, 500);
    setSelectedDeviceInterval(interval);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Id]);

  const groupedMarkers = getGroupedMarkersByLocation(
    origins,
    mapMarkersGroupsAccuracy,
  );

  const calls: MapOriginCall[] = origins.reduce(
    (acc: MapOriginCall[], origin: MapOrigin) => {
      const isNewCall =
        Boolean(origin.call) &&
        !includes(
          acc.map(({id}) => id),
          origin.call?.id,
        );
      if (isNewCall) {
        return [...acc, origin.call as MapOriginCall];
      }
      return acc;
    },
    [] as MapOriginCall[],
  );

  return (
    <>
      {Object.keys(groupedMarkers).map((markerKey, index) => {
        const locationDevices = groupedMarkers[markerKey];
        const isOnCall = locationDevices.some(
          (devices) => devices.callStatus === DEVICE_CALL_STATUS.On_the_call,
        );
        return (
          <OverlayView
            key={`grouped-marker-${index}`}
            position={JSON.parse(markerKey)}
            mapPaneName="floatPane"
            getPixelPositionOffset={getMapPixelPositionOffset}
            children={
              <Circle
                onClick={() => {
                  if (locationDevices.length === 1) {
                    openDrawerOrigin(locationDevices[0].id);
                  } else {
                    setSelectedOriginId('');
                    setId('');
                    setSelectedGroupedMarker(markerKey);
                    setDrawerOpen(true);
                  }
                }}
                isOnCall={isOnCall}
              >
                <Typography variant="body2" color={white} fontWeight={700}>
                  {locationDevices.length}
                </Typography>
              </Circle>
            }
          />
        );
      })}
      {selectedGroupedMarker && groupedMarkers[selectedGroupedMarker] && (
        <Drawer
          minPadding
          variant="persistent"
          open={drawerOpen}
          width="42%"
          marginTop="4rem"
          height="calc(100vh - 4rem)"
          top="4.6rem"
          title={<></>}
          onClose={closeDrawerOrigin}
          action={
            <GroupedMarker
              data={groupedMarkers[selectedGroupedMarker]}
              openDrawerOrigin={openDrawerOrigin}
              setId={setId}
              setSelectedOriginId={setSelectedOriginId}
            />
          }
        />
      )}

      {Id && (
        <Drawer
          minPadding
          variant="persistent"
          open={drawerOpen}
          width="42%"
          marginTop="4rem"
          height="calc(100vh - 4rem)"
          top="4.6rem"
          title={<></>}
          onClose={closeDrawerOrigin}
          action={
            <MapDeviceDetails
              origins={origins}
              visible={Id}
              selectedOriginId={selectedOriginId}
              showPolyline={() => {
                Id && setSelectedOriginId(Id);
              }}
              hidePolyline={() => {
                clearRoute();
              }}
            />
          }
          backButtonStyle={{top: -48, left: -23}}
          onBackButtonClick={
            selectedGroupedMarker
              ? () => {
                  if (selectedGroupedMarker) {
                    setSelectedOriginId('');
                    setId('');
                    setSelectedGroupedMarker(selectedGroupedMarker);
                    setDrawerOpen(true);
                  }
                }
              : undefined
          }
        />
      )}

      {selectedOrigin?.callStatus === DEVICE_CALL_STATUS.On_the_call &&
        destinations?.map(({id, location}: MapOrigin) => {
          return (
            <Polyline
              key={id}
              path={getLatLngPolyline({
                origin: selectedOrigin.location,
                destination: location,
              })}
              options={mapSettings.polylineOptions.dashed}
            />
          );
        })}
      {calls?.map(({id, devices}) => {
        const polylines = [] as DeviceLocation[];

        devices.forEach((device) => {
          const position = Object.keys(groupedMarkers).find((key) => {
            return groupedMarkers[key].map(({id}) => id).includes(device.id);
          });

          if (position) {
            polylines.push(JSON.parse(position));
          }
        });

        const path = polylines.sort((locationA, locationB) => {
          if (locationA.lat === locationB.lat)
            return locationA.lng - locationB.lng;
          return locationA.lat - locationB.lat;
        });

        return (
          <Polyline
            key={`${id}`}
            path={path}
            options={mapSettings.polylineOptions.dashed}
          />
        );
      })}
    </>
  );
};

export default DevicesMarker;
