import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {User} from 'types/users';
import UserCard from 'view/components/UserCard';
import Loader from 'view/components/Loader';
import {StyledChip} from './styled';
import ContactCard from 'view/components/ContactCard';
import {Contact} from 'types/contacts';

type UsersListProps = {
  totalUsers: number;
  users: Array<User & {contactId?: string}>;
  allUsersLoaded: boolean;
  fetchNextUsers: () => void;
  onAddContact: (user: User) => void;
  onClickRemoveContact: (contact: Contact) => void;
};

export default function UsersList({
  users,
  totalUsers,
  onAddContact,
  onClickRemoveContact,
  fetchNextUsers,
  allUsersLoaded,
}: UsersListProps) {
  return (
    <InfiniteScroll
      dataLength={users.length}
      next={fetchNextUsers}
      hasMore={allUsersLoaded}
      loader={
        <Box my={2}>
          <Loader />
        </Box>
      }
      style={{overflow: 'visible'}}
    >
      <Box mb={2}>
        <Box display="flex">
          <Typography
            variant="subtitle1"
            mb={0.5}
            sx={{display: 'flex', alignItems: 'center'}}
          >
            {totalUsers ? `Contacts found` : 'No contacts found'}
            {!!totalUsers && <StyledChip label={totalUsers} size="small" />}
          </Typography>
        </Box>
        {users.map((user, index, users) => {
          if (user.contactId) {
            return (
              <ContactCard
                id={user.contactId}
                key={user.contactId}
                firstName={user.firstName}
                lastName={user.lastName}
                username={user.username}
                status={user.status}
                onClickRemoveContact={() =>
                  onClickRemoveContact({...user, id: user.contactId as string})
                }
                divider={users.length > index + 1}
              />
            );
          }

          return (
            <UserCard
              key={user.id}
              firstName={user.firstName}
              lastName={user.lastName}
              username={user.username}
              status={user.status}
              onAddContact={
                !user.contactId ? () => onAddContact(user) : undefined
              }
              divider={users.length > index + 1}
            />
          );
        })}
      </Box>
    </InfiniteScroll>
  );
}
