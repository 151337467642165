import {black54} from 'view/theme/colors';

export default function SortIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{marginLeft: '0.5rem'}}
    >
      <path
        d="M10.86 9.52663L8.00004 12.3933L5.14004 9.52663C5.01451 9.40109 4.84424 9.33056 4.66671 9.33056C4.48917 9.33056 4.31891 9.40109 4.19338 9.52663C4.06784 9.65216 3.99731 9.82242 3.99731 9.99996C3.99731 10.1775 4.06784 10.3478 4.19338 10.4733L7.52671 13.8066C7.58868 13.8691 7.66242 13.9187 7.74366 13.9526C7.8249 13.9864 7.91203 14.0038 8.00004 14.0038C8.08805 14.0038 8.17519 13.9864 8.25643 13.9526C8.33767 13.9187 8.4114 13.8691 8.47337 13.8066L11.8067 10.4733C11.8689 10.4111 11.9182 10.3373 11.9518 10.2561C11.9855 10.1749 12.0028 10.0879 12.0028 9.99996C12.0028 9.91205 11.9855 9.82501 11.9518 9.74379C11.9182 9.66258 11.8689 9.58878 11.8067 9.52663C11.7445 9.46447 11.6708 9.41516 11.5895 9.38152C11.5083 9.34788 11.4213 9.33056 11.3334 9.33056C11.2455 9.33056 11.1584 9.34788 11.0772 9.38152C10.996 9.41516 10.9222 9.46447 10.86 9.52663V9.52663ZM5.14004 6.47329L8.00004 3.60663L10.86 6.47329C10.922 6.53578 10.9958 6.58537 11.077 6.61922C11.1582 6.65307 11.2454 6.67049 11.3334 6.67049C11.4214 6.67049 11.5085 6.65307 11.5898 6.61922C11.671 6.58537 11.7447 6.53578 11.8067 6.47329C11.8692 6.41132 11.9188 6.33758 11.9526 6.25634C11.9865 6.1751 12.0039 6.08797 12.0039 5.99996C12.0039 5.91195 11.9865 5.82481 11.9526 5.74357C11.9188 5.66234 11.8692 5.5886 11.8067 5.52663L8.47337 2.19329C8.4114 2.13081 8.33767 2.08121 8.25643 2.04737C8.17519 2.01352 8.08805 1.99609 8.00004 1.99609C7.91203 1.99609 7.8249 2.01352 7.74366 2.04737C7.66242 2.08121 7.58868 2.13081 7.52671 2.19329L4.19338 5.52663C4.06784 5.65216 3.99731 5.82242 3.99731 5.99996C3.99731 6.17749 4.06784 6.34776 4.19338 6.47329C4.31891 6.59883 4.48917 6.66935 4.66671 6.66935C4.84424 6.66935 5.01451 6.59883 5.14004 6.47329Z"
        fill={black54}
      />
    </svg>
  );
}
