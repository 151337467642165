import {useState, useEffect, useCallback} from 'react';
import debounce from 'lodash.debounce';
import {
  addContact as addContactApi,
  listContacts,
  removeContact as removeContactApi,
} from 'services/api/contacts';
import {PaginatedResponse} from 'services/api/types';
import PageHeader from 'view/components/PageHeader';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import Loader from 'view/components/Loader';
import Alert from 'view/components/Alert';
import {User} from 'types/users';
import {Contact} from 'types/contacts';
import {StyledContainer} from './styled';
import {getFullname} from 'tools';
import Search from 'view/components/Search';
import {listUsers} from 'services/api/user';
import ContactsList from './components/ContactsList';
import UsersList from './components/UsersList';
import Box from '@mui/material/Box';
import useUser from 'hooks/user';
import axios, {AxiosError} from 'axios';

export default function Contacts() {
  const {contactsData, setContactsData, removeContact, addContact} = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResult, setSearchResult] = useState<
    PaginatedResponse<User & {contactId?: string}>
  >({
    items: [] as Array<User & {contactId?: string}>,
  } as PaginatedResponse<User>);
  const [contactToRemove, setContactToRemove] = useState<Contact | null>(null);

  const fetchNextContacts = async (nextLink?: string) => {
    if (nextLink) {
      try {
        const contactsData = await listContacts(nextLink);
        setContactsData(contactsData);
      } catch (err) {
        console.error(err);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchUsers = useCallback(
    debounce(async (searchQuery: string) => {
      setLoading(true);

      try {
        const searchResult = await listUsers({name: searchQuery});
        setSearchResult(searchResult);
        setLoading(false);
      } catch (err) {
        if (err instanceof Error) {
          console.error(err);
          setError(err.message);
          setLoading(false);
        }
      }
    }, 500),
    [],
  );

  const fetchNextUsers = async (nextLink?: string) => {
    if (nextLink) {
      try {
        const searchResult = await listUsers({
          url: nextLink,
          name: searchQuery,
        });
        setSearchResult(({items}) => ({
          ...searchResult,
          items: [...items, ...searchResult.items],
        }));
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (searchQuery) {
      setLoading(true);
      searchUsers(searchQuery);
    }
  }, [searchQuery, searchUsers]);

  const handleAddContact = async (user: User) => {
    try {
      const {id} = await addContactApi(user.id);
      addContact({...user, id});
      setSearchResult((prevState) => ({
        ...prevState,
        items: prevState.items.map((item) =>
          item.id === user.id ? {...item, contactId: id} : item,
        ),
      }));
    } catch (err) {
      console.error(err);

      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const handleRemoveContact = async (contact: Contact) => {
    try {
      await removeContactApi(contact.id);
      removeContact(contact.id);
      setSearchResult((prevState) => ({
        ...prevState,
        items: prevState.items.map((item) => {
          const {contactId, ...user} = item;
          return item.contactId === contact.id ? user : item;
        }),
      }));
      setContactToRemove(null);
    } catch (err) {
      console.error(err);

      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(e.target.value);

  const onClearSearch = () => setSearchQuery('');

  const onDeleteConfirmationClose = () => setContactToRemove(null);

  const onDeleteConfirmation = () =>
    handleRemoveContact(contactToRemove as Contact);

  return (
    <StyledContainer component="main">
      <PageHeader
        title="Contacts"
        action={
          <Box sx={{width: '20rem'}}>
            <Search
              name="contacts-search"
              small
              value={searchQuery}
              label="Search people"
              onChange={onSearchChange}
              onClear={onClearSearch}
            />
          </Box>
        }
      />
      <Alert open={!!error} type="error" position="right" />
      {contactToRemove && (
        <ConfirmationDialog
          open
          text={`Are you sure you want to delete ${getFullname(
            contactToRemove,
          )} / @${contactToRemove.username}?`}
          confirmText="Delete"
          onConfirm={onDeleteConfirmation}
          onClose={onDeleteConfirmationClose}
        />
      )}
      {loading ? (
        <Loader justify="flex-start" />
      ) : searchQuery ? (
        <UsersList
          totalUsers={searchResult.meta?.totalItems || 0}
          users={searchResult.items}
          fetchNextUsers={() => fetchNextUsers(searchResult.links?.next)}
          allUsersLoaded={!!searchResult.links?.next}
          onAddContact={(user) => handleAddContact(user)}
          onClickRemoveContact={(contact: Contact) =>
            setContactToRemove(contact)
          }
        />
      ) : (
        <ContactsList
          contacts={contactsData?.items}
          fetchNextContacts={() => fetchNextContacts(contactsData?.links?.next)}
          allContactsLoaded={!!contactsData?.links?.next}
          onClickRemoveContact={(contact: Contact) =>
            setContactToRemove(contact)
          }
        />
      )}
    </StyledContainer>
  );
}
