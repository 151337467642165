import {createContext, ReactElement, useEffect, useState} from 'react';
import {getDevices, getUser, listCalls} from 'services/api/user';
import {listContacts} from 'services/api/contacts';
import {Device} from 'types/devices';
import {Contact} from 'types/contacts';
import Alert from 'view/components/Alert';
import {PaginatedResponse} from 'services/api/types';
import {User} from 'types/users';
import {Call} from 'types/calls';
import useAuth from 'hooks/auth';

type UserData = {
  isLoading: boolean;
  devices: Device[];
  contactsData: PaginatedResponse<Contact>;
  callsData: PaginatedResponse<Call>;
  user: User;
};

export const UserContext = createContext({
  isLoading: true,
  devices: [] as Device[],
  contactsData: {} as PaginatedResponse<Contact>,
  callsData: {} as PaginatedResponse<Call>,
  user: {} as User,
  setUser: (user: User) => {},
  setDevices: (devices: Device[]) => {},
  setContactsData: (contacts: PaginatedResponse<Contact>) => {},
  removeCall: (id: string) => {},
  setCallsData: (contacts: PaginatedResponse<Call>) => {},
  disconnectDevice: (id: string) => {},
  removeContact: (id: string) => {},
  addContact: (user: Contact) => {},
});

type Props = {
  children: ReactElement;
};

const initialData = {
  isLoading: true,
  devices: [] as Device[],
  contactsData: {} as PaginatedResponse<Contact>,
  user: {} as User,
  callsData: {} as PaginatedResponse<Call>,
};

export const UserProvider = ({children}: Props) => {
  const user = useAuth();
  const [successMessage, setSuccessMessage] = useState('');
  const [userData, setUserData] = useState<UserData>(initialData);

  const setUser = (user: User = {} as User) =>
    setUserData((prevState) => ({...prevState, user}));

  const setDevices = (devices: Device[] = [] as Device[]) =>
    setUserData((prevState) => ({...prevState, devices}));

  const disconnectDevice = (id: string) => {
    setUserData((prevState) => {
      const updatedDevices = prevState.devices.filter(
        (device) => id !== device.id,
      );
      return {...prevState, devices: updatedDevices};
    });
    setSuccessMessage('Successfully disconnected device');
  };

  const setCallsData = (callsData: PaginatedResponse<Call>) =>
    setUserData((prevState) => ({
      ...prevState,
      callsData: {
        ...prevState.callsData,
        items: [...callsData.items, ...prevState.callsData.items],
        links: {...prevState.callsData.links, ...callsData.links},
        meta: {...prevState.callsData.meta, ...callsData.meta},
      },
    }));

  const removeCall = (id: string) => {
    setUserData((prevState) => {
      const updatedContacts = prevState.callsData.items.filter(
        (call) => id !== call.id,
      );
      return {
        ...prevState,
        callsData: {
          ...prevState.callsData,
          items: updatedContacts,
        },
      };
    });
    setSuccessMessage('Successfully deleted call');
  };

  const setContactsData = (contactsData: PaginatedResponse<Contact>) =>
    setUserData((prevState) => ({
      ...prevState,
      contactsData: {
        ...prevState.contactsData,
        items: [...contactsData.items, ...prevState.contactsData.items],
        links: {...prevState.contactsData.links, ...contactsData.links},
        meta: {...prevState.contactsData.meta, ...contactsData.meta},
      },
    }));

  const removeContact = (id: string) => {
    setUserData((prevState) => {
      const updatedContacts = prevState.contactsData.items.filter(
        (contact) => id !== contact.id,
      );
      return {
        ...prevState,
        contactsData: {
          ...prevState.contactsData,
          items: updatedContacts,
          meta: {
            ...prevState.contactsData.meta,
            totalItems: prevState.contactsData.meta.totalItems - 1,
            itemCount: prevState.contactsData.meta.itemCount - 1,
          },
        },
      };
    });
    setSuccessMessage('Successfully deleted contact');
  };

  const addContact = (user: Contact) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        contactsData: {
          ...prevState.contactsData,
          items: [...(prevState.contactsData.items || []), {...user}],
          meta: {
            ...prevState.contactsData.meta,
            totalItems: prevState.contactsData.meta.totalItems + 1,
            itemCount: prevState.contactsData.meta.itemCount + 1,
          },
        },
      };
    });
    setSuccessMessage('Successfully added contact');
  };

  useEffect(() => {
    if (!user.isAuthenticated) {
      return setUserData({...initialData, isLoading: false});
    }

    const getCurrentUserData = async () => {
      try {
        const user = await getUser();
        const devices = await getDevices();
        const contactsData = await listContacts();
        const callsData = await listCalls();
        return setUserData({
          isLoading: false,
          devices,
          contactsData,
          callsData,
          user,
        });
      } catch (err) {
        return setUserData({
          isLoading: false,
          devices: [],
          contactsData: {} as PaginatedResponse<Contact>,
          callsData: {} as PaginatedResponse<Call>,
          user: {} as User,
        });
      }
    };

    getCurrentUserData();
  }, [user.isAuthenticated]);

  const value = {
    ...userData,
    setUser,
    setDevices,
    disconnectDevice,
    setCallsData,
    removeCall,
    setContactsData,
    removeContact,
    addContact,
  };

  return (
    <UserContext.Provider value={value}>
      <Alert
        open={!!successMessage}
        type="success"
        label={successMessage}
        position="right"
        onClose={() => setSuccessMessage('')}
      />
      {children}
    </UserContext.Provider>
  );
};
