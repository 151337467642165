import {useState} from 'react';
import {Box, ClickAwayListener, MenuItem, Typography} from '@mui/material';
import Button from 'view/components/Button';
import FormHeader from 'view/components/Form/Header';
import {User, UserStatus} from 'types/users';
import Alert from 'view/components/Alert';
import {StyledFormContainer, StyledFormActions} from '../../styled';
import {userStatuses} from 'view/constants';
import CheckIcon from 'view/components/icons/Check';
import ChevronDownIcon from 'view/components/icons/ChevronDown';
import {updateUser} from 'services/api/admin/user';
import {StyledInput, StyledPopper, StyledSelectItem} from './styled';
import axios, {AxiosError} from 'axios';

type ChangeAvailabilityStatusProps = {
  user: User;
  onCancel: () => void;
  onSaved: (user: User) => void;
};

export default function ChangeAvailabilityStatus(
  props: ChangeAvailabilityStatusProps,
) {
  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState(props.user.status);

  const [open, setOpen] = useState(false);

  const onSubmit = async () => {
    setError('');
    setSaving(true);

    try {
      const updated = await updateUser(props.user.id, {status});
      // setName(data);
      props.onSaved(updated);
    } catch (err) {
      console.error(err);

      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setSaving(false);
    }
  };

  const handleTooltipClose = () => setOpen(false);

  const submitDisabled = props.user.status === status || saving;

  const statusesToSelect = userStatuses.filter(
    (status) => status.label !== UserStatus.OFFLINE,
  );

  const title = (
    <>
      <MenuItem disabled value="" sx={{'&.Mui-disabled': {opacity: 'inherit'}}}>
        <Typography variant="subtitle1" mr={1}>
          Select status
        </Typography>
      </MenuItem>
      {statusesToSelect.map((statusToSelect) => (
        <StyledSelectItem
          key={statusToSelect.label}
          value={statusToSelect.label}
          onClick={() => {
            setStatus(statusToSelect.label);
            setOpen(false);
          }}
        >
          <Box sx={{display: 'flex'}}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              mr={1}
            >
              {statusToSelect.icon}
            </Box>
            <Typography variant="body2">{statusToSelect.label}</Typography>
          </Box>
          {status === statusToSelect.label && <CheckIcon />}
        </StyledSelectItem>
      ))}
    </>
  );

  return (
    <>
      <Alert open={!!error} type="error" label={error} position="right" />
      <StyledFormContainer>
        <FormHeader
          title="Availability status"
          description="Change users availability status and save"
          mb={5}
        />
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Box display="flex" flexDirection="column" mb={2} position="relative">
            <Box
              onClick={() => {
                setOpen((prevState) => !prevState);
              }}
            >
              <StyledInput
                disabled
                id="availability-status-input"
                label="Availability status"
                value={status}
                endAdornment={
                  open ? (
                    <ChevronDownIcon style={{transform: 'rotate(180deg)'}} />
                  ) : (
                    <ChevronDownIcon />
                  )
                }
              />
            </Box>
            <StyledPopper open={open} disablePortal={true}>
              {title}
            </StyledPopper>
          </Box>
        </ClickAwayListener>
        <StyledFormActions>
          <Button
            type="submit"
            onClick={onSubmit}
            label="Save"
            className="save-button"
            disabled={submitDisabled}
          />
          <Button
            label="Cancel"
            variant="text"
            className="cancel-button"
            onClick={props.onCancel}
          />
        </StyledFormActions>
      </StyledFormContainer>
    </>
  );
}
