import * as React from 'react';
import capitalize from 'lodash.capitalize';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from 'view/components/icons/Delete';
import ActionMenu, {MenuItemType} from 'view/components/ActionMenu';
import ListIcon from 'view/components/icons/List';
import {black87, purple} from 'view/theme/colors';
import {DeviceWithUser, DEVICE_TYPE} from 'types/devices';
import ClaraIcon from 'view/components/icons/Clara';
import HaruIcon from 'view/components/icons/Haru';
import Loader from 'view/components/Loader';
import TableHeader, {Order} from 'view/components/Table/components/TableHeader';
import {
  renderUserStatusCell,
  renderDeviceStatusCell,
} from 'view/pages/AdminDevices/components/DevicesTable';
import {getFullname} from 'tools';
import {Data, headCells} from './helpers';
import {
  formatDeviceLastActiveDate,
  formatDeviceRegistrationDate,
} from 'constraints/dates';
import {useState} from 'react';

type DevicesTableProps = {
  selectDeviceId: (deviceId: string) => void;
  selectUserId: (userId: string) => void;
  onClickDisconnectDevice: (device: DeviceWithUser) => void;
  loading: boolean;
  data: DeviceWithUser[];
};

const DevicesTable = ({
  loading,
  selectDeviceId,
  selectUserId,
  onClickDisconnectDevice,
  data,
}: DevicesTableProps) => {
  const [sorting, setSorting] = useState<[string, Order]>(['id', 'asc']);

  const rowsPerPage = 8;
  const orderBy = sorting[0];
  const order = sorting[1];

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setSorting([property, isAsc ? 'desc' : 'asc']);
  };

  if (!data.length) {
    return (
      <Box sx={{width: '100%'}}>
        <Typography variant="body2">No devices found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{width: '100%'}}>
      <Paper sx={{width: '100%', mb: 2, boxShadow: 'none'}}>
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
            <TableHeader
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={1}
            />
            {loading ? (
              <TableRow
                sx={{
                  'th,td': {borderBottom: 'none'},
                  height: 73 * rowsPerPage,
                }}
              >
                <TableCell colSpan={6}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {data.map((row: DeviceWithUser, index: number) => {
                  const menuItems: MenuItemType[] = [
                    {
                      label: 'Details',
                      icon: <ListIcon />,
                      onClick: () => selectDeviceId(row.id),
                    },
                  ];
                  if (row.user) {
                    menuItems.push({
                      label: 'Disconnect device',
                      icon: <DeleteIcon />,
                      onClick: () => onClickDisconnectDevice(row),
                      className: 'error',
                    });
                  }
                  return (
                    <TableRow
                      sx={
                        index + 1 === data.length
                          ? {
                              'th,td': {borderBottom: 'none'},
                            }
                          : undefined
                      }
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell component="th" scope="row" padding="none">
                        <Box display="flex" sx={{alignItems: 'center'}}>
                          {row.deviceType === DEVICE_TYPE.CLARA ? (
                            <ClaraIcon small />
                          ) : (
                            <HaruIcon small />
                          )}
                          <Typography
                            ml="22.55px"
                            variant="body2"
                            color={black87}
                            sx={{textTransform: 'capitalize'}}
                          >
                            {capitalize(row.deviceType)}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align={row.status ? 'left' : 'center'}>
                        {row.status ? renderDeviceStatusCell(row.status) : '-'}
                      </TableCell>
                      <TableCell align={row.user ? 'left' : 'center'}>
                        {row.user ? renderUserStatusCell(row.user.status) : '-'}
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">
                          {formatDeviceRegistrationDate(row.createdAt)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          {row.lastActiveAt
                            ? formatDeviceLastActiveDate(row.lastActiveAt)
                            : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell
                        onClick={() => selectUserId(row.userId || '')}
                        align={row.user ? 'left' : 'center'}
                        sx={
                          row.user
                            ? {color: purple, cursor: 'pointer'}
                            : undefined
                        }
                      >
                        {row.user ? getFullname(row.user) : '-'}
                      </TableCell>
                      <TableCell align="right" sx={{paddingRight: 0}}>
                        <ActionMenu
                          small
                          buttonIcon={<MoreVertIcon />}
                          menuItems={menuItems}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default DevicesTable;
