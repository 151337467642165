import * as React from 'react';
import {useEffect, useState} from 'react';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import {User} from 'types/users';
import {getUser} from 'services/api/user';
import {
  EditableUserInfoType,
  UserInfoRowType,
} from 'view/pages/AccountSettings';
import ChangeName from 'view/pages/AccountSettings/components/ChangeName';
import UserInfoRow from 'view/pages/AccountSettings/components/UserInfoRow';
import ChangePassword from 'view/pages/AccountSettings/components/ChangePassword';
import VerifyPasswordDialog from 'view/pages/AccountSettings/components/VerifyPasswordDialog';
import BackButton from 'view/components/BackButton';
import Loader from 'view/components/Loader';
import {getFullname} from 'tools';
import Typography from '@mui/material/Typography';
import Avatar from 'view/components/Avatar';

const DrawerDetails = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isVerifyPasswordDialogOpen, setIsVerifyPasswordDialogOpen] =
    useState(false);
  const [verifiedPassword, setVerifiedPassword] = useState('');
  const [fieldToEdit, setFieldToEdit] = useState<EditableUserInfoType | null>(
    null,
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchUser() {
      const user = await getUser();
      setUser(user);
      setLoading(false);
    }
    fetchUser();
  }, []);

  const clearFieldToEdit = () => setFieldToEdit(null);

  const renderEditForm = (fieldToEdit: EditableUserInfoType) => {
    let editForm;
    if (fieldToEdit === 'fullname' && user) {
      editForm = (
        <ChangeName
          firstName={user.firstName}
          lastName={user.lastName}
          onCancel={clearFieldToEdit}
          onSaved={({firstName = '', lastName = ''}) => {
            setUser(
              (prevState) => ({...prevState, firstName, lastName} as User),
            );
            setFieldToEdit('');
          }}
        />
      );
    }
    if (fieldToEdit === 'password' && verifiedPassword) {
      editForm = (
        <ChangePassword
          onCancel={clearFieldToEdit}
          oldPassword={verifiedPassword}
          onSaved={(password: string) => {
            setVerifiedPassword(password);
            setFieldToEdit('');
          }}
        />
      );
    }

    return (
      <Box position="relative">
        {editForm}
        <BackButton onClick={clearFieldToEdit} />
      </Box>
    );
  };

  if (loading) {
    return <Loader height="100vh" />;
  }

  const infoRows = [
    {
      label: 'Avatar',
      type: 'avatar',
      value: user && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body1" fontWeight={500} mr={1}>
            Avatar helps personalise users profile
          </Typography>
          <Avatar firstName={user.firstName} lastName={user.lastName} />
        </Box>
      ),
    },
    {
      label: 'Full Name',
      type: 'fullname',
      value: getFullname(user as User),
    },
    {
      label: 'Username',
      type: 'username',
      value: user?.username,
    },
    {
      label: 'Password',
      type: 'password',
      value: undefined,
    },
  ];

  return (
    <>
      {fieldToEdit ? (
        renderEditForm(fieldToEdit)
      ) : (
        <>
          <List>
            {infoRows.map(({type, label, value = ''}, index) => {
              let onClick;

              if (type === 'password') {
                onClick = () => setIsVerifyPasswordDialogOpen(true);
              }

              if (type === 'fullname') {
                onClick = () => setFieldToEdit('fullname');
              }
              return (
                <UserInfoRow
                  key={type}
                  type={type as UserInfoRowType}
                  label={label}
                  value={value}
                  divider={infoRows.length !== index + 1}
                  onClick={onClick}
                />
              );
            })}
          </List>
        </>
      )}
      <VerifyPasswordDialog
        open={isVerifyPasswordDialogOpen}
        onVerify={(password: string) => {
          setVerifiedPassword(password);
          setFieldToEdit('password');
          setIsVerifyPasswordDialogOpen(false);
        }}
        onClose={() => setIsVerifyPasswordDialogOpen(false)}
      />
    </>
  );
};
export default DrawerDetails;
