import {useState, useEffect, ReactElement, createContext} from 'react';
import {Auth, Hub} from 'aws-amplify';
import {HubCapsule} from '@aws-amplify/core';
import Alert from 'view/components/Alert';
import {createUser, getUser} from 'services/api/user';
import {UserRole} from 'types/users';

export type AuthData = {
  isAuthenticated: boolean;
  isLoading: boolean;
  username: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
};

export const AuthContext = createContext({
  isAuthenticated: false,
  isLoading: true,
  isAdmin: false,
  username: '',
  firstName: '',
  lastName: '',
  setName: ({firstName = '', lastName = ''}) => {},
  setPasswordChanged: () => {},
});

const AuthListener = async (
  data: HubCapsule,
  setAuthData: React.Dispatch<React.SetStateAction<AuthData>>,
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
) => {
  const authData = data.payload.data;

  switch (data.payload.event) {
    case 'signIn':
      const {username} = authData;

      const initialSignIn = localStorage.getItem('initialSignIn');
      if (initialSignIn) {
        const firstName = localStorage.getItem('firstName') as string;
        const lastName = localStorage.getItem('lastName') as string;

        await createUser({username, firstName, lastName});
        localStorage.removeItem('initialSignIn');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
      }

      let dbUser;
      try {
        dbUser = await getUser();
      } catch (err) {
        console.error(err);
        return setError('Something went wrong');
      }

      if (!dbUser.active) {
        return setError('Something went wrong');
      }

      if (initialSignIn) {
        setSuccessMessage('Successfully signed up');
      } else {
        setSuccessMessage('Successfully logged in');
      }

      setAuthData({
        isAuthenticated: true,
        isLoading: false,
        isAdmin: dbUser.role === UserRole.ADMIN,
        username,
        firstName: dbUser.firstName,
        lastName: dbUser.lastName,
      });
      break;
    case 'signOut':
      localStorage.clear();
      setSuccessMessage('Successfully logged out');
      setAuthData({
        isAuthenticated: false,
        isAdmin: false,
        isLoading: false,
        username: '',
        firstName: '',
        lastName: '',
      });
      break;
    case 'signIn_failure':
      localStorage.clear();
      break;
  }
};

type Props = {
  children: ReactElement;
};

export const AuthProvider = ({children}: Props) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const [authData, setAuthData] = useState<AuthData>({
    isAdmin: false,
    isAuthenticated: false,
    isLoading: true,
    username: '',
    firstName: '',
    lastName: '',
  });

  const setName = ({firstName = '', lastName = ''}) => {
    setAuthData((prevState) => ({...prevState, firstName, lastName}));
    setSuccessMessage('Successfully changed full name');
  };

  const setPasswordChanged = () => {
    setSuccessMessage('Successfully changed password');
  };

  useEffect(() => {
    Hub.listen('auth', async (data) =>
      AuthListener(data, setAuthData, setSuccessMessage, setError),
    );

    const getCurrentUserData = async () => {
      setError('');
      await Auth.currentAuthenticatedUser({bypassCache: true})
        .then(async (data) => {
          const {
            firstName,
            lastName,
            role = UserRole.USER,
            active,
          } = await getUser();

          if (!active) {
            try {
              await Auth.signOut({global: true});
            } catch (err) {
              console.error(err);
              if (err instanceof Error) {
                if (err.message === 'Access Token has been revoked') {
                  await Auth.signOut();
                }
              }
            } finally {
              return setAuthData({
                isAuthenticated: false,
                isLoading: false,
                isAdmin: false,
                username: '',
                firstName: '',
                lastName: '',
              });
            }
          }

          return setAuthData((prevState) => ({
            ...prevState,
            isAuthenticated: true,
            isLoading: false,
            isAdmin: role === UserRole.ADMIN,
            username: data.username,
            firstName,
            lastName,
          }));
        })
        .catch(() => {
          return setAuthData({
            isAuthenticated: false,
            isLoading: false,
            isAdmin: false,
            username: '',
            firstName: '',
            lastName: '',
          });
        });
    };

    getCurrentUserData();
  }, []);

  const value = {...authData, setName, setPasswordChanged};

  return (
    <AuthContext.Provider value={value}>
      <Alert
        open={!!successMessage}
        type="success"
        label={successMessage}
        position="right"
        onClose={() => setSuccessMessage('')}
      />
      <Alert
        open={!!error}
        type="error"
        label={error}
        onClose={() => setError('')}
      />
      {children}
    </AuthContext.Provider>
  );
};
