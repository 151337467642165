import {white, yellow} from 'view/theme/colors';

export default function StatusWarningIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill={white} />
      <path
        d="M7.99996 -0.000976562C3.58881 -0.000976562 0 3.58775 0 7.99898C0 12.4102 3.58881 15.999 7.99996 15.999C12.4111 15.999 15.9999 12.4102 15.9999 7.99898C15.9999 3.58775 12.4112 -0.000976562 7.99996 -0.000976562ZM12.5937 6.64772L7.56771 11.6737C7.354 11.8874 7.06993 12.005 6.76774 12.005C6.46555 12.005 6.18147 11.8874 5.96777 11.6737L3.40624 9.11216C3.19254 8.89846 3.07483 8.61438 3.07483 8.31219C3.07483 8.00992 3.19254 7.72584 3.40624 7.51214C3.61986 7.29843 3.90394 7.18073 4.20621 7.18073C4.5084 7.18073 4.79256 7.29843 5.00618 7.51222L6.76765 9.27361L10.9936 5.0477C11.2073 4.83399 11.4913 4.71637 11.7935 4.71637C12.0957 4.71637 12.3798 4.83399 12.5935 5.0477C13.0348 5.48896 13.0348 6.20662 12.5937 6.64772Z"
        fill={yellow}
      />
    </svg>
  );
}
