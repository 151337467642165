import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const StyledAdminMap = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* padding: 0 4rem; */
  margin: 4rem 0 0 0;
  min-height: calc(100vh - 4rem);
` as typeof Box;

export const StyledContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 4rem);
` as typeof Box;

export const H1 = styled.h1`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #000000;
`;

// GoogleMap Style

export const containerStyle = {
  width: '100%',
  height: '100%',
};
