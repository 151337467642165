import {Box, Typography} from '@mui/material';
import {AdminContext} from 'contexts/admin';
import {useContext, useEffect, useState} from 'react';
import {DEVICE_CALL_STATUS, DEVICE_STATUS} from 'types/devices';
import ActionMenu, {MenuItemType} from 'view/components/ActionMenu';
import Chip from 'view/components/Chip';
import DeviceInfo from 'view/components/DeviceInfo';
import {ArrowUpIcon} from 'view/components/icons/Arrow';
import ChevronDownIcon from 'view/components/icons/ChevronDown';
import TemperatureIcon from 'view/components/icons/Temperature';
import {
  WifiFullIcon,
  WifiNoIcon,
  WifiNormalIcon,
  WifiWeakestIcon,
} from 'view/components/icons/WiFi';
import {black4, black54} from 'view/theme/colors';
import {formatMinutesDuration} from 'tools';
import {ClaraData} from '../Data';
import Divider from '@mui/material/Divider';
import * as React from 'react';
import {DevicesInfoProps} from 'types/map';

type ClaraProps = {
  showPolyline: () => void;
  hidePolyline: () => void;
  visible: string;
  selectedOriginId: string;
  setActionLabel: any;
  actionLabel: string;
};

const Clara = ({
  visible,
  setActionLabel,
  actionLabel,
  hidePolyline,
  showPolyline,
  selectedOriginId,
}: ClaraProps) => {
  const {deviceInfoMap} = useContext(AdminContext);

  const [motorOne, setMotorOne] = useState<number>(25);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMotorOne(
        Math.floor(minNumber + Math.random() * (maxNumber - minNumber)),
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const maxNumber = 26.9;
  const minNumber = 25.0;

  const maxWifi = -30;
  const minWifi = -100;
  const randomWifi = Math.floor(minWifi + Math.random() * (maxWifi - minWifi));

  const maxSpeed = 8;
  const minSpeed = 3;
  const randomSpeed = Math.floor(
    minSpeed + Math.random() * (maxSpeed - minSpeed),
  );

  const menuItems: MenuItemType[] = [
    {
      label: 'Day',
      onClick: () => setActionLabel('Day'),
    },
    {
      label: 'Week',
      onClick: () => setActionLabel('Week'),
    },
    {
      label: 'Month',
      onClick: () => setActionLabel('Month'),
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          flexGrow: 1,
        }}
      >
        <Box sx={{minWidth: 0, display: 'flex', flexDirection: 'column'}}>
          <Typography
            variant="h1"
            noWrap
            sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}
          >
            {deviceInfoMap?.deviceType}
          </Typography>
          <Box
            sx={{minWidth: 0, display: 'flex', alignItems: 'center'}}
            mt={1.3}
            ml={-0.5}
          >
            {deviceInfoMap?.status === DEVICE_STATUS.Online ? (
              <Chip label={DEVICE_STATUS.Online} color="success" size="small" />
            ) : (
              <Chip label={DEVICE_STATUS.Offline} color="error" size="small" />
            )}
            {deviceInfoMap?.callStatus === DEVICE_CALL_STATUS.On_the_call ? (
              <Chip
                label={deviceInfoMap?.callStatus}
                color="success"
                size="small"
              />
            ) : (
              <Chip
                label={deviceInfoMap?.callStatus}
                color="error"
                size="small"
              />
            )}
          </Box>
          <Box
            sx={{
              minWidth: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
            mt={2}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              {deviceInfoMap?.wifiStrength ? (
                deviceInfoMap?.status === DEVICE_STATUS.Offline ? (
                  'N/A'
                ) : null || deviceInfoMap?.wifiStrength < -85 ? (
                  <WifiNoIcon />
                ) : null || deviceInfoMap?.wifiStrength < -67 ? (
                  <WifiWeakestIcon />
                ) : null || deviceInfoMap?.wifiStrength < -49 ? (
                  <WifiNormalIcon />
                ) : null || deviceInfoMap?.wifiStrength <= -30 ? (
                  <WifiFullIcon />
                ) : null
              ) : deviceInfoMap?.status === DEVICE_STATUS.Offline ? (
                'N/A'
              ) : null || randomWifi < -85 ? (
                <WifiNoIcon />
              ) : null || randomWifi < -67 ? (
                <WifiWeakestIcon />
              ) : null || randomWifi < -49 ? (
                <WifiNormalIcon />
              ) : null || randomWifi <= -30 ? (
                <WifiFullIcon />
              ) : null}
              <Typography
                variant="body1"
                noWrap
                ml={1}
                sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}
              >
                {deviceInfoMap?.status === DEVICE_STATUS.Offline
                  ? 'N/A'
                  : ` ${randomSpeed.toString()} Mbps`}{' '}
              </Typography>
              {deviceInfoMap?.status ===
              DEVICE_STATUS.Offline ? null : randomSpeed > 5 ? (
                <ArrowUpIcon />
              ) : (
                <Box
                  sx={{
                    transform: 'rotate(180deg)',
                  }}
                >
                  <ArrowUpIcon />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
              pl={2.5}
            >
              <TemperatureIcon />
              <Typography
                variant="body1"
                noWrap
                ml={1}
                sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}
              >
                {deviceInfoMap?.status === DEVICE_STATUS.Offline
                  ? 'N/A'
                  : `+${motorOne.toString().slice(0, 2)} °C`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{minWidth: 0, display: 'flex', flexDirection: 'column'}}
          mt={4}
        >
          {ClaraData.map((e, i) => {
            return (
              <Box
                key={i.toString()}
                sx={{
                  minWidth: 0,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  borderBottomColor: `${black4}`,
                  borderBottom: 0.1,
                }}
                pb={3}
                pt={3}
              >
                <Box
                  sx={{
                    minWidth: '150px',
                  }}
                >
                  <Typography
                    variant="body2"
                    noWrap
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      fontSize: 12,
                      color: `${black54}`,
                    }}
                  >
                    {e.settingName}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    minWidth: 'calc(100% - 9.5rem)',
                  }}
                >
                  {e?.settingName === 'DEVICE TYPE' ? (
                    <Typography
                      variant="h5"
                      noWrap
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontWeight: '500',
                        fontSize: 16,
                      }}
                    >
                      {deviceInfoMap?.deviceType}
                    </Typography>
                  ) : null}

                  {e?.settingName === 'DEVICE ID' ? (
                    <Typography
                      variant="h5"
                      noWrap
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontWeight: '500',
                        fontSize: 16,
                      }}
                    >
                      {deviceInfoMap?.id}
                    </Typography>
                  ) : null}

                  {e?.settingName === 'ERROR STATUS' ? (
                    <Typography
                      variant="h5"
                      noWrap
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontWeight: '500',
                        fontSize: 16,
                      }}
                    >
                      No errors
                    </Typography>
                  ) : null}

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {e?.settingName === 'USAGE' ? (
                      <Typography
                        variant="h5"
                        noWrap
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          fontWeight: '500',
                          fontSize: 16,
                        }}
                        mr={-1}
                        mt={-0.2}
                      >
                        {actionLabel ? actionLabel : 'Day'}
                      </Typography>
                    ) : null}

                    {e?.settingName === 'USAGE' ? (
                      <ActionMenu
                        small
                        buttonIcon={<ChevronDownIcon />}
                        menuItems={menuItems}
                      />
                    ) : null}
                  </Box>

                  {e?.settingName === 'USAGE' ? (
                    <Typography
                      variant="h5"
                      noWrap
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontWeight: '500',
                        fontSize: 16,
                      }}
                    >
                      {actionLabel && deviceInfoMap?.usageInMinutes
                        ? formatMinutesDuration(
                            deviceInfoMap.usageInMinutes[
                              actionLabel.toLowerCase() as keyof DevicesInfoProps['usageInMinutes']
                            ],
                          )
                        : null}
                    </Typography>
                  ) : null}

                  {e?.settingName === 'FIRMWARE VERSION' ? (
                    <Typography
                      variant="h5"
                      noWrap
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontWeight: '500',
                        fontSize: 16,
                        minWidth: '100%',
                      }}
                    >
                      {deviceInfoMap?.firmwareVersion
                        ? deviceInfoMap?.firmwareVersion
                        : '1.0.23'}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            );
          })}
        </Box>
        {deviceInfoMap?.call && (
          <>
            <Box
              sx={{
                minWidth: 0,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              mt={8}
              mb={4}
            >
              <Typography
                variant="h2"
                noWrap
                sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}
              >
                On the call
              </Typography>
            </Box>
            <Box sx={{minWidth: 0, display: 'flex', flexDirection: 'column'}}>
              {deviceInfoMap?.call?.devices?.map((device) => {
                return (
                  <>
                    <Box key={device.id} pb={3} pt={3}>
                      <DeviceInfo
                        id={device?.id}
                        name={device?.deviceType}
                        type={device?.deviceType}
                        status={device?.status}
                      />
                    </Box>
                    <Divider />
                  </>
                );
              })}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Clara;
