import styled from '@emotion/styled';
import MuiChip from '@mui/material/Chip';
import {
  green,
  lightGreen,
  red,
  lightRed,
  lightGray,
  black54,
  lightPurple,
  purple,
  yellow,
  lightYellow,
} from 'view/theme/colors';

export const StyledChip = styled(MuiChip)`
  &.MuiChip-root {
    border-radius: 0.25rem;
    margin: 0 0 0 0.25rem;
  }

  &.MuiChip-sizeSmall {
    font-size: 0.75rem;
  }

  &.MuiChip-filledPrimary {
    color: ${purple};
    background: ${lightPurple};
  }

  &.MuiChip-filledSuccess {
    color: ${green};
    background: ${lightGreen};
  }

  &.MuiChip-filledWarning {
    color: ${yellow};
    background: ${lightYellow};
  }

  &.MuiChip-filledError {
    color: ${red};
    background: ${lightRed};
  }

  &.MuiChip-filledSecondary {
    border-radius: 10px;
    color: ${black54};
    background: ${lightGray};
  }
`;

type ChipProps = {
  label: string | number | undefined;
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | any;
  size?: 'medium' | 'small';
  transform?: boolean;
};

export default function Chip({label, color, size, transform}: ChipProps) {
  return (
    <StyledChip
      label={label}
      color={color}
      size={size}
      sx={{textTransform: transform ? 'uppercase' : null}}
    />
  );
}
