import {purple} from 'view/theme/colors';

export default function DeviceIcon() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="7.69043"
        width="41"
        height="41"
        rx="6"
        stroke={purple}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="25.4998"
        cy="28.1893"
        r="8.2"
        stroke={purple}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="23.54"
        y="4.5"
        width="3.92"
        height="3.92"
        rx="1.96"
        fill={purple}
        stroke={purple}
      />
    </svg>
  );
}
