import {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import Alert from 'view/components/Alert';
import PasswordInput from 'view/components/Form/PasswordInput';
import {VerifyPasswordValidationSchema} from 'constraints';
import KeyIcon from 'view/components/icons/Key';

type VerifyPasswordFormData = {
  password: string;
};

type VerifyPasswordDialogProps = {
  open: boolean;
  onVerify: (password: string) => void;
  onClose: () => void;
};

const initialFormData = {
  resolver: yupResolver(VerifyPasswordValidationSchema),
  defaultValues: {
    password: '',
  },
};

export default function VerifyPasswordDialog({
  open,
  onVerify,
  onClose,
}: VerifyPasswordDialogProps) {
  const [error, setError] = useState('');
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<VerifyPasswordFormData>(initialFormData);

  useEffect(() => {
    if (!open) {
      setError('');
      reset();
    }
  }, [open, reset]);

  const onSubmit = async (data: VerifyPasswordFormData) => {
    setError('');

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, data.password, data.password);
      onVerify(data.password);
    } catch (err) {
      if (err instanceof Error) {
        if (err.name === 'LimitExceededException') {
          const message =
            'You have exceeded the number of attempts. Please try again in 15 minutes';
          setError(message);
        } else if (
          err.message === 'Incorrect username or password.' ||
          err.name === 'InvalidParameterException'
        ) {
          setError('Invalid password');
        } else {
          setError(err.message);
        }
      }
    }
  };

  // prevents chrome from autofilling inputs except 'new-password' input
  const renderHiddenInput = () => (
    <div
      style={{
        overflow: 'hidden',
        height: 0,
        background: 'transparent',
      }}
    >
      <input type="text" />
    </div>
  );

  return (
    <>
      <Alert
        open={!!error}
        type="error"
        label={error}
        onClose={() => setError('')}
      />
      <form>
        <ConfirmationDialog
          open={open}
          text={`To continue, first verify that \n it's your password`}
          icon={<KeyIcon />}
          color="primary"
          confirmText="Next"
          onConfirm={() => handleSubmit(onSubmit)()}
          onClose={onClose}
        >
          {renderHiddenInput()}
          <PasswordInput
            inputProps={{
              autocomplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            control={control}
            error={!!errors.password?.message}
            helperText={errors.password?.message}
            mt={4}
          />
        </ConfirmationDialog>
      </form>
    </>
  );
}
