import {useState} from 'react';
import {Auth} from 'aws-amplify';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Button from 'view/components/Button';
import Alert from 'view/components/Alert';
import PasswordInput from 'view/components/Form/PasswordInput';
import FormHeader from 'view/components/Form/Header';
import {
  ChangePasswordValidationSchema,
  invalidPasswordMessage,
} from 'constraints';
import {StyledFormContainer, StyledFormActions} from '../../styled';
import useAuth from 'hooks/auth';

type ChangePasswordFormData = {
  password: string;
  confirmPassword: string;
};

type ChangePasswordProps = {
  oldPassword: string;
  onCancel: () => void;
  onSaved: (password: string) => void;
};

const initialFormData = {
  resolver: yupResolver(ChangePasswordValidationSchema),
  defaultValues: {
    password: '',
    confirmPassword: '',
  },
};

export default function ChangePassword({
  oldPassword,
  onCancel,
  onSaved,
}: ChangePasswordProps) {
  const {setPasswordChanged} = useAuth();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const {
    control,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm<ChangePasswordFormData>(initialFormData);

  const onSubmit = async (data: ChangePasswordFormData) => {
    setError('');
    setSaving(true);

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword, data.password);
      setSaving(false);
      setPasswordChanged();
      onSaved(data.password);
    } catch (err) {
      if (err instanceof Error) {
        console.error(err);
        setError(err.message);
        setSaving(false);
      }
    }
  };

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');
  const isPasswordConfirmed = password === confirmPassword;
  const isValuesChanged = oldPassword !== watch('password');
  const submitDisabled = !isValuesChanged || !isPasswordConfirmed || saving;

  return (
    <>
      <Alert open={!!error} type="error" label={error} position="right" />
      <StyledFormContainer>
        <FormHeader
          title="Password"
          description="Change your password and save"
          mb={5}
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <PasswordInput
            control={control}
            error={!!errors.password?.message}
            helperText={
              errors.password?.message &&
              (errors.password?.message === 'not-strong-enough-password' ||
                ['min', 'max'].includes(errors.password?.type))
                ? invalidPasswordMessage
                : errors.password?.message
            }
            mb={3}
          />
          <PasswordInput
            name="confirmPassword"
            label="Confirm Password"
            control={control}
            error={!!confirmPassword && !isPasswordConfirmed}
            helperText={
              !!confirmPassword && !isPasswordConfirmed
                ? 'Passwords must much'
                : undefined
            }
          />
          <StyledFormActions>
            <Button
              type="submit"
              label="Confirm"
              className="save-button"
              disabled={submitDisabled}
            />
            <Button
              label="Cancel"
              variant="text"
              className="cancel-button"
              onClick={onCancel}
            />
          </StyledFormActions>
        </form>
      </StyledFormContainer>
    </>
  );
}
