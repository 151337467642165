import capitalize from 'lodash.capitalize';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Button from 'view/components/Button';
import TrashIcon from 'view/components/icons/Trash';
import UserInfoRow from './components/UserInfoRow';
import UserInfoSimplified from 'view/components/UserInfoSimplified';
import {
  formatDeviceRegistrationDate,
  formatDeviceLastActiveDate,
} from 'constraints/dates';
import {Device, DeviceWithUser, DEVICE_STATUS} from 'types/devices';
import useAdmin from 'hooks/admin';
import {updateDeviceOwner as updateDeviceOwnerApi} from 'services/api/admin/devices';
import Alert from 'view/components/Alert';
import React, {SetStateAction, useState} from 'react';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import {getLastArrayElement} from 'tools';
import {PaginatedResponse} from 'services/api/types';
import {useNavigate} from 'react-router-dom';
import {ADMIN_MAP_PATH} from 'view/routes';
import axios, {AxiosError} from 'axios';

type DeviceDetailsProps = {
  device: DeviceWithUser;
  setDevices?: (
    value: SetStateAction<PaginatedResponse<DeviceWithUser>>,
  ) => void;
};

export default function DeviceDetails({
  device,
  setDevices,
}: DeviceDetailsProps) {
  const navigate = useNavigate();
  const {setDrawerHistory, drawerHistory, selectUserId} = useAdmin();

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [deviceToDisconnect, setDeviceToDisconnect] = useState<Device | null>(
    null,
  );

  const showOnMapButtonVisible =
    device.location && device.status === DEVICE_STATUS.Online;

  const onDisconnectDevice = async (device: DeviceWithUser) => {
    try {
      await updateDeviceOwnerApi(device.id, null);
      setDevices &&
        setDevices((prevDevices: PaginatedResponse<DeviceWithUser>) => ({
          ...prevDevices,
          items: prevDevices.items.map((item) => {
            if (device.id === item.id) {
              return {...item, user: null};
            }
            return item;
          }),
        }));

      const currentDevice = getLastArrayElement(drawerHistory);
      const updatedDevice = {
        ...currentDevice,
        selectedItem: {...currentDevice.selectedItem, user: null},
      };
      const updatedHistory = drawerHistory.filter(
        (item, index) => index !== drawerHistory.length - 1,
      );
      setDrawerHistory([...updatedHistory, updatedDevice]);
      setSuccessMessage('Device was disconnected successfully');
      setDeviceToDisconnect(null);
    } catch (err) {
      console.error(err);

      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const onDisconnectClick = (device: DeviceWithUser) =>
    setDeviceToDisconnect(device);

  const onDisconnectConfirmationClose = () => setDeviceToDisconnect(null);

  const onDisconnectConfirmation = () =>
    onDisconnectDevice(deviceToDisconnect as DeviceWithUser);

  const rows = [
    {
      label: 'Device type',
      node: capitalize(device.deviceType),
    },
    {
      label: 'Device id',
      node: device.id,
    },
    {
      label: 'Owner',
      onClick: device.user
        ? () => selectUserId(device?.userId || '')
        : undefined,
      node: device.user ? (
        <UserInfoSimplified
          iconSize={2.5}
          firstName={device.user.firstName as string}
          lastName={device.user.lastName as string}
        />
      ) : (
        '-'
      ),
    },
    {
      label: 'Registration date',
      node: formatDeviceRegistrationDate(device.createdAt),
    },
    {
      label: 'Last active date',
      node: device.lastActiveAt
        ? formatDeviceLastActiveDate(device.lastActiveAt, true)
        : '-',
    },
  ];

  return (
    <>
      <Alert
        open={!!successMessage}
        type="success"
        label={successMessage}
        position="right"
        onClose={() => setSuccessMessage('')}
      />
      <Alert open={!!error} type="error" position="right" />
      {deviceToDisconnect && (
        <ConfirmationDialog
          open
          text={`Are you sure you want to disconnect ${deviceToDisconnect.id} device?`}
          confirmText="Disconnect"
          onConfirm={onDisconnectConfirmation}
          onClose={onDisconnectConfirmationClose}
        />
      )}
      <List>
        {rows.map(({label, onClick, node}, index) => {
          return (
            <UserInfoRow
              key={label}
              label={label}
              divider={rows.length !== index + 1}
              onClick={onClick}
            >
              {node}
            </UserInfoRow>
          );
        })}
      </List>
      {showOnMapButtonVisible && (
        <Box display="flex" justifyContent="flex-end" mt={6}>
          <Button
            label="Show on map"
            variant="text"
            onClick={() => navigate(`${ADMIN_MAP_PATH}/?deviceId=${device.id}`)}
          />
        </Box>
      )}
      {device.user && (
        <Box
          display="flex"
          justifyContent="flex-end"
          mt={device.location ? 3 : 6}
        >
          <Button
            label="Disconnect device"
            variant="text"
            color="error"
            endIcon={<TrashIcon />}
            onClick={() => onDisconnectClick(device)}
          />
        </Box>
      )}
    </>
  );
}
