import React, {useCallback, useEffect, useState} from 'react';
import debounce from 'lodash.debounce';
import Box from '@mui/material/Box';
import {StyledContainer} from './styled';
import {User, UserStatus} from 'types/users';
import {PaginatedResponse} from 'services/api/types';
import Filters, {FiltersData} from 'view/components/Filters';
import Search from 'view/components/Search';
import PageHeader from 'view/components/PageHeader';
import Alert from 'view/components/Alert';
import {Order} from 'view/components/Table/components/TableHeader';
import UsersTable from './components/UsersTable';
import {deleteUser, listUsers} from 'services/api/admin/user';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import useAdmin from 'hooks/admin';
import AdminDrawer from 'view/components/AdminDrawer';
import axios, {AxiosError} from 'axios';

const userFilters = {
  // todo: move all filters to one place and include needed
  availabilityStatus: {
    label: 'Availability status',
    options: {
      [UserStatus.KEEN_TO_MEET]: {
        label: UserStatus.KEEN_TO_MEET,
        value: UserStatus.KEEN_TO_MEET,
        checked: false,
      },
      [UserStatus.AVAILABLE]: {
        label: UserStatus.AVAILABLE,
        value: UserStatus.AVAILABLE,
        checked: false,
      },
      [UserStatus.AVAILABLE_IF_NEEDED]: {
        label: UserStatus.AVAILABLE_IF_NEEDED,
        value: UserStatus.AVAILABLE_IF_NEEDED,
        checked: false,
      },
      [UserStatus.NOT_AVAILABLE]: {
        label: UserStatus.NOT_AVAILABLE,
        value: UserStatus.NOT_AVAILABLE,
        checked: false,
      },
      [UserStatus.OFFLINE]: {
        label: UserStatus.OFFLINE,
        value: UserStatus.OFFLINE,
        checked: false,
      },
    },
  },
};

const AdminUsers = () => {
  const {selectUserId} = useAdmin();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [users, setUsers] = useState({
    items: [] as Array<User>,
    meta: {totalItems: 0},
  } as PaginatedResponse<User>);
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filters, setFilters] = useState<FiltersData>(userFilters);
  const [sorting, setSorting] = useState<[string, Order]>(['id', 'asc']);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchUsers = useCallback(
    debounce(
      async (
        searchQuery: string,
        filters: FiltersData,
        sorting: [string, 'asc' | 'desc'],
        page: number,
      ) => {
        setLoading(true);

        try {
          const availabilityStatus = Object.keys(
            filters.availabilityStatus.options,
          ).filter((option) => {
            return filters.availabilityStatus.options[option].checked;
          });
          const searchResult = await listUsers({
            searchQuery,
            orderBy: sorting[0],
            orderDirection: sorting[1],
            page,
            filters: {
              status: availabilityStatus,
            },
          });
          setUsers(searchResult);
          setLoading(false);
        } catch (err) {
          if (err instanceof Error) {
            console.error(err);
            setError(err.message);
            setLoading(false);
          }
        }
      },
      500,
    ),
    [],
  );

  useEffect(() => {
    searchUsers('', filters, sorting, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    searchUsers(searchQuery, filters, sorting, page);
  }, [searchUsers, searchQuery, filters, sorting, page]);

  const handleChangePage = async (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchQuery(e.target.value);
  };

  const onClearSearch = () => setSearchQuery('');

  const onDeleteConfirmation = async (user: User) => {
    try {
      await deleteUser(user.id);
      searchUsers(searchQuery, filters, sorting, page);
      selectUserId('');
      setUserToDelete(null);
    } catch (err) {
      console.error(err);

      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onEditUser = async (user: User) => {
    try {
      await searchUsers(searchQuery, filters, sorting, page);
      selectUserId(user.id);
    } catch (err) {
      if (err instanceof Error) {
        console.error(err);
        setError(err.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {userToDelete && (
        <ConfirmationDialog
          open
          text={`Are you sure you want to delete ${userToDelete.id} user?`}
          confirmText="Delete"
          onConfirm={() => onDeleteConfirmation(userToDelete)}
          onClose={() => setUserToDelete(null)}
        />
      )}
      <Alert open={!!error} type="error" position="right" />
      <Filters
        data={filters}
        onClear={() => setFilters(userFilters)}
        setFilters={(filters: FiltersData) => {
          setPage(1);
          setFilters(filters);
        }}
      />
      <StyledContainer component="main">
        <PageHeader
          title="Users"
          action={
            <Box sx={{width: '20rem'}}>
              <Search
                small
                name="users-search"
                value={searchQuery}
                label="Search"
                onChange={onSearchChange}
                onClear={onClearSearch}
              />
            </Box>
          }
        />
        <UsersTable
          loading={loading}
          data={users}
          page={page}
          sorting={sorting}
          selectUserId={selectUserId}
          onPageChange={handleChangePage}
          setSorting={setSorting}
          onClickDeleteUser={(user: User) => setUserToDelete(user)}
        />
      </StyledContainer>
      <AdminDrawer onEditUser={onEditUser} setUserToDelete={setUserToDelete} />
    </>
  );
};

export default AdminUsers;
