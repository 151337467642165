import {Contact} from 'types/contacts';
import {request} from '../request';
import {PaginatedResponse} from '../types';

export const listContacts = async (
  url = '/users/me/contacts',
): Promise<PaginatedResponse<Contact>> => {
  const {data} = await request({
    url,
  });

  return data;
};

export const addContact = async (userId: string): Promise<{id: string}> => {
  const {data} = await request({
    method: 'post',
    url: '/users/me/contacts',
    data: {id: userId},
  });

  return data;
};

export const removeContact = async (contactId: string): Promise<void> => {
  await request({
    method: 'delete',
    url: `/users/me/contacts/${contactId}`,
  });

  return;
};
