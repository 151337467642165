import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';
import {black54} from 'view/theme/colors';

export const StyledChip = styled(Chip)`
  color: ${black54};
  margin-left: 0.5rem;
  height: 1.25rem;

  .MuiChip-label {
    padding: 0 9px;
  }
`;
