import {request} from '../../request';

export const getDevicesListMap = async () => {
  const {data} = await request({
    url: '/admin/devices/map/overview',
  });

  return data;
};

export const getDeviceInfoMap = async (id: string | undefined) => {
  const {data} = await request({
    url: `/admin/devices/map/${id}`,
  });

  return data;
};
