import styled from '@emotion/styled';
import MuiAvatar from '@mui/material/Avatar';
import {getFullname} from 'tools';
import {black54, purple} from 'view/theme/colors';

export const StyledAvatar = styled(MuiAvatar)`
  background-color: ${purple};
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 0.75rem;
`;

type AvatarProps = {
  firstName: string;
  lastName: string;
  size?: number;
  inactive?: boolean;
};

export default function Avatar({
  firstName,
  lastName,
  size = 3.5,
  inactive,
}: AvatarProps) {
  const sizeInRem = `${size}rem`;
  const initials = inactive ? (
    'D'
  ) : (
    <>
      {firstName[0]}
      {lastName[0]}
    </>
  );

  return (
    <StyledAvatar
      alt={getFullname({firstName, lastName})}
      style={{
        height: sizeInRem,
        width: sizeInRem,
        fontSize: size === 3.5 ? '1.25rem' : '1rem',
        background: inactive ? black54 : undefined,
      }}
    >
      {initials}
    </StyledAvatar>
  );
}
