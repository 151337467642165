import React, {useState} from 'react';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from 'view/components/icons/Delete';
import UserInfo from 'view/components/UserInfo';
import {StyledContactCard} from './styled';
import ActionMenu from 'view/components/ActionMenu';
import {UserStatus} from 'types/users';

type ContactBlockProps = {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  status: UserStatus;
  divider?: boolean;
  onClickRemoveContact: React.MouseEventHandler<HTMLLIElement>;
  size?: string;
  inactive?: boolean;
};

export default function ContactCard({
  id,
  firstName,
  lastName,
  username,
  status,
  divider,
  onClickRemoveContact,
  size,
  inactive,
}: ContactBlockProps) {
  const [elevated, setElevated] = useState(false);

  const handleMouseEnter = !elevated ? () => setElevated(true) : undefined;
  const handleMouseLeave = () => setElevated(false);

  return (
    <>
      <StyledContactCard
        className={`${size} ${elevated ? 'elevated' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        inactiveContact={inactive}
      >
        <UserInfo
          username={username}
          firstName={firstName}
          lastName={lastName}
          status={status}
          size={size}
          inactive={inactive}
        />
        {elevated && (
          <ActionMenu
            buttonIcon={<MoreVertIcon />}
            handleClose={handleMouseLeave}
            menuItems={[
              {
                label: 'Delete',
                icon: <DeleteIcon />,
                onClick: onClickRemoveContact,
                className: 'error',
              },
            ]}
          />
        )}
      </StyledContactCard>
      {divider && <Divider />}
    </>
  );
}
