import React from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {black16, black54, black87} from 'view/theme/colors';
import {SxProps, Theme} from '@mui/material';

export const StyledInput = styled(TextField)`
  & .MuiOutlinedInput-root,
  & .MuiInputLabel-root {
    font-size: 0.875rem;
    line-height: 24px;
    font-weight: 500;
    color: ${black54};
  }

  & .MuiOutlinedInput-root {
    border-radius: 0.5rem;
    color: ${black87};

    &.Mui-focused {
      & fieldset {
        border: 1px solid ${black16};
      }
    }
  }

  & .MuiInputLabel-root {
    &.Mui-focused {
      color: ${black54};
    }
  }
`;

type FormInputProps = {
  id: string;
  label: string;
  value?: string;
  error?: boolean;
  inputProps?: object;
  helperText?: React.ReactElement | string;
  variant?: 'filled' | 'outlined';
  type?: React.InputHTMLAttributes<unknown>['type'];
  fullWidth?: boolean;
  disabled?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<any>;
  small?: boolean;
  sx?: SxProps<Theme>;
};

export default function Input({
  variant = 'outlined',
  inputProps,
  label,
  id,
  value,
  error,
  helperText,
  type,
  disabled,
  fullWidth = true,
  startAdornment,
  endAdornment,
  onChange,
  inputRef,
  small,
  sx = {},
  ...props
}: FormInputProps) {
  return (
    <StyledInput
      id={id}
      name={id}
      type={type}
      variant={variant}
      inputProps={inputProps}
      margin="none"
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
      disabled={disabled}
      label={label}
      value={value}
      onChange={onChange}
      inputRef={inputRef}
      size={small ? 'small' : undefined}
      sx={{...sx, '& .MuiInputBase-root': {height: small ? '3rem' : '3.5rem'}}}
      InputProps={{
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : undefined,
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : undefined,
      }}
      {...props}
    />
  );
}
