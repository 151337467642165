import {FC, Suspense, lazy} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import {
  APP_PATH,
  SIGNIN_PATH,
  SIGNUP_PATH,
  CONTACTS_PATH,
  DEVICES_PATH,
  CALLS_PATH,
  ACCOUNT_SETTINGS_PATH,
  ADMIN_APP_PATH,
  ADMIN_DEVICES_PATH,
  ADMIN_USERS_PATH,
  ADMIN_MAP_PATH,
} from 'view/routes';
import Loader from 'view/components/Loader';
import AnonymousRoute from 'view/components/routing/AnonymousRoute';
import PrivateRoute from 'view/components/routing/PrivateRoute';
import AdminRoute from 'view/components/routing/AdminRoute';
import Contacts from 'view/pages/Contacts';
import Devices from 'view/pages/Devices';
import Callbook from 'view/pages/Callbook';
import AccountSettings from 'view/pages/AccountSettings';
import AdminDevices from 'view/pages/AdminDevices';
import AdminUsers from 'view/pages/AdminUsers';
import {AuthProvider} from 'contexts/auth';
import {UserProvider} from 'contexts/user';
import {AdminProvider} from 'contexts/admin';
import ResponsiveAppBar from 'view/components/AppBar';
import AdminMap from './pages/AdminMap';

const SignIn = lazy(() => import('view/pages/SignIn'));
const SignUp = lazy(() => import('view/pages/SignUp'));
const Sidebar = lazy(() => import('view/components/Sidebar'));

const App: FC = () => {
  return (
    <Suspense fallback={<Loader height="100vh" />}>
      <AdminProvider>
        <AuthProvider>
          <UserProvider>
            <Router>
              <Routes>
                <Route
                  element={
                    <AnonymousRoute>
                      <Outlet />
                    </AnonymousRoute>
                  }
                >
                  <Route path={SIGNIN_PATH} element={<SignIn />} />
                  <Route path={SIGNUP_PATH} element={<SignUp />} />
                  <Route path="*" element={<Navigate to={SIGNIN_PATH} />} />
                </Route>
                <Route
                  element={
                    <AdminRoute>
                      <Box display="flex">
                        <ResponsiveAppBar />
                        <Outlet />
                      </Box>
                    </AdminRoute>
                  }
                >
                  <Route
                    path={ADMIN_APP_PATH}
                    element={<Navigate to={ADMIN_DEVICES_PATH} />}
                  />
                  <Route path={ADMIN_DEVICES_PATH} element={<AdminDevices />} />
                  <Route path={ADMIN_USERS_PATH} element={<AdminUsers />} />
                  <Route path={ADMIN_MAP_PATH} element={<AdminMap />} />
                  <Route path="*" element={<Navigate to={ADMIN_APP_PATH} />} />
                </Route>
                <Route
                  element={
                    <PrivateRoute>
                      <Box display="flex">
                        <Sidebar />
                        <Outlet />
                      </Box>
                    </PrivateRoute>
                  }
                >
                  <Route path={CONTACTS_PATH} element={<Contacts />} />
                  <Route path={DEVICES_PATH} element={<Devices />} />
                  <Route path={CALLS_PATH} element={<Callbook />} />
                  <Route
                    path={ACCOUNT_SETTINGS_PATH}
                    element={<AccountSettings />}
                  />
                  <Route path="*" element={<Navigate to={APP_PATH} />} />
                </Route>
                <Route
                  path={APP_PATH}
                  element={<Navigate to={CONTACTS_PATH} />}
                />
              </Routes>
            </Router>
          </UserProvider>
        </AuthProvider>
      </AdminProvider>
    </Suspense>
  );
};

export default App;
