import {createTheme} from '@mui/material/styles';
import {black54, black87, green, lightPurple, purple, red} from './colors';

const theme = createTheme({
  palette: {
    primary: {
      main: purple,
    },
    error: {
      main: red,
    },
    success: {
      main: green,
    },
  },
  typography: {
    fontFamily: `"Manrope", "Helvetica", "Arial", sans-serif`,
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '32px',
      color: black87,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '40px',
      color: black87,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '24px',
      color: black87,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '24px',
      color: black54,
    },
    subtitle1: {
      fontSize: '0.75rem',
      fontWeight: 800,
      letterSpacing: 0.2,
      lineHeight: '24px',
      textTransform: 'uppercase',
      color: black54,
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          height: 45,
          width: 45,
          color: black54,

          '&:hover': {
            color: purple,
            background: lightPurple,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: 28,
          span: {
            fontWeight: 500,
            fontSize: '0.875rem',
          },
        },
      },
    },
  },
});

export default theme;
export type Theme = typeof theme;
