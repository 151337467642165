export type User = {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  status: UserStatus;
  role?: UserRole;
  createdAt: Date;
  active?: boolean;
};

export enum UserStatus {
  KEEN_TO_MEET = 'Keen to meet',
  AVAILABLE = 'Available',
  AVAILABLE_IF_NEEDED = 'Available if needed',
  NOT_AVAILABLE = 'Not available',
  OFFLINE = 'Offline',
}

export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
}
