import {User} from './users';

export enum DEVICE_TYPE {
  CLARA = 'CLARA',
  HARU = 'HARU',
}

export enum DEVICE_STATUS {
  Offline = 'Offline',
  Online = 'Online',
}

export enum DEVICE_CALL_STATUS {
  On_the_call = 'On the call',
  Idle = 'Idle',
}

export type DeviceLocation = {
  lat: number;
  lng: number;
};

export type Device = {
  id: string;
  displayName: string;
  status: DEVICE_STATUS;
  deviceType: DEVICE_TYPE;
  createdAt: Date;
  location?: DeviceLocation;
  lastActiveAt?: Date;
};

export type DeviceWithUser = Device & {userId?: string} & {
  user: Partial<User> | null;
};
