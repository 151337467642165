import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import {
  black32,
  black40,
  black8,
  purple,
  gray,
  red,
  lightPurple,
  white,
} from 'view/theme/colors';

const buttonStyles = `
&.MuiButton-root {
  text-transform: none;
  border-radius: 0.5rem;
  box-shadow: none;
  padding: 0.75rem 1.5rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  white-space: nowrap;
}

&.MuiButton-contained {
  background: ${purple};

  &.Mui-disabled {
    background: ${black8};
    color: ${black32};
  }

  &.MuiButton-containedError {
    background: ${red};
  }
}

&.MuiButton-outlined {
  color: ${black40};
  border: 1px solid ${gray};
}

&.MuiLoadingButton-loading {
  color: transparent !important;
}

&.MuiButton-containedSecondary {
    color: ${purple};
    background: ${lightPurple};
    
    &:hover {
      color: ${white};
      background: ${purple};
    }
  }
`;

export const StyledButton = styled(MuiButton)`
  ${buttonStyles}
`;

export const StyledLoadingButton = styled(MuiLoadingButton)`
  ${buttonStyles}
`;
