import {ReactElement} from 'react';
import {Controller, Control} from 'react-hook-form';
import Box from '@mui/material/Box';
import Input from 'view/components/Input';

type FormInputProps = {
  label: string;
  name: string;
  error?: boolean;
  inputProps?: object;
  helperText?: ReactElement | string;
  control: Control<any, any>;
  type?: React.InputHTMLAttributes<unknown>['type'];
  fullWidth?: boolean;
  startAdornment?: ReactElement;
  endAdornment?: ReactElement;
  mb?: number;
  mt?: number;
  sx?: any;
};

const FormInput = ({
  label,
  name,
  error,
  helperText,
  inputProps,
  control,
  type,
  fullWidth = true,
  startAdornment,
  endAdornment,
  mt,
  mb,
  sx,
}: FormInputProps) => (
  <Controller
    name={name}
    control={control}
    render={({field}) => (
      <Box mb={mb} mt={mt} sx={sx}>
        <Input
          type={type}
          error={error}
          helperText={helperText}
          fullWidth={fullWidth}
          inputProps={inputProps}
          id={name}
          label={label}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          {...field}
        />
      </Box>
    )}
  />
);

export default FormInput;
