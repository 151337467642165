export function WifiFullIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20h.01M8.53 16.11a6 6 0 016.95 0M5 12.55a11 11 0 0114.08 0M1.42 9a16 16 0 0121.16 0"
      ></path>
    </svg>
  );
}

export function WifiNormalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20h.01M8.53 16.11a6 6 0 016.95 0M5 12.55a11 11 0 0114.08 0"
      ></path>
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.42 9a16 16 0 0121.16 0"
        opacity="0.32"
      ></path>
    </svg>
  );
}

export function WifiLowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20h.01M8.53 16.11a6 6 0 016.95 0"
      ></path>
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 12.55a11 11 0 0114.08 0M1.42 9a16 16 0 0121.16 0"
        opacity="0.32"
      ></path>
    </svg>
  );
}

export function WifiWeakestIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20h.01M8.53 16.11a6 6 0 016.95 0"
      ></path>
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 12.55a11 11 0 0114.08 0M1.42 9a16 16 0 0121.16 0"
        opacity="0.32"
      ></path>
    </svg>
  );
}

export function WifiNoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20h.01"
        opacity="0.65"
      ></path>
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.53 16.11a6 6 0 016.95 0M5 12.55a11 11 0 0114.08 0M1.42 9a16 16 0 0121.16 0"
        opacity="0.32"
      ></path>
    </svg>
  );
}
