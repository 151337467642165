import apiRequest, {request} from '../../request';
import {DeviceWithUser} from 'types/devices';

export const getDevices = async ({
  url = '/admin/devices',
  searchQuery = '',
  filters = {},
  page = 1,
  order,
  orderBy,
}: {
  url?: string;
  page?: number;
  searchQuery?: string;
  filters?: Record<string, any>;
  order?: 'asc' | 'desc';
  orderBy?: string;
}) => {
  const params = {page} as any;

  if (orderBy) {
    params.orderBy = orderBy;
  }

  if (order) {
    params.order = order;
  }

  if (searchQuery) {
    params.search = searchQuery.trim();
  }

  Object.keys(filters).forEach((filter) => {
    if (filters[filter].length) {
      params[filter] = filters[filter].join(',');
    }
  });

  const {data} = await request({url, params});
  return data;
};

export const getDeviceInfo = async (
  deviceId: string,
): Promise<DeviceWithUser> => {
  const {data} = await request({
    url: `/admin/devices/${deviceId}`,
  });

  return data;
};

export const updateDeviceOwner = async (
  deviceId: string,
  userId: string | null,
): Promise<void> => {
  await apiRequest({
    method: 'patch',
    url: `/admin/devices/${deviceId}/owner`,
    data: {
      user: userId,
    },
  });

  return;
};
