import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import Alert from 'view/components/Alert';
import {ChangeDeviceNameValidationSchema} from 'constraints';
import Input from 'view/components/Form/Input';
import DeviceIcon from 'view/components/icons/Device';

type VerifyDeviceNameFormData = {
  deviceName: string;
};

type ChangeDeviceNameDialogProps = {
  open: boolean;
  displayName: string;
  onVerify: any;
  onClose: () => void;
};

export default function ChangeDeviceNameDialog({
  open,
  onVerify,
  onClose,
  displayName,
}: ChangeDeviceNameDialogProps) {
  const [error, setError] = useState('');

  const initialFormData = {
    resolver: yupResolver(ChangeDeviceNameValidationSchema),
    defaultValues: {
      deviceName: displayName,
    },
  };

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<VerifyDeviceNameFormData>(initialFormData);

  const onSubmit = async (data: VerifyDeviceNameFormData) => {
    setError('');

    try {
      onVerify(data.deviceName);
    } catch (err) {
      if (err instanceof Error) {
        if (
          err.message === 'Incorrect device name.' ||
          err.name === 'InvalidParameterException'
        ) {
          setError('Incorrect device name');
        } else {
          setError(err.message);
        }
      }
    }
  };

  return (
    <>
      <Alert
        open={!!error}
        type="error"
        label={error}
        onClose={() => setError('')}
      />
      <form>
        <ConfirmationDialog
          open={open}
          text="To change name of the device, press save button"
          icon={<DeviceIcon />}
          color="primary"
          confirmText="Save"
          onConfirm={handleSubmit(onSubmit)}
          onClose={onClose}
        >
          <Input
            name="deviceName"
            label="Device name"
            error={!!errors.deviceName?.message}
            helperText={errors.deviceName?.message}
            fullWidth
            control={control}
            mt={2}
          />
        </ConfirmationDialog>
      </form>
    </>
  );
}
