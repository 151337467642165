import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from 'view/components/Avatar';
import {getFullname} from 'tools';

type UserInfoProps = {
  iconSize: any;
  firstName: string;
  lastName: string;
};

export default function UserInfoSimplified({
  iconSize,
  firstName,
  lastName,
}: UserInfoProps) {
  return (
    <Box display="flex" alignItems="center" minWidth="0">
      <Avatar firstName={firstName} lastName={lastName} size={iconSize} />
      <Box
        minWidth="0"
        mr={1}
        sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}
      >
        <Typography
          variant="body1"
          noWrap
          sx={{fontWeight: iconSize === 2.5 ? 500 : 700}}
        >
          {getFullname({firstName, lastName})}
        </Typography>
      </Box>
    </Box>
  );
}
