import {red, white} from 'view/theme/colors';

export default function StatusErrorIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill={white} />
      <circle cx="8" cy="7.99902" r="8" fill={red} />
      <rect x="3" y="6.79883" width="10" height="2.3" rx="1.15" fill={white} />
    </svg>
  );
}
