import format from 'date-fns/format';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

const dateFormat = 'MMM d, yyyy';

export const formatDeviceRegistrationDate = (date: Date) =>
  format(new Date(date), dateFormat);

export const formatDeviceLastActiveDate = (date: Date, full = false) => {
  if (full) {
    return `${format(new Date(date), dateFormat)} / ${formatDistanceToNowStrict(
      new Date(date),
    )} ago`;
  }

  return `${formatDistanceToNowStrict(new Date(date))} ago`;
};
