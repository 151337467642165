import styled from '@emotion/styled';
import {Tooltip} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {black4, black87, white} from 'view/theme/colors';
import {userStatuses} from 'view/constants';
import Chip from 'view/components/Chip';
import {UserStatus} from 'types/users';
import {ReactElement} from 'react';

type StatusTooltipProps = {
  userStatus: UserStatus;
  children: ReactElement;
};

const StyledTooltip = styled((props: any) => (
  <Tooltip classes={{popper: props.className}} {...props} />
))`
  & .MuiTooltip-tooltip {
    background: ${white};
    border: 1px solid ${black4};
    border-radius: 0.5rem;
    padding: 0.7rem 1rem;
    box-shadow: 0 8px 24px 0 #00000014;
    color: black;

    .MuiTooltip-arrow {
      &:before {
        background: ${white};
        border: 1px solid ${black4};
        box-shadow: 0 8px 24px 0 #00000014;
      }
    }
  }
`;

export default function StatusTooltip({
  userStatus,
  children,
}: StatusTooltipProps) {
  const statusData = userStatuses.find((status) => status.label === userStatus);

  const title = (
    <Box>
      <Box sx={{display: 'flex'}}>
        <Box sx={{display: 'flex', alignItems: 'center'}} mr={1}>
          <Typography variant="body2" color={black87}>
            Status:
          </Typography>
          <Chip
            label={statusData?.label}
            color={statusData?.color}
            size="small"
          />
        </Box>
      </Box>
      <Typography variant="body2" fontSize="0.75rem">
        {statusData?.text}
      </Typography>
    </Box>
  );

  return (
    <StyledTooltip title={title} placement="top-start" arrow>
      <Box>{children}</Box>
    </StyledTooltip>
  );
}
