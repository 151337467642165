import styled from '@emotion/styled';
import Box from '@mui/material/Box';

// cast type to use "component" prop on Container
export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 7.5rem;
` as typeof Box;
