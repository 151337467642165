import {purple, red} from 'view/theme/colors';

type ConfirmationWarningIconProps = {
  color?: string;
};

export default function ConfirmationWarningIcon({
  color = 'error',
}: ConfirmationWarningIconProps) {
  const handleFillColor = (color: string) => {
    if (color === 'error') {
      return red;
    }
    return purple;
  };

  const fill = handleFillColor(color);

  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none">
      <path
        d="M49.3816 38.9542L29.6928 5.15849C28.9171 3.82711 27.5021 3 26 3C24.4978 3 23.0828 3.82711 22.307 5.15859L2.61832 38.9542C1.81667 40.3303 1.79295 42.0544 2.55635 43.4536C3.31995 44.8528 4.75864 45.722 6.31114 45.722H45.6888C47.2413 45.722 48.6801 44.8528 49.4437 43.4535C50.2071 42.0542 50.1833 40.3301 49.3816 38.9542ZM46.7225 41.863C46.5123 42.2482 46.1162 42.4875 45.6888 42.4875H6.31114C5.88373 42.4875 5.48764 42.2483 5.27754 41.8631C5.06735 41.4779 5.07392 41.0032 5.29451 40.6245L24.9834 6.82881C25.197 6.46232 25.5865 6.23458 26 6.23458C26.4135 6.23458 26.803 6.46232 27.0166 6.82881L46.7053 40.6245C46.9261 41.0033 46.9327 41.4779 46.7225 41.863Z"
        fill={fill}
      />
      <path
        d="M26.013 16.3086C24.8242 16.3086 23.8965 16.9688 23.8965 18.1392C23.8965 21.7102 24.3023 26.8417 24.3023 30.4128C24.3024 31.343 25.0853 31.7331 26.0131 31.7331C26.7089 31.7331 27.6947 31.343 27.6947 30.4128C27.6947 26.8418 28.1005 21.7103 28.1005 18.1392C28.1005 16.9689 27.1438 16.3086 26.013 16.3086Z"
        fill={fill}
      />
      <path
        d="M26.0441 33.8008C24.7684 33.8008 23.8115 34.8511 23.8115 36.1115C23.8115 37.3419 24.7683 38.4222 26.0441 38.4222C27.2328 38.4222 28.2477 37.3419 28.2477 36.1115C28.2477 34.8511 27.2327 33.8008 26.0441 33.8008Z"
        fill={fill}
      />
    </svg>
  );
}
