import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import {purple, black4} from 'view/theme/colors';

type StyledCallCardProps = {
  inactiveContact?: boolean;
};

export const StyledContactCard = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.125rem;
  transition: transform 0.25s ease-in-out;
  outline: 1px solid transparent;

  &.small {
    padding: 0.5rem 0.125rem;

    .MuiAvatar-root {
      width: 3rem !important;
      height: 3rem !important;
      font-size: 1rem !important;
    }

    &.elevated {
      padding: 0.5rem 1rem;
    }
  }

  .MuiIconButton-root {
    display: hidden;
  }

  .fullname.small {
    font-size: 0.875rem;
  }

  .username.small {
    font-size: 0.75rem;
  }

  &.elevated {
    outline: 1px solid ${black4};
    border-radius: 0.5rem;
    padding: 1rem 1.625rem;
    transform: scale3d(1.05, 1.05, 1);
    background: white;
    box-shadow: 0px 8px 24px 0px #00000014;

    .fullname {
      color: ${(props: StyledCallCardProps) =>
        !props.inactiveContact ? purple : undefined};
    }

    .MuiIconButton-root {
      display: inline-flex;
    }
  }
`;
