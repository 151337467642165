import {useJsApiLoader} from '@react-google-maps/api';
import useAuth from 'hooks/auth';
import {useEffect, useState} from 'react';
import {getDevicesListMap} from 'services/api/admin/map';
import Alert from 'view/components/Alert';
import Map from './components/Map';
import {H1, StyledAdminMap, StyledContainer} from './styled';

const AdminMap = () => {
  let {isAuthenticated, isLoading: isAuthLoading} = useAuth();
  const [devicesListMap, setDevicesListMap] = useState([]);

  // GOOGLE MAP API KEY
  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  // Error String
  const error = 'Error loading GoogleMap API. Please try again';

  const {isLoaded, loadError} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${API_KEY}`,
  });

  useEffect(() => {
    let mapIntervalId: NodeJS.Timer;

    (async () => {
      try {
        mapIntervalId = setInterval(async () => {
          if (isAuthLoading) return;
          if (!!isAuthenticated) {
            const devicesListMap = await getDevicesListMap();
            if (devicesListMap) {
              setDevicesListMap(devicesListMap);
            }
          }
        }, 2000);
      } catch (err) {
        if (err instanceof Error) {
          console.error(err);
        }
      }
    })();

    return () => mapIntervalId && clearInterval(mapIntervalId);
  }, [isAuthLoading, isAuthenticated]);

  return (
    <StyledAdminMap>
      {isLoaded ? (
        <Map origins={devicesListMap} />
      ) : (
        <StyledContainer>
          <H1>GoogleMap</H1>
        </StyledContainer>
      )}

      {loadError ? (
        <Alert open={!!error} type="error" label={error} position="right" />
      ) : null}
    </StyledAdminMap>
  );
};

export default AdminMap;
