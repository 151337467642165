import {useRef} from 'react';
import IconButton from '@mui/material/IconButton';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchIcon from 'view/components/icons/Search';
import Input from 'view/components/Input';

type SearchProps = {
  name: string;
  value: string;
  label: string;
  small?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
};

export default function Search({
  value,
  name,
  label,
  small,
  onChange,
  onClear,
}: SearchProps) {
  const textInput = useRef<HTMLInputElement>(null);

  const focus = () => {
    textInput.current?.focus();
  };

  return (
    <Input
      id={name}
      label={label}
      value={value}
      onChange={onChange}
      inputRef={textInput}
      small={small}
      endAdornment={
        value ? (
          <IconButton onClick={onClear} aria-label="clear-search" edge="end">
            <ClearOutlinedIcon />
          </IconButton>
        ) : (
          <IconButton onClick={focus} aria-label="search" edge="end">
            <SearchIcon />
          </IconButton>
        )
      }
    />
  );
}
