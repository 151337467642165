import axios, {AxiosRequestConfig, AxiosError} from 'axios';
import {Auth} from 'aws-amplify';

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

request.interceptors.request.use(function (config) {
  return Auth.currentSession()
    .then((session) => {
      // User is logged in. Set auth header on all requests
      config.headers = {
        Authorization: `Bearer ${session.getAccessToken().getJwtToken()}`,
      };

      return Promise.resolve(config);
    })
    .catch(() => {
      // No logged-in user: don't set auth header
      return Promise.resolve(config);
    });
});

const apiRequest = async ({
  method = 'get',
  url,
  data,
  params,
  headers,
}: AxiosRequestConfig) => {
  try {
    const result = await request({
      method,
      url,
      data,
      params,
      headers,
    } as AxiosRequestConfig);
    return result.data;
  } catch (e) {
    const err = e as AxiosError;
    if (err.response?.status && err.response.status >= 400) {
      throw new Error(err.response.data.message);
    }
    throw new Error(e as string);
  }
};

export default apiRequest;
