import * as yup from 'yup';

export const nameValidationRegexp = /^[A-Z]'?[- a-zA-Z]+$/;
export const passwordValidationRegexp =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;

export const invalidPasswordMessage = (
  <>
    Password should contain:
    <br />● at least 8 characters
    <br />● at least 1 uppercase character
    <br />● at least 1 lowercase character
    <br />● at least 1 digit
  </>
);

export const nameValidation = (part: 'First' | 'Last') =>
  yup
    .string()
    .required('This field is required')
    .min(2, `The ${part} Name must be longer than 2 characters.`)
    .max(32, `The ${part} Name must not be longer than 32 characters.`)
    .test(
      'no-whitespace',
      `Invalid ${part} Name. Please try again`,
      (value) => !!value && !value.includes(' '),
    )
    .matches(nameValidationRegexp, `Invalid ${part} Name. Please try again`);

export const usernameValidation = yup
  .string()
  .lowercase('Only lowercase letters allowed')
  .strict()
  .min(2, 'The username must be longer than 2 characters')
  .max(64, 'The username must not be longer than 64 characters')
  .test(
    'no-whitespace',
    'Invalid Username. Please try again',
    (value) => !!value && !value.includes(' '),
  )
  .required('This field is required');

export const passwordCreateValidation = yup
  .string()
  .min(8)
  .matches(passwordValidationRegexp, 'not-strong-enough-password')
  .required('This field is required');

export const passwordValidation = yup
  .string()
  .required('This field is required');

export const confirmPasswordValidation = yup
  .string()
  .required('This field is required');

export const deviceNameValidation = yup
  .string()
  .required('This field is required')
  .min(2, `Device name must be longer than 2 characters.`)
  .max(32, `Invalid Device name. Please try again`)
  .required();

export const SignUpValidationSchema = yup
  .object({
    firstName: nameValidation('First'),
    lastName: nameValidation('Last'),
    username: usernameValidation,
    password: passwordCreateValidation,
    confirmPassword: confirmPasswordValidation,
  })
  .required();

export const SignInValidationSchema = yup
  .object({
    username: yup
      .string()
      .lowercase('Only lowercase letters allowed.')
      .strict()
      .min(2, 'The username must be longer than 2 characters.')
      .max(64, 'The username must not be longer than 64 characters.')
      .test(
        'no-whitespace',
        'The username must not include white spaces.',
        (value) => !!value && !value.includes(' '),
      )
      .required('This field is required. Please, enter your username.'),
    password: yup
      .string()
      .required('This field is required. Please, enter your password'),
  })
  .required();

export const ChangeNameValidationSchema = yup
  .object({
    firstName: nameValidation('First'),
    lastName: nameValidation('Last'),
  })
  .required();

export const ChangePasswordValidationSchema = yup
  .object({
    password: passwordCreateValidation,
    confirmPassword: confirmPasswordValidation,
  })
  .required();

export const VerifyPasswordValidationSchema = yup
  .object({
    password: passwordValidation,
  })
  .required();

export const ChangeDeviceNameValidationSchema = yup
  .object({
    deviceName: deviceNameValidation,
  })
  .required();
