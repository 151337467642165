import groupBy from 'lodash.groupby';
import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Loader from 'view/components/Loader';
import {Contact} from 'types/contacts';
import ContactCard from 'view/components/ContactCard';

type ContactsListProps = {
  contacts: Contact[];
  allContactsLoaded: boolean;
  fetchNextContacts: () => void;
  onClickRemoveContact: (contact: Contact) => void;
};

export default function ContactsList({
  contacts,
  onClickRemoveContact,
  fetchNextContacts,
  allContactsLoaded,
}: ContactsListProps) {
  if (!contacts?.length) {
    return <Typography variant="body2">No contacts yet.</Typography>;
  }

  const contactsGrouppedByName = groupBy(
    contacts,
    (contact) => contact.firstName[0],
  );

  return (
    <InfiniteScroll
      dataLength={contacts.length}
      next={fetchNextContacts}
      hasMore={allContactsLoaded}
      loader={
        <Box my={2}>
          <Loader />
        </Box>
      }
      style={{overflow: 'visible'}}
    >
      <Box mb={5}>
        {Object.keys(contactsGrouppedByName)
          .sort((a, b) => a.localeCompare(b))
          .map((nameFirstLetter: string) => (
            <Box mb={2} key={nameFirstLetter}>
              <Typography variant="subtitle1" mb={0.5}>
                {nameFirstLetter}
              </Typography>
              {contactsGrouppedByName[nameFirstLetter].map(
                (contact, index, contacts) => (
                  <ContactCard
                    key={contact.id}
                    id={contact.id}
                    firstName={contact.firstName}
                    lastName={contact.lastName}
                    username={contact.username}
                    status={contact.status}
                    onClickRemoveContact={() => onClickRemoveContact(contact)}
                    divider={contacts.length > index + 1}
                    inactive={!contact.active}
                  />
                ),
              )}
            </Box>
          ))}
      </Box>
    </InfiniteScroll>
  );
}
