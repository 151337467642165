import {ReactElement} from 'react';
import {black87} from 'view/theme/colors';

type DownloadLinkProps = {
  label?: string;
  children?: ReactElement;
};

export default function DownloadLink({label, children}: DownloadLinkProps) {
  return (
    <a
      href={`${process.env.REACT_APP_API_URL}/files/desktop`}
      download
      rel="noreferrer"
      style={{textDecoration: 'none', color: black87}}
    >
      {children || label}
    </a>
  );
}
