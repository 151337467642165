import {useState} from 'react';
import {DEVICE_TYPE} from 'types/devices';
import Clara from './Clara';
import Haru from './Haru';
import {MapOrigin} from 'types/map';

type MapDeviceDetailsProps = {
  origins: MapOrigin[];
  showPolyline: () => void;
  hidePolyline: () => void;
  visible: string;
  selectedOriginId: string;
};

export default function MapDeviceDetails({
  origins,
  showPolyline,
  hidePolyline,
  visible,
  selectedOriginId,
}: MapDeviceDetailsProps) {
  const [actionLabel, setActionLabel] = useState('Day');
  const [showMore, setShowMore] = useState(false);

  const selectedOriginData = origins.find(
    (origin: MapOrigin) => origin.id === visible,
  );
  const deviceType = selectedOriginData?.deviceType;

  return (
    <>
      {deviceType === DEVICE_TYPE.CLARA ? (
        <Clara
          visible={visible}
          selectedOriginId={selectedOriginId}
          actionLabel={actionLabel}
          setActionLabel={setActionLabel}
          showPolyline={showPolyline}
          hidePolyline={hidePolyline}
        />
      ) : (
        <Haru
          visible={visible}
          selectedOriginId={selectedOriginId}
          actionLabel={actionLabel}
          setActionLabel={setActionLabel}
          showPolyline={showPolyline}
          hidePolyline={hidePolyline}
          showMore={showMore}
          setShowMore={() => setShowMore((e) => !e)}
        />
      )}
    </>
  );
}
