import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import {black4, black54} from 'view/theme/colors';

export const StyledFiltersContainer = styled(Box)`
  width: 20rem;
  flex-shrink: 0;
  margin: 4rem 0 0 0;
  padding: 0 4rem;
  border-right: 1px solid ${black4};
  min-height: calc(100vh - 4rem);

  .clear-filters {
    font-size: 0.875rem;
    color: ${black54};
  }
` as typeof Box;
