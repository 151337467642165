// Polyline Style

import {green} from 'view/theme/colors';

export const mapSettings = {
  polylineOptions: {
    dashed: {
      geodesic: true,
      strokeOpacity: 0,
      strokeColor: green,
      icons: [
        {
          icon: {
            path: 'M 0,0 0,2',
            strokeOpacity: 1,
            strokeWeight: 4,
          },
          repeat: '14px',
        },
      ],
    },
  },
};
