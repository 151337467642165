import {ReactElement} from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const StylePageHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 0 3.5rem;
`;

type PageHeaderProps = {
  title: string;
  action?: ReactElement;
};

const PageHeader = ({title, action}: PageHeaderProps) => (
  <StylePageHeader>
    <Typography variant="h1" mr={2}>
      {title}
    </Typography>
    {action}
  </StylePageHeader>
);

export default PageHeader;
