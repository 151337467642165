import Box from '@mui/material/Box';
import {black20, black29, black71} from 'view/theme/colors';

export default function ClaraIcon({small}: any) {
  if (small) {
    return (
      <Box
        sx={{
          width: '2.153rem',
          height: '2.067rem',
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
        }}
      >
        <svg width="21" height="38" viewBox="0 0 21 38" fill="none">
          <path
            d="M1.27536 32.4344C0.495431 32.7137 0.0961843 33.57 0.383618 34.3469C0.671052 35.1239 1.53632 35.5274 2.31625 35.2481L1.27536 32.4344ZM3.45416 31.6542L1.27536 32.4344L2.31625 35.2481L4.49505 34.4679L3.45416 31.6542Z"
            fill="#4B4B4B"
          />
          <path
            d="M6.0918 11.0679C6.21191 10.3268 6.85186 9.78223 7.60266 9.78223H10.5723H13.542C14.2928 9.78223 14.9327 10.3268 15.0528 11.0679H6.0918Z"
            fill="#B5B5B5"
          />
          <path
            d="M5.25933 5.39933C5.45398 2.63947 7.74965 0.5 10.5164 0.5C13.3251 0.5 15.6403 2.70278 15.7799 5.50804L16.0709 11.3531H5.06356L5.2204 5.95138L5.25933 5.39933Z"
            stroke="#4B4B4B"
          />
          <rect
            x="1.21484"
            y="11.5449"
            width="18.7143"
            height="16.2408"
            rx="2.5"
            stroke="#4B4B4B"
          />
          <path
            d="M4 27.8574H17.8922L18 32.489L17.5 35.8574H4.07746L4 27.8574Z"
            fill="#CCCCCC"
          />
          <path
            d="M3.65825 33.5063L3.98811 27.978H17.7053L18.0352 33.5063C18.1552 35.5177 16.5564 37.2148 14.5414 37.2148H7.15204C5.13707 37.2148 3.53823 35.5177 3.65825 33.5063Z"
            stroke="#4B4B4B"
          />
          <ellipse
            cx="10.8454"
            cy="31.7878"
            rx="1.91667"
            ry="1.88571"
            fill="#4B4B4B"
          />
        </svg>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '4rem',
        height: '4rem',
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
      }}
    >
      <svg
        width="34"
        height="64"
        viewBox="0 0 34 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.46571 55.7882C0.427444 56.1651 -0.107282 57.3119 0.271372 58.3495C0.650025 59.3871 1.79867 59.9228 2.83694 59.5458L1.46571 55.7882ZM5.77264 54.2247L1.46571 55.7882L2.83694 59.5458L7.14386 57.9823L5.77264 54.2247Z"
          fill={black71}
        />
        <path
          d="M8.52785 9.03305C8.85237 4.36829 12.7311 0.75 17.4072 0.75C22.1532 0.75 26.0644 4.47385 26.2972 9.21418L26.7957 19.3643H8.18854L8.45405 10.0939L8.52785 9.03305Z"
          stroke={black71}
          strokeWidth="1.5"
        />
        <rect
          x="1.75"
          y="19.4927"
          width="31.5"
          height="27.7571"
          rx="3.25"
          stroke={black71}
          strokeWidth="1.5"
        />
        <path
          d="M6.1765 47.0859H30.1462L30.3058 58.7729C30.321 59.8881 29.4212 60.8002 28.306 60.8002H7.67184C6.53337 60.8002 5.62453 59.8512 5.67371 58.7138L6.1765 47.0859Z"
          fill={black20}
        />
        <path
          d="M5.78935 57.6914L6.39634 47.3784H29.5205L30.1275 57.6914C30.305 60.7069 27.9073 63.2498 24.8866 63.2498H11.0303C8.0095 63.2498 5.61186 60.7069 5.78935 57.6914Z"
          stroke={black71}
          strokeWidth="1.5"
        />
        <path
          d="M10.0599 18.2565C10.2269 17.2989 11.0582 16.6001 12.0302 16.6001H17.5H22.9698C23.9418 16.6001 24.7731 17.2989 24.9401 18.2565L25 18.6001H10L10.0599 18.2565Z"
          fill={black29}
        />
        <ellipse
          cx="17.9583"
          cy="53.9427"
          rx="3.20833"
          ry="3.2"
          fill={black71}
        />
      </svg>
    </Box>
  );
}
