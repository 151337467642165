import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {DEVICE_CALL_STATUS, DEVICE_STATUS, DEVICE_TYPE} from 'types/devices';
import ClaraIcon from 'view/components/icons/Clara';
import Haru from 'view/components/icons/Haru';
import Chip from 'view/components/Chip';
import {format} from 'date-fns';

type DeviceInfoProps = {
  name: string | undefined;
  id: string | undefined;
  type: DEVICE_TYPE;
  status: DEVICE_STATUS;
  createdAt?: Date;
  callStatus?: DEVICE_CALL_STATUS;
};

export default function DeviceInfo({
  name,
  id,
  type,
  status,
  createdAt,
  callStatus,
}: DeviceInfoProps) {
  const renderCallStatus = (status?: DEVICE_CALL_STATUS) => {
    if (!status) return null;
    return status === DEVICE_CALL_STATUS.On_the_call ? (
      <Chip label={status} color="success" size="small" />
    ) : (
      <Chip label={status} color="error" size="small" />
    );
  };

  return (
    <Box sx={{display: 'flex', alignItems: 'center', minWidth: 0}}>
      <Box mr={2}>{type === DEVICE_TYPE.CLARA ? <ClaraIcon /> : <Haru />}</Box>
      <Box sx={{minWidth: 0}}>
        <Box sx={{display: 'flex'}}>
          <Typography
            variant="body1"
            noWrap
            mr={1}
            sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}
          >
            {name}
          </Typography>
          {status === DEVICE_STATUS.Online ? (
            <Chip label={DEVICE_STATUS.Online} color="success" size="small" />
          ) : (
            <Chip label={DEVICE_STATUS.Offline} color="error" size="small" />
          )}
          {renderCallStatus(callStatus)}
        </Box>
        <Box display="flex">
          <Typography
            variant="body2"
            mr={1}
            noWrap
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {id}
          </Typography>
          {createdAt && (
            <>
              <Typography variant="body2">/</Typography>
              <Typography variant="body2" ml={1} noWrap>
                {format(new Date(createdAt), 'MMM dd, yyyy')}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
