import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {UserStatus} from 'types/users';
import UserAvatar from 'view/components/UserInfo/UserAvatar';
import {getFullname} from 'tools';
import {deletedUserName} from 'constraints/user';
import {black54} from 'view/theme/colors';

type UserInfoProps = {
  firstName: string;
  lastName: string;
  username: string;
  status: UserStatus;
  size?: string;
  color?: string;
  inactive?: boolean;
};

export default function UserInfo({
  firstName,
  lastName,
  username,
  status = UserStatus.OFFLINE,
  size = '',
  color,
  inactive,
}: UserInfoProps) {
  const fullname = !inactive
    ? getFullname({firstName, lastName})
    : deletedUserName;

  return (
    <Box display="flex" alignItems="center" minWidth="0">
      <UserAvatar
        firstName={firstName}
        lastName={lastName}
        status={status}
        inactive={inactive}
      />
      <Box
        minWidth="0"
        mr={1}
        sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}
      >
        <Typography
          variant="body1"
          className={`fullname ${size}`}
          color={inactive ? black54 : color}
          fontWeight={inactive ? 500 : undefined}
          noWrap
        >
          {fullname}
        </Typography>
        {!inactive && (
          <Typography variant="body2" className={`username ${size}`} noWrap>
            @{username}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
