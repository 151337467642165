import {ReactElement} from 'react';
import {Navigate} from 'react-router-dom';
import Loader from 'view/components/Loader';
import {APP_PATH} from 'view/routes';
import useAuth from 'hooks/auth';

type Props = {
  children: ReactElement;
};

export default function AnonymousRoute({children}: Props) {
  const {isLoading, isAuthenticated} = useAuth();

  if (isLoading) {
    return <Loader height="100vh" />;
  }

  return isAuthenticated ? <Navigate to={APP_PATH} /> : children;
}
