import styled from '@emotion/styled';
import {useNavigate, NavLink} from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {lightGray} from 'view/theme/colors';
import {ADMIN_MAP_PATH} from 'view/routes';

const StyledTabs = styled(Tabs)`
  padding: 1rem 0 0;
  overflow: visible;
  z-index: 100;

  .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-between;
  }

  .MuiTabs-indicator {
    height: 0.125rempx;
    bottom: -0.063rem;
    border-top-left-radius: 1.875rem;
    border-top-right-radius: 1.875rem;
    z-index: 10000;
  }

  .MuiTabs-scroller {
    overflow: visible !important;
  }

  .MuiTab-root {
    margin-right: 4rem;
    overflow: visible;
    text-transform: none;
    min-width: 3rem;
    padding: 0.5rem 0.5rem 24px 0.5rem;

    &.Mui-selected {
      font-weight: 700;
    }
  }
`;

const StyledTabsWithIcons = styled(Tabs)`
  padding: 1rem 2rem 0;
  background: ${lightGray};
  min-height: 4.5rem;
  z-index: 100;

  .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-between;
  }

  .MuiTabs-indicator {
    height: 0.313rem;
  }

  .MuiTab-root {
    max-width: 3rem;
    min-width: 3rem;
    padding: 0.5rem 0.5rem 24px 0.5rem;
  }
`;

type TabT = {
  id: string;
  to: string;
  icon?: JSX.Element;
  label?: string;
  disabled?: boolean;
};

type NavigationTabsProps = {
  withIcons?: boolean;
  tabs: TabT[];
};

export default function NavigationTabs({withIcons, tabs}: NavigationTabsProps) {
  let {pathname} = window.location;
  const navigate = useNavigate();

  if (pathname.includes(ADMIN_MAP_PATH)) {
    pathname = ADMIN_MAP_PATH;
  }

  if (withIcons) {
    return (
      <StyledTabsWithIcons
        value={pathname}
        variant="fullWidth"
        aria-label="navigation-tabs"
      >
        {tabs.map(({icon, to, id, disabled}) => (
          <Tab
            key={to}
            icon={icon}
            aria-label={id}
            value={to}
            disabled={disabled}
            onClick={() => navigate(to)}
            LinkComponent={NavLink}
            disableRipple
          />
        ))}
      </StyledTabsWithIcons>
    );
  }

  return (
    <StyledTabs
      value={pathname}
      variant="fullWidth"
      aria-label="navigation-tabs"
    >
      {tabs.map(({label, to, id, disabled}) => (
        <Tab
          key={to}
          aria-label={id}
          value={to}
          label={label}
          disabled={disabled}
          onClick={() => navigate(to)}
          LinkComponent={NavLink}
          disableRipple
        />
      ))}
    </StyledTabs>
  );
}
