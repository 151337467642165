import {User} from 'types/users';
import {Device} from 'types/devices';

export enum CallStatus {
  REQUESTED = 'REQUESTED',
  INPROGRESS = 'INPROGRESS',
  FINISHED = 'FINISHED',
  DECLINED = 'DECLINED',
  MISSED = 'MISSED',
}

export type CallDeviceMediaConfig = {
  video: boolean;
  audio: boolean;
};

export type Call = {
  id: string;
  callee: User;
  calleeDevice: Device;
  caller: User;
  callerDevice: Device;
  createdAt: Date;
  endTime: Date | null;
  startTime: Date | null;
  status: CallStatus;
};

export enum CallSSE {
  INCOMING_CALL = 'user.incoming-call',
  DEVICE_OUTCOMING_CALL = 'user.device-outcoming-call',
  PENDING_CALL = 'user.pending-call',
  CALL_ACCEPTED = 'user.call-accepted',
  CALL_FINISHED = 'user.call-finished',
  CALL_DECLINED = 'user.call-declined',
  CHANGE_DEVICE_CONFIG = 'user.change-device-config',
}
