import {useState, useEffect} from 'react';
import {Auth} from 'aws-amplify';
import {
  getUser,
  deleteAccount as deleteAccountRequest,
} from 'services/api/user';
import PageHeader from 'view/components/PageHeader';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import Loader from 'view/components/Loader';
import BackButton from 'view/components/BackButton';
import Button from 'view/components/Button';
import TrashIcon from 'view/components/icons/Trash';
import {getFullname} from 'tools';
import ChangeName from './components/ChangeName';
import ChangePassword from './components/ChangePassword';
import UserInfoRow from './components/UserInfoRow';
import {StyledContainer} from './styled';
import VerifyPasswordDialog from './components/VerifyPasswordDialog';
import {User} from 'types/users';

export type UserInfoRowType = 'password' | 'username' | 'fullname';
export type EditableUserInfoType = Omit<UserInfoRowType, 'username'>;

export default function AccountSettings() {
  const [user, setUser] = useState<User | null>(null);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [isVerifyPasswordDialogOpen, setIsVerifyPasswordDialogOpen] =
    useState(false);
  const [verifiedPassword, setVerifiedPassword] = useState('');
  const [fieldToEdit, setFieldToEdit] = useState<EditableUserInfoType | null>(
    null,
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchUser() {
      const user = await getUser();
      setUser(user);
      setLoading(false);
    }
    fetchUser();
  }, []);

  const deleteAccount = async () => {
    try {
      await deleteAccountRequest();
      await Auth.deleteUser();
      await Auth.signOut({global: true});
    } catch (err) {
      console.error(err);
      if (err instanceof Error) {
        if (err.message === 'Access Token has been revoked') {
          await Auth.signOut();
        }
      }
    }
  };

  const clearFieldToEdit = () => setFieldToEdit(null);

  const renderEditForm = (fieldToEdit: EditableUserInfoType) => {
    let editForm;
    if (fieldToEdit === 'fullname' && user) {
      editForm = (
        <ChangeName
          firstName={user.firstName}
          lastName={user.lastName}
          onCancel={clearFieldToEdit}
          onSaved={({firstName = '', lastName = ''}) => {
            setUser(
              (prevState) => ({...prevState, firstName, lastName} as User),
            );
            setFieldToEdit('');
          }}
        />
      );
    }
    if (fieldToEdit === 'password' && verifiedPassword) {
      editForm = (
        <ChangePassword
          onCancel={clearFieldToEdit}
          oldPassword={verifiedPassword}
          onSaved={(password: string) => {
            setVerifiedPassword(password);
            setFieldToEdit('');
          }}
        />
      );
    }

    return (
      <Box position="relative">
        {editForm}
        <BackButton onClick={clearFieldToEdit} />
      </Box>
    );
  };

  if (loading) {
    return <Loader height="100vh" />;
  }

  const infoRows = [
    {
      label: 'Full Name',
      type: 'fullname',
      value: getFullname(user as User),
    },
    {
      label: 'Username',
      type: 'username',
      value: user?.username,
    },
    {
      label: 'Password',
      type: 'password',
      value: undefined,
    },
  ];

  return (
    <StyledContainer component="main">
      <PageHeader title="Account settings" />
      {fieldToEdit ? (
        renderEditForm(fieldToEdit)
      ) : (
        <>
          <List>
            {infoRows.map(({type, label, value = ''}, index) => {
              let onClick;

              if (type === 'password') {
                onClick = () => setIsVerifyPasswordDialogOpen(true);
              }

              if (type === 'fullname') {
                onClick = () => setFieldToEdit('fullname');
              }
              return (
                <UserInfoRow
                  key={type}
                  type={type as UserInfoRowType}
                  label={label}
                  value={value}
                  divider={infoRows.length !== index + 1}
                  onClick={onClick}
                />
              );
            })}
          </List>
          <Box display="flex" justifyContent="flex-end" mt={6}>
            <Button
              label="Delete account"
              variant="text"
              color="error"
              endIcon={<TrashIcon />}
              onClick={() => setIsDeleteAlertOpen(true)}
            />
          </Box>
        </>
      )}
      <ConfirmationDialog
        open={isDeleteAlertOpen}
        text="Are you sure you want to delete your account?"
        confirmText="Delete"
        color="error"
        onConfirm={deleteAccount}
        onClose={() => setIsDeleteAlertOpen(false)}
      />
      <VerifyPasswordDialog
        open={isVerifyPasswordDialogOpen}
        onVerify={(password: string) => {
          setVerifiedPassword(password);
          setFieldToEdit('password');
          setIsVerifyPasswordDialogOpen(false);
        }}
        onClose={() => setIsVerifyPasswordDialogOpen(false)}
      />
    </StyledContainer>
  );
}
