import * as React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import {visuallyHidden} from '@mui/utils';
import styled from '@emotion/styled';
import {black54} from 'view/theme/colors';
import SortIcon from 'view/components/icons/Sort';

const StyledTableHead = styled(TableHead)`
  .MuiTableCell-head {
    border: none;
    font-size: 12px;
    color: ${black54};

    .MuiTableSortLabel-root {
      color: ${black54};
    }

    .MuiTableSortLabel-icon {
      margin: 0 0 0 0.5rem;
    }
  }
`;

export type Order = 'asc' | 'desc';

export interface HeadCell<T> {
  disablePadding: boolean;
  id: keyof T;
  label: string;
  numeric: boolean;
  align?: 'right' | 'left' | 'center';
  isSortable?: boolean;
  width?: number | string;
}

interface TableHeaderProps<T> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  headCells: HeadCell<T>[];
  order: Order;
  orderBy: string;
  rowCount: number;
}

export default function TableHeader<T>(props: TableHeaderProps<T>) {
  const {order, orderBy, onRequestSort} = props;
  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <StyledTableHead>
      <TableRow>
        {props.headCells.map((headCell: HeadCell<T>) => (
          <TableCell
            key={String(headCell.id)}
            align={headCell.align || 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{flexDirection: 'row', width: headCell.width}}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.isSortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                IconComponent={() => <SortIcon />}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden} />
                ) : null}
              </TableSortLabel>
            ) : (
              <>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden} />
                ) : null}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}
