import {useState} from 'react';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import UserInfo from 'view/components/UserInfo';
import Button from 'view/components/Button';
import {lightPurple} from 'view/theme/colors';
import {UserStatus} from 'types/users';

export const StyledUserCard = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.125rem;

  .MuiButton-root {
    background: ${lightPurple};
  }
`;

type UserCardProps = {
  firstName: string;
  lastName: string;
  username: string;
  status: UserStatus;
  divider?: boolean;
  onAddContact?: () => void;
};

export default function UserCard({
  firstName,
  lastName,
  username,
  status,
  divider,
  onAddContact,
}: UserCardProps) {
  const [submitting, setSubmitting] = useState(false);

  const onClickAddContact = async () => {
    if (onAddContact) {
      setSubmitting(true);

      try {
        await onAddContact();
      } catch (err) {
        if (err instanceof Error) {
          throw err;
        }
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <StyledUserCard>
        <UserInfo
          username={username}
          firstName={firstName}
          lastName={lastName}
          status={status}
        />
        {onAddContact && (
          <Box display="flex" alignItems="center">
            <Button
              variant="text"
              label="Add contact"
              withLoader
              loading={submitting}
              onClick={() => onClickAddContact()}
            />
          </Box>
        )}
      </StyledUserCard>
      {divider && <Divider />}
    </>
  );
}
