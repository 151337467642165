import {User} from 'types/users';
import {request} from '../../request';
import {PaginatedResponse} from '../../types';
import {Device} from 'types/devices';
import {ContactWithCall} from 'types/contacts';

export const listUsers = async ({
  searchQuery = '',
  page = 1,
  orderBy = '',
  orderDirection = 'ASC',
  filters = {},
  url = '/admin/users',
}): Promise<PaginatedResponse<User>> => {
  const {data} = await request({
    url,
    params: {
      page,
      orderBy,
      orderDirection,
      ...(searchQuery.trim() && {searchQuery: searchQuery.trim()}),
      ...(page && {page}),
      ...(filters && {filters}),
    },
  });
  return data;
};

export const deleteUser = async (userId: string): Promise<void> => {
  await request({
    method: 'delete',
    url: `/admin/users/${userId}`,
  });

  return;
};

export const removeUserContact = async (
  userId: string,
  contactId: string,
): Promise<void> => {
  await request({
    method: 'delete',
    url: `/admin/users/${userId}/contacts/${contactId}`,
  });

  return;
};

export const getUserInfo = async (userId: string): Promise<User> => {
  const {data} = await request({
    url: `/admin/users/${userId}`,
  });

  return data;
};

export const getUserDevices = async (userId: string): Promise<Device[]> => {
  const {data} = await request({
    url: `/admin/users/${userId}/devices`,
  });

  return data;
};

export const listContacts = async (
  {searchQuery = '', page = 1, orderBy = '', orderDirection = 'ASC'},
  userId: string,
): Promise<PaginatedResponse<ContactWithCall>> => {
  const {data} = await request({
    url: `/admin/users/${userId}/contacts`,
    params: {
      page,
      orderBy,
      orderDirection,
      ...(searchQuery.trim() && {searchQuery: searchQuery.trim()}),
      ...(page && {page}),
    },
  });
  return data;
};

export const updateUser = async (
  userId: string,
  update: Partial<User>,
): Promise<User> => {
  const {data} = await request({
    method: 'patch',
    url: `/admin/users/${userId}`,
    data: update,
  });

  return data;
};
