import {intervalToDuration, isWithinInterval, sub} from 'date-fns';
import {MapOrigin} from 'types/map';
import pluralize from 'pluralize';

export const getFullname = ({firstName = '', lastName = ''}) => {
  return `${firstName} ${lastName}`;
};

export const getLastArrayElement = (arr: any[]) => {
  return arr[arr.length - 1];
};

export const isLastWeek = (date: Date) =>
  isWithinInterval(date, {
    start: sub(Date.now(), {days: 7}),
    end: Date.now(),
  });

export const getCallDuration = (
  startTime: Date | null,
  endTime: Date | null,
) => {
  if (!startTime || !endTime) {
    return '';
  }
  const {days, hours, minutes, seconds} = intervalToDuration({
    start: new Date(startTime),
    end: new Date(endTime),
  });
  if (days) {
    return `${days} days ${hours ? `${hours} h` : ''}`;
  }
  if (hours) {
    return `${hours} h ${minutes ? `${minutes} min` : ''}`;
  }
  if (minutes) {
    return `${minutes} min ${seconds ? `${seconds} sec` : ''}`;
  }
  return `${seconds ? `${seconds} sec` : ''}`;
};

export const formatMinutesDuration = (value?: number) => {
  if (!value) {
    return 'None';
  }

  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  const hoursStr = hours ? `${hours} ${pluralize('hour', hours)} ` : '';
  const minutesStr = minutes
    ? `${minutes} ${pluralize('minute', minutes)}`
    : '';

  return `${hoursStr}${minutesStr}`;
};

const findLocation = (
  {currentLat, currentLng}: {currentLat: number; currentLng: number},
  {lat, lng}: {lat: number; lng: number},
  accuracy: number,
) => {
  return (
    currentLat < lat + accuracy &&
    currentLat > lat - accuracy &&
    currentLng < lng + accuracy &&
    currentLng > lng - accuracy
  );
};

export const getGroupedMarkersByLocation = (
  origins: MapOrigin[],
  accuracy: number,
) => {
  return origins.reduce((acc, origin) => {
    const {
      location: {lat, lng},
    } = origin;
    const isInCurrentLocation = Object.keys(acc).find((currentLocation) => {
      return findLocation(
        {currentLat: lat, currentLng: lng},
        JSON.parse(currentLocation),
        accuracy,
      );
    });

    if (isInCurrentLocation) {
      (acc[isInCurrentLocation] ||= []).push(origin);
      return acc;
    }

    (acc[JSON.stringify({lat, lng})] ||= []).push(origin);
    return acc;
  }, {} as {[position: string]: MapOrigin[]});
};

export const getMapPixelPositionOffset = (width: number, height: number) => ({
  x: -(width / 2),
  y: -(height / 2),
});
