import React, {useState} from 'react';
import Box from '@mui/material/Box';
import PageHeader from 'view/components/PageHeader';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import Loader from 'view/components/Loader';
import Alert from 'view/components/Alert';
import Search from 'view/components/Search';
import Button from 'view/components/Button';
import DownloadLink from 'view/components/DownloadLink';
import {getOS, OS} from 'tools/getOS';
import DevicesList from 'view/pages/Devices/components/DevicesList';
import {StyledContainer} from './styled';
import {
  disconnectDevice as disconnectDeviceApi,
  updateUserDevice,
} from 'services/api/user';
import {Device} from 'types/devices';
import useUser from 'hooks/user';
import DownloadIcon from 'view/components/icons/Download';
import axios, {AxiosError} from 'axios';

export default function Devices() {
  const {isLoading, devices, setUser, setDevices, disconnectDevice} = useUser();

  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResult, setSearchResult] = useState<Device[]>([]);
  const [deviceToRemove, setDeviceToRemove] = useState<Device | null>(null);

  const searchDevices = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    const searchParameter = e.target.value.toLowerCase();
    const devicesResult = devices.filter((device) => {
      return (
        device.displayName.toLowerCase().includes(searchParameter) ||
        device.deviceType.toLowerCase().includes(searchParameter)
      );
    });
    setSearchResult(devicesResult);
  };

  const handleDisconnectDevice = async (device: Device) => {
    try {
      const updatedUser = await disconnectDeviceApi(device.id);
      disconnectDevice(device.id);
      setUser(updatedUser);
      setDeviceToRemove(null);
    } catch (err) {
      console.error(err);

      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const handleDeviceNameChange = async (id: string, displayName: string) => {
    try {
      const updatedUser = await updateUserDevice(id, {displayName});
      setDevices(updatedUser.devices);
    } catch (err) {
      console.error(err);

      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const onClearSearch = () => setSearchQuery('');

  const onDeleteConfirmationClose = () => setDeviceToRemove(null);

  const onDeleteConfirmation = () =>
    handleDisconnectDevice(deviceToRemove as Device);

  const DownloadAppLinks = () => {
    const os = getOS();

    if ([OS.Windows, OS.Linux, OS['Mac OS']].includes(os as OS)) {
      return (
        <DownloadLink>
          <Button
            label="Download an app"
            withLoader
            startIcon={<DownloadIcon />}
          />
        </DownloadLink>
      );
    }
    return null;
  };

  return (
    <StyledContainer component="main">
      <PageHeader
        title="Devices"
        action={
          devices.length ? (
            <Box
              sx={{
                display: 'flex',
                columnGap: '1rem',
              }}
            >
              <Box sx={{width: '15rem'}}>
                <Search
                  name="device-search"
                  value={searchQuery}
                  small
                  label="Search devices"
                  onChange={searchDevices}
                  onClear={onClearSearch}
                />
              </Box>
              <DownloadAppLinks />
            </Box>
          ) : undefined
        }
      />
      <Alert
        open={!!error}
        type="error"
        label={error}
        position="right"
        onClose={() => setError('')}
      />
      {deviceToRemove && (
        <ConfirmationDialog
          open
          text={`Are you sure you want to disconnect ${deviceToRemove.displayName}?`}
          confirmText="Delete"
          onConfirm={onDeleteConfirmation}
          onClose={onDeleteConfirmationClose}
        />
      )}
      {isLoading ? (
        <Loader justify="flex-start" />
      ) : (
        <DevicesList
          searchQuery={searchQuery}
          devices={searchQuery ? searchResult : devices}
          totalDevices={searchQuery ? searchResult.length : devices.length}
          onClickChangeDeviceName={handleDeviceNameChange}
          onClickDisconnectDevice={(device: Device) =>
            setDeviceToRemove(device)
          }
        />
      )}
    </StyledContainer>
  );
}
