import {UserStatus} from 'types/users';
import {HeadCell} from 'view/components/Table/components/TableHeader';
import {DEVICE_STATUS} from 'types/devices';

export interface Data {
  deviceType: string;
  displayName: string;
  id: number;
  userStatus: UserStatus;
  deviceStatus: DEVICE_STATUS;
  createdAt: string;
  lastActiveAt: string;
  owner: string;
}

export const headCells: HeadCell<Data>[] = [
  {
    id: 'deviceType',
    numeric: false,
    disablePadding: true,
    label: 'Device type',
    isSortable: true,
    width: 120,
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'Device id',
    isSortable: true,
    width: 200,
  },
  {
    id: 'deviceStatus',
    numeric: true,
    disablePadding: false,
    label: 'Device status',
    width: 120,
  },
  {
    id: 'userStatus',
    numeric: true,
    disablePadding: false,
    label: 'User status',
    width: 140,
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Registration date',
    isSortable: true,
    width: 180,
  },
  {
    id: 'lastActiveAt',
    numeric: true,
    align: 'right',
    disablePadding: false,
    label: 'Last active',
    isSortable: true,
    width: 160,
  },
  {
    id: 'owner',
    numeric: true,
    disablePadding: false,
    label: 'Owner',
  },
];
