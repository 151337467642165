import React, {ReactElement, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {SnackbarCloseReason} from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from 'view/components/icons/Close';
import {StyledDrawer, StylePageHeader} from './styled';
import useAdmin from 'hooks/admin';
import BackButton from '../BackButton';

type DrawerProps = {
  title: ReactElement;
  open: boolean;
  onClose?: () => void;
  onBackButtonClick?: () => void;
  action: ReactElement;
  width: string;
  top: string;
  minPadding?: boolean;
  variant: 'permanent' | 'persistent' | 'temporary' | undefined;
  marginTop: string;
  height: string;
  backButtonStyle?: React.CSSProperties;
};

export default function Drawer({
  open: propOpen,
  title,
  onClose,
  action,
  width,
  top,
  minPadding,
  variant,
  marginTop,
  height,
  onBackButtonClick,
  backButtonStyle,
}: DrawerProps) {
  const {drawerHistory, setDrawerHistory} = useAdmin();
  const [open, setOpen] = useState(propOpen);

  const showBackButton = drawerHistory.length > 1 || onBackButtonClick;

  useEffect(() => {
    setOpen(propOpen);
  }, [propOpen]);

  const closeHandler = (
    _event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') return;
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
    setDrawerHistory([]);
  };

  const handleBackClick = () => {
    const updatedHistory = drawerHistory.filter(
      (item, index) => index !== drawerHistory.length - 1,
    );
    setDrawerHistory(updatedHistory);
  };

  return (
    <StyledDrawer
      variant={variant}
      anchor="right"
      PaperProps={{
        sx: {
          width: width,
          overflow: 'visible',
          marginTop: marginTop,
          height: height,
        },
      }}
      open={open}
    >
      <Box
        sx={{
          position: 'absolute',
          left: '-1.5rem',
          top: top,
        }}
      >
        <IconButton
          color="primary"
          onClick={closeHandler}
          className="close-drawer"
          aria-label="back"
          disableRipple
          disableFocusRipple
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        p={minPadding ? '0 2rem 0 2rem' : '0 4rem 0 4rem'}
        sx={{overflowY: 'auto', height: '100%'}}
      >
        <StylePageHeader
          sx={{padding: minPadding ? '2.1rem 0 1rem' : '4rem 0 3.5rem'}}
        >
          {title}
        </StylePageHeader>
        {showBackButton && (
          <Box position="relative">
            <BackButton
              onClick={onBackButtonClick || handleBackClick}
              style={backButtonStyle}
            />
          </Box>
        )}
        {action}
      </Box>
    </StyledDrawer>
  );
}
