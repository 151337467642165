import {ReactElement, useState} from 'react';
import {Control} from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import Input from 'view/components/Form/Input';
import EyeIcon from 'view/components/icons/Eye';
import EyeOffIcon from 'view/components/icons/EyeOff';

type FormInputProps = {
  label?: string;
  name?: string;
  error?: boolean;
  inputProps?: object;
  helperText?: ReactElement | string;
  control: Control<any, any>;
  fullWidth?: boolean;
  mb?: number;
  mt?: number;
};

export default function PasswordFormInput({
  label = 'Password',
  name = 'password',
  inputProps,
  error,
  helperText,
  control,
  fullWidth = true,
  mt,
  mb,
}: FormInputProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <Input
      type={showPassword ? 'text' : 'password'}
      name={name}
      inputProps={inputProps}
      label={label}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
      endAdornment={
        <IconButton
          onClick={() => setShowPassword(!showPassword)}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <EyeOffIcon /> : <EyeIcon />}
        </IconButton>
      }
      control={control}
      mt={mt}
      mb={mb}
    />
  );
}
