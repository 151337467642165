import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import {black4, purple} from 'view/theme/colors';

export const StyledDeviceCard = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0.125rem;
  transition: transform 0.25s ease-in-out;
  outline: 1px solid transparent;

  .MuiIconButton-root {
    display: hidden;
  }

  .fullname.small {
    font-size: 0.875rem;
  }

  .username.small {
    font-size: 0.75rem;
  }

  &.elevated {
    outline: 1px solid ${black4};
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem;
    transform: scale3d(1.05, 1.05, 1);
    background: white;
    box-shadow: 0px 8px 24px 0px #00000014;

    .fullname {
      color: ${purple};
    }

    .MuiIconButton-root {
      display: inline-flex;
    }
  }
`;
