import * as React from 'react';
import {Box, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import DeviceInfo from 'view/components/DeviceInfo';
import ChevronIcon from 'view/components/icons/Chevron';
import {MapOrigin} from 'types/map';
import {getFullname} from 'tools';
import {DEVICE_CALL_STATUS} from 'types/devices';

type GroupedMarkerProps = {
  data: MapOrigin[];
  openDrawerOrigin: (id: string) => void;
  setId: (id: string) => void;
  setSelectedOriginId: (id: string) => void;
};

type MapOriginMapping = {[userId: string]: MapOrigin[]};

export default function GroupedMarker({
  data,
  openDrawerOrigin,
  setId,
  setSelectedOriginId,
}: GroupedMarkerProps) {
  if (!data?.length) {
    return null;
  }

  const groupedDevicesByUserId: MapOriginMapping = data.reduce(
    (acc: any, device) => {
      (acc[device.user?.id] ||= []).push(device);
      return acc;
    },
    {} as MapOriginMapping,
  );

  return (
    <>
      <Typography
        variant="h1"
        noWrap
        sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}
        mb={3}
      >
        {data.length} devices in this location
      </Typography>
      <Box sx={{minWidth: 0, display: 'flex', flexDirection: 'column'}}>
        {Object.keys(groupedDevicesByUserId).map((userId) => {
          const devices = groupedDevicesByUserId[userId];
          const [{user}] = devices;
          return (
            <>
              <Typography variant="body1" fontWeight={500} mr={1}>
                @{user.username} ({getFullname(user)}) devices
              </Typography>
              <Box mb={3}>
                {devices.map((origin: MapOrigin) => {
                  const deviceCallStatusLabel =
                    origin.callStatus === DEVICE_CALL_STATUS.On_the_call
                      ? origin.callStatus
                      : undefined;
                  return (
                    <Box>
                      <Box
                        key={origin.id}
                        pb={3}
                        pt={3}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <DeviceInfo
                          id={origin.id}
                          name={origin.deviceType}
                          type={origin.deviceType}
                          status={origin.status}
                          callStatus={deviceCallStatusLabel}
                        />
                        <IconButton
                          disableRipple
                          onClick={() => {
                            setId(origin.id);
                            openDrawerOrigin(origin.id);
                          }}
                        >
                          <ChevronIcon />
                        </IconButton>
                      </Box>
                      <Divider />
                    </Box>
                  );
                })}
              </Box>
            </>
          );
        })}
      </Box>
    </>
  );
}
