import * as React from 'react';
import format from 'date-fns/format';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ActionMenu, {MenuItemType} from 'view/components/ActionMenu';
import ListIcon from 'view/components/icons/List';
import Loader from 'view/components/Loader';
import TablePagination from 'view/components/Table/components/TablePagination';
import TableHeader, {Order} from 'view/components/Table/components/TableHeader';
import {Data, headCells} from './helpers';
import {PaginatedResponse} from 'services/api/types';
import UserAvatar from 'view/components/UserInfo/UserAvatar';
import DeleteIcon from 'view/components/icons/Delete';
import {ContactWithCall} from 'types/contacts';
import {getCallDuration} from 'tools';
import {deletedUserName} from 'constraints/user';

type ContactsTableProps = {
  selectUserId: (userId: string) => void;
  setSorting: (sorting: [string, Order]) => void;
  onRemoveContact: (contact: ContactWithCall) => void;
  onPageChange: (_event: unknown, newPage: number) => void;
  loading: boolean;
  data: PaginatedResponse<ContactWithCall>;
  page: number;
  sorting: [string, Order];
};

const ContactsTable = ({
  loading,
  selectUserId,
  data,
  page,
  sorting,
  onPageChange,
  setSorting,
  onRemoveContact,
}: ContactsTableProps) => {
  const rowsPerPage = 8;
  const orderBy = sorting[0];
  const order = sorting[1];

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setSorting([property, isAsc ? 'desc' : 'asc']);
  };

  const handleCallMode = (callerId: string, userId: string) => {
    if (isCaller(callerId, userId)) {
      return 'Outgoing';
    }
    return 'Incoming';
  };

  const isCaller = (callerId: string, userId: string) => {
    return callerId === userId;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, page * rowsPerPage - data.meta.totalItems) : 0;

  if (!data.items.length) {
    return (
      <Box sx={{width: '100%'}}>
        <Typography variant="body2">No users found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{width: '100%'}}>
      <Paper sx={{width: '100%', mb: 2, boxShadow: 'none'}}>
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
            <TableHeader
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.meta.totalItems}
            />
            {loading ? (
              <TableRow
                sx={{
                  'th,td': {borderBottom: 'none'},
                  height: 73 * rowsPerPage,
                }}
              >
                <TableCell colSpan={6}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {data.items.map((row: ContactWithCall, index: number) => {
                  const menuItems: MenuItemType[] = [
                    {
                      label: 'Profile',
                      icon: <ListIcon />,
                      onClick: () => selectUserId(row.contactId),
                    },
                    {
                      label: 'Remove',
                      icon: <DeleteIcon />,
                      onClick: () => onRemoveContact(row),
                      className: 'error',
                    },
                  ];
                  return (
                    <TableRow
                      sx={
                        index + 1 === data.items.length
                          ? {
                              'th,td': {borderBottom: 'none'},
                            }
                          : undefined
                      }
                      role="checkbox"
                      tabIndex={-1}
                      key={row.contactId}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        sx={{padding: '1rem 0'}}
                      >
                        <Box display="flex" sx={{alignItems: 'center'}}>
                          <UserAvatar
                            firstName={row.firstName}
                            lastName={row.lastName}
                            status={row.status}
                            inactive={!row.active}
                          />
                          <Box
                            sx={{
                              maxWidth: headCells[index]?.width || 'unset',
                              overflowWrap: 'break-word',
                            }}
                          >
                            {row.active
                              ? `${row.firstName} ${row.lastName}`
                              : deletedUserName}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          maxWidth: headCells[index]?.width || 'unset',
                          overflowWrap: 'break-word',
                        }}
                      >
                        {row.username}
                      </TableCell>
                      <TableCell align="left">
                        {isCaller(row.call?.callerId, row.contactId)
                          ? row.call?.callerDeviceId
                          : row.call?.calleeDeviceId || '-'}
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">
                          {row.call?.startTime
                            ? format(
                                new Date(row.call.startTime),
                                'MMM d, yyyy',
                              )
                            : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          {getCallDuration(
                            row.call?.startTime,
                            row.call?.endTime,
                          ) || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {row.call?.callerId
                          ? handleCallMode(row.call.callerId, row.userId)
                          : '-'}
                      </TableCell>
                      <TableCell align="right" sx={{paddingRight: 0}}>
                        <ActionMenu
                          small
                          buttonIcon={<MoreVertIcon />}
                          menuItems={menuItems}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && page !== 1 && (
                  <TableRow
                    sx={{
                      'th,td': {borderBottom: 'none'},
                      height: 73 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          page={page}
          totalItems={data.meta.totalItems}
          totalPages={data.meta.totalPages}
          onPageChange={onPageChange}
          rowsPerPage={data.meta.itemsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default ContactsTable;
