import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {StyledChip} from './styled';
import {Device} from 'types/devices';
import LargeSizeDeviceCard from 'view/components/DeviceCard/LargeSizeDeviceCard';
import {getOS, OS} from 'tools/getOS';
import DownloadLink from 'view/components/DownloadLink';

type DevicesListProps = {
  totalDevices: number;
  searchQuery: string;
  devices: Array<Device>;
  onClickDisconnectDevice: (device: Device) => void;
  onClickChangeDeviceName: (id: string, device: string) => void;
};

export default function DevicesList({
  devices,
  totalDevices,
  searchQuery,
  onClickDisconnectDevice,
  onClickChangeDeviceName,
}: DevicesListProps) {
  if (!totalDevices && !searchQuery) {
    const os = getOS();

    if ([OS.Windows, OS.Linux, OS['Mac OS']].includes(os as OS)) {
      return (
        <Box sx={{display: 'flex', margin: 'auto'}}>
          <Typography variant="body2" textAlign="center" fontSize="1rem">
            You have not connected any devices yet.
            <br />
            Navigate to Desktop app and connect your Clara or Haru.
            <br />
            To install Desktop app click a <DownloadLink label="link" />.
          </Typography>
        </Box>
      );
    }
    return (
      <Box sx={{display: 'flex', margin: 'auto'}}>
        <Typography variant="body2" textAlign="center" fontSize="1rem">
          Something went wrong
        </Typography>
      </Box>
    );
  }

  const handleSummaryText = (
    searchQuery?: string,
    totalDevices: number = 0,
  ) => {
    if (searchQuery && totalDevices) {
      return 'Devices found';
    }
    if (searchQuery && !totalDevices) {
      return 'No devices found';
    }
    if (!searchQuery && totalDevices) {
      return 'Connected devices';
    }
    return 'No devices yet.';
  };

  return (
    <Box mb={2}>
      <Box display="flex">
        <Typography
          variant="subtitle1"
          mb={0.5}
          sx={{display: 'flex', alignItems: 'center'}}
        >
          {handleSummaryText(searchQuery, totalDevices)}
          {!!totalDevices && <StyledChip label={totalDevices} size="small" />}
        </Typography>
      </Box>
      {devices
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map((device, index, devices) => (
          <LargeSizeDeviceCard
            key={device.id}
            device={device}
            onClickChangeDeviceName={onClickChangeDeviceName}
            onClickDisconnectDevice={() => onClickDisconnectDevice(device)}
            divider={devices.length > index + 1}
          />
        ))}
    </Box>
  );
}
