import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import {black87, purple, white} from 'view/theme/colors';
import styled from '@emotion/styled';
import Input from 'view/components/Input';

export const StyledPopper = styled(Popper)`
  z-index: 100;
  width: 100%;
  position: absolute !important;
  top: 4rem !important;
  background: ${white};
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
`;

export const StyledSelectItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  &:hover {
    background: rgba(72, 83, 231, 0.08);

    .MuiTypography-root {
      color: ${purple};
    }
  }
`;

export const StyledInput = styled(Input)`
  .MuiFormLabel-root.Mui-disabled {
    color: rgba(26, 26, 26, 0.54);
  }
  .MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: inherit;
    color: ${black87};
  }
`;
