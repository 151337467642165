import Accordion from '../Accordion';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import DevicesTable from '../DevicesTable';
import React, {useCallback, useEffect, useState} from 'react';
import debounce from 'lodash.debounce';
import {updateDeviceOwner as updateDeviceOwnerApi} from 'services/api/admin/devices';
import {Device, DeviceWithUser} from 'types/devices';
import {
  getUserDevices as getUserDevicesApi,
  listContacts,
  removeUserContact,
} from 'services/api/admin/user';
import {User, UserStatus} from 'types/users';
import ContactsTable from '../ContactsTable';
import {PaginatedResponse} from 'services/api/types';
import {ContactWithCall} from 'types/contacts';
import {Order} from 'view/components/Table/components/TableHeader';
import Search from 'view/components/Search';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import {getFullname} from 'tools';
import Alert from 'view/components/Alert';
import BackButton from 'view/components/BackButton';
import UserInfoRow from './components/UserInfoRow';
import {formatDeviceRegistrationDate} from 'constraints/dates';
import ChangeName from './components/ChangeName';
import ChangeAvailabilityStatus from './components/ChangeAvailabilityStatus';
import Avatar from 'view/components/Avatar';
import useAdmin from 'hooks/admin';
import axios, {AxiosError} from 'axios';

type EditableUserFields = 'fullname' | 'availabilityStatus';

type UserDetailsProps = {
  user: User | null;
  onEditUser?: (user: User) => void;
};

const UserDetails = ({user, onEditUser}: UserDetailsProps) => {
  const {selectUserId, selectDeviceId} = useAdmin();

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState<DeviceWithUser[] | []>([]);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const [page, setPage] = React.useState(1);
  const [sorting, setSorting] = useState<[string, Order]>(['username', 'asc']);

  const [contactsData, setContactsData] = useState({
    items: [] as Array<ContactWithCall>,
    meta: {totalItems: 0},
  } as PaginatedResponse<ContactWithCall>);

  const [fieldToEdit, setFieldToEdit] = useState<EditableUserFields | null>(
    null,
  );
  const [contactToRemove, setContactToRemove] =
    useState<ContactWithCall | null>(null);
  const [deviceToDisconnect, setDeviceToDisconnect] = useState<Device | null>(
    null,
  );

  const handleChangePage = async (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserDevices = useCallback(async () => {
    setLoading(true);
    try {
      const searchResult = await getUserDevicesApi(user?.id || '');
      const updatedResult = searchResult.map((result) => {
        return {...result, user};
      });
      setDevices(updatedResult);
      setLoading(false);
    } catch (err) {
      if (err instanceof Error) {
        console.error(err);
        setError(err.message);
        setLoading(false);
      }
    }
  }, [user]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchContacts = useCallback(
    debounce(
      async (
        searchQuery: string,
        sorting: [string, 'asc' | 'desc'],
        page: number,
      ) => {
        setLoading(true);

        try {
          const searchResult = await listContacts(
            {
              searchQuery,
              orderBy: sorting[0],
              orderDirection: sorting[1],
              page,
            },
            user?.id || '',
          );
          setContactsData(searchResult);
          setLoading(false);
        } catch (err) {
          if (err instanceof Error) {
            console.error(err);
            setError(err.message);
            setLoading(false);
          }
        }
      },
      500,
    ),
    [user],
  );

  useEffect(() => {
    getUserDevices();
    searchContacts('', sorting, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setLoading(true);
    searchContacts(searchQuery, sorting, page);
  }, [searchContacts, searchQuery, sorting, page]);

  const onRemoveConfirmation = async (contact: ContactWithCall) => {
    try {
      await removeUserContact(contact.userId, contact.contactId);
      searchContacts(searchQuery, sorting, page);
      setContactToRemove(null);
    } catch (err) {
      console.error(err);

      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onDisconnectDevice = async (device: DeviceWithUser) => {
    try {
      await updateDeviceOwnerApi(device.id, null);
      const updatedDevices = devices.filter((item) => item.id !== device.id);
      setDevices(updatedDevices);
      setSuccessMessage('Device was disconnected successfully');
      setDeviceToDisconnect(null);
    } catch (err) {
      console.error(err);
      setLoading(false);

      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchQuery(e.target.value);
  };

  const onClearSearch = () => setSearchQuery('');

  const clearFieldToEdit = () => setFieldToEdit(null);

  if (!user) {
    return null;
  }

  const rows = [
    {
      label: 'Avatar',
      node: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body1" fontWeight={500}>
            Avatar helps personalise users profile
          </Typography>
          <Avatar firstName={user.firstName} lastName={user.lastName} />
        </Box>
      ),
    },
    {
      label: 'Full name',
      node: getFullname(user),
      onClick: () => setFieldToEdit('fullname'),
    },
    {
      label: 'Username',
      node: `@${user.username}`,
    },
    {
      label: 'Registration date',
      node: formatDeviceRegistrationDate(user.createdAt),
    },
    {
      label: 'User id',
      node: user.id,
    },
    {
      label: 'Availability status',
      node: user.status,
      onClick:
        user.status !== UserStatus.OFFLINE
          ? () => setFieldToEdit('availabilityStatus')
          : undefined,
    },
  ];

  const renderEditForm = (fieldToEdit: EditableUserFields) => {
    let editForm;
    if (fieldToEdit === 'fullname') {
      editForm = (
        <ChangeName
          user={user}
          onCancel={clearFieldToEdit}
          onSaved={(user) => {
            setSuccessMessage('User fullname changed successfully');
            onEditUser && onEditUser(user);
          }}
        />
      );
    }

    if (fieldToEdit === 'availabilityStatus') {
      editForm = (
        <ChangeAvailabilityStatus
          user={user}
          onCancel={clearFieldToEdit}
          onSaved={(user) => {
            setSuccessMessage('User availability status changed successfully');
            onEditUser && onEditUser(user);
          }}
        />
      );
    }

    return (
      <Box position="relative">
        {editForm}
        <BackButton onClick={clearFieldToEdit} />
      </Box>
    );
  };

  return (
    <Box>
      <Alert
        open={!!successMessage}
        type="success"
        label={successMessage}
        position="right"
        onClose={() => setSuccessMessage('')}
      />
      <Alert
        open={!!error}
        label={error}
        type="error"
        position="right"
        onClose={() => setError('')}
      />
      {contactToRemove && (
        <ConfirmationDialog
          open
          text={`Are you sure you want to remove ${getFullname(
            contactToRemove,
          )} from contacts?`}
          confirmText="Remove"
          onConfirm={() => onRemoveConfirmation(contactToRemove)}
          onClose={() => setContactToRemove(null)}
        />
      )}
      {deviceToDisconnect && (
        <ConfirmationDialog
          open
          text={`Are you sure you want to disconnect ${deviceToDisconnect.id} device?`}
          confirmText="Disconnect"
          onConfirm={() =>
            onDisconnectDevice(deviceToDisconnect as DeviceWithUser)
          }
          onClose={() => setDeviceToDisconnect(null)}
        />
      )}
      {fieldToEdit ? (
        renderEditForm(fieldToEdit)
      ) : (
        <>
          <List>
            {rows.map(({label, onClick, node}, index) => (
              <UserInfoRow
                key={label}
                label={label}
                divider={rows.length !== index + 1}
                onClick={onClick}
              >
                {node}
              </UserInfoRow>
            ))}
          </List>
          <Accordion
            defaultExpanded={true}
            disableGutters={true}
            square={true}
            summary="Devices"
            children={
              <DevicesTable
                selectUserId={selectUserId}
                selectDeviceId={selectDeviceId}
                onClickDisconnectDevice={(device: DeviceWithUser) =>
                  setDeviceToDisconnect(device)
                }
                loading={false}
                data={devices}
              />
            }
          />
          <Accordion
            defaultExpanded={true}
            disableGutters={true}
            square={true}
            summary="Contacts"
            children={
              <>
                <Box sx={{position: 'absolute', top: 0, right: 0}}>
                  <Search
                    small
                    name="devices-contacts"
                    value={searchQuery}
                    label="Search"
                    onChange={onSearchChange}
                    onClear={onClearSearch}
                  />
                </Box>
                <ContactsTable
                  selectUserId={selectUserId}
                  onRemoveContact={(contact: ContactWithCall) =>
                    setContactToRemove(contact)
                  }
                  loading={loading}
                  data={contactsData}
                  page={page}
                  sorting={sorting}
                  onPageChange={handleChangePage}
                  setSorting={setSorting}
                />
              </>
            }
          />
        </>
      )}
    </Box>
  );
};

export default UserDetails;
