export const APP_PATH = '/';
export const SIGNIN_PATH = '/sign-in';
export const SIGNUP_PATH = '/sign-up';
export const CONTACTS_PATH = '/contacts';
export const DEVICES_PATH = '/devices';
export const CALLS_PATH = '/calls';
export const ACCOUNT_SETTINGS_PATH = '/settings';

export const ADMIN_APP_PATH = '/admin';
export const ADMIN_DEVICES_PATH = `${ADMIN_APP_PATH}/devices`;
export const ADMIN_CALLS_PATH = `${ADMIN_APP_PATH}/calls`;
export const ADMIN_USERS_PATH = `${ADMIN_APP_PATH}/users`;
export const ADMIN_MAP_PATH = `${ADMIN_APP_PATH}/map`;
