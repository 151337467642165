import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import ActionMenu from 'view/components/ActionMenu';
import Avatar from 'view/components/Avatar';
import {getFullname} from 'tools';
import SettingsIcon from 'view/components/icons/Settings';
import Drawer from 'view/components/Drawer';
import LogoutIcon from 'view/components/icons/Logout';
import {useContext, useState} from 'react';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import NavigationTabs from 'view/components/NavigationTabs';
import {Auth} from 'aws-amplify';
import {
  ADMIN_DEVICES_PATH,
  ADMIN_MAP_PATH,
  ADMIN_USERS_PATH,
} from 'view/routes';
import {AuthContext} from 'contexts/auth';
import {white} from 'view/theme/colors';
import LogoIcon from 'view/components/icons/Logo';
import MoreHorizontalIcon from 'view/components/icons/MoreHorizontal';
import DrawerDetails from './components/DrawerDetails';

const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-root {
    background: ${white};
    box-shadow: none;
  }
  .MuiToolbar-root {
    padding: 0 4rem 0 0;
    width: 16rem;
  }
`;

const adminTabs = [
  {
    id: 'navigation-tab-devices',
    to: ADMIN_DEVICES_PATH,
    label: 'Devices',
  },
  {
    id: 'navigation-tab-users',
    to: ADMIN_USERS_PATH,
    label: 'Users',
    disabled: false,
  },
  {
    id: 'navigation-tab-map',
    to: ADMIN_MAP_PATH,
    label: 'Map',
  },
];

const ResponsiveAppBar = () => {
  const {firstName, lastName} = useContext(AuthContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogToLogout, setDialogToLogout] = useState<Boolean>(false);

  const signOutClicked = async () => {
    try {
      await Auth.signOut({global: true});
    } catch (err) {
      console.error(err);
      if (err instanceof Error) {
        if (err.message === 'Access Token has been revoked') {
          await Auth.signOut();
        }
      }
    }
  };

  const onLogoutConfirmation = () => signOutClicked();

  const onConfirmationClose = () => {
    setDialogToLogout(false);
  };

  return (
    <StyledAppBar position="fixed">
      <Box px="4rem" display="flex">
        <Toolbar sx={{padding: 0}}>
          <LogoIcon />
        </Toolbar>
        <Box
          pl="4rem"
          display="flex"
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <NavigationTabs tabs={adminTabs} />
          </Box>
          <Box display="flex">
            <Avatar firstName={firstName} lastName={lastName} size={3} />
            <Box
              minWidth="0"
              mr={1}
              sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}
            >
              <Typography
                variant="body1"
                fontSize="0.875rem"
                sx={{lineHeight: '1.5rem'}}
                noWrap
              >
                {getFullname({firstName, lastName})}
              </Typography>
              <Typography
                variant="body2"
                fontSize="0.75rem"
                sx={{lineHeight: '1rem'}}
                noWrap
              >
                Administrator
              </Typography>
            </Box>
            <ActionMenu
              buttonIcon={<MoreHorizontalIcon />}
              menuItems={[
                {
                  label: 'Account settings',
                  icon: <SettingsIcon />,
                  onClick: () => setDrawerOpen(true),
                },
                {
                  label: 'Logout',
                  icon: <LogoutIcon />,
                  onClick: () => setDialogToLogout(true),
                },
              ]}
            />
          </Box>
        </Box>
      </Box>
      <Divider />
      <Drawer
        variant="temporary"
        marginTop="0"
        height="100vh"
        width={'calc(100% - 20rem)'}
        top={'3.5rem'}
        title={
          <Typography variant="h1" mr={2}>
            Account settings
          </Typography>
        }
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        action={<DrawerDetails />}
      />
      {dialogToLogout && (
        <ConfirmationDialog
          open
          text={`Do you want to log out? \n You will be returned to the login page.`}
          confirmText="Logout"
          color="primary"
          onConfirm={onLogoutConfirmation}
          onClose={onConfirmationClose}
        />
      )}
    </StyledAppBar>
  );
};
export default ResponsiveAppBar;
