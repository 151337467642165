import * as React from 'react';
import capitalize from 'lodash.capitalize';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from 'view/components/icons/Delete';
import ActionMenu, {MenuItemType} from 'view/components/ActionMenu';
import ListIcon from 'view/components/icons/List';
import {black87, purple} from 'view/theme/colors';
import {DEVICE_STATUS, DEVICE_TYPE, DeviceWithUser} from 'types/devices';
import ClaraIcon from 'view/components/icons/Clara';
import HaruIcon from 'view/components/icons/Haru';
import {UserStatus} from 'types/users';
import Chip from 'view/components/Chip';
import Loader from 'view/components/Loader';
import TablePagination from 'view/components/Table/components/TablePagination';
import TableHeader, {Order} from 'view/components/Table/components/TableHeader';
import {userStatuses} from 'view/constants';
import {getFullname} from 'tools';
import {Data, headCells} from './helpers';
import {PaginatedResponse} from 'services/api/types';
import {
  formatDeviceLastActiveDate,
  formatDeviceRegistrationDate,
} from 'constraints/dates';

export const renderDeviceStatusCell = (
  status: DEVICE_STATUS = DEVICE_STATUS.Offline,
) => {
  if (status === DEVICE_STATUS.Offline) {
    return <Chip label={DEVICE_STATUS.Offline} color="error" size="small" />;
  }
  return <Chip label={DEVICE_STATUS.Online} color="success" size="small" />;
};

export const renderUserStatusCell = (
  status: UserStatus = UserStatus.OFFLINE,
) => {
  const statusData = userStatuses.find(
    (userStatus) => userStatus.label === status,
  );
  return (
    <Chip label={statusData?.label} color={statusData?.color} size="small" />
  );
};

type DevicesTableProps = {
  selectDeviceId: (deviceId: string) => void;
  selectUserId: (userId: string) => void;
  onClickDisconnectDevice: (device: DeviceWithUser) => void;
  setSorting: (sorting: [string, Order]) => void;
  onPageChange: (_event: unknown, newPage: number) => void;
  loading: boolean;
  data: PaginatedResponse<DeviceWithUser>;
  page: number;
  sorting: [string, Order];
};

const DevicesTable = ({
  loading,
  selectDeviceId,
  selectUserId,
  onClickDisconnectDevice,
  data,
  page,
  sorting,
  onPageChange,
  setSorting,
}: DevicesTableProps) => {
  const rowsPerPage = 8;
  const orderBy = sorting[0];
  const order = sorting[1];

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setSorting([property, isAsc ? 'desc' : 'asc']);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, page * rowsPerPage - data.meta.totalItems) : 0;

  if (!data.items.length) {
    return (
      <Box sx={{width: '100%'}}>
        <Typography variant="body2">No devices found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{width: '100%'}}>
      <Paper sx={{width: '100%', mb: 2, boxShadow: 'none'}}>
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
            {loading ? (
              <TableRow
                sx={{
                  'th,td': {borderBottom: 'none'},
                  height: 73 * rowsPerPage,
                }}
              >
                <TableCell colSpan={6}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : (
              <>
                <TableHeader
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={data.meta.totalItems}
                />
                <TableBody>
                  {data.items.map((row: DeviceWithUser, index: number) => {
                    const menuItems: MenuItemType[] = [
                      {
                        label: 'Details',
                        icon: <ListIcon />,
                        onClick: () => selectDeviceId(row.id),
                      },
                    ];
                    if (row.user) {
                      menuItems.push({
                        label: 'Disconnect device',
                        icon: <DeleteIcon />,
                        onClick: () => onClickDisconnectDevice(row),
                        className: 'error',
                      });
                    }

                    return (
                      <TableRow
                        sx={
                          index + 1 === data.items.length
                            ? {
                                'th,td': {borderBottom: 'none'},
                              }
                            : undefined
                        }
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell component="th" scope="row" padding="none">
                          <Box display="flex" sx={{alignItems: 'center'}}>
                            {row.deviceType === DEVICE_TYPE.CLARA ? (
                              <ClaraIcon small />
                            ) : (
                              <HaruIcon small />
                            )}
                            <Typography
                              ml="22.55px"
                              variant="body2"
                              color={black87}
                              sx={{textTransform: 'capitalize'}}
                            >
                              {capitalize(row.deviceType)}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align={row.status ? 'left' : 'center'}>
                          {row.status
                            ? renderDeviceStatusCell(row.status)
                            : '-'}
                        </TableCell>
                        <TableCell align={row.user ? 'left' : 'center'}>
                          {row.user
                            ? renderUserStatusCell(row.user.status)
                            : '-'}
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2">
                            {formatDeviceRegistrationDate(row.createdAt)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2">
                            {row.lastActiveAt
                              ? formatDeviceLastActiveDate(row.lastActiveAt)
                              : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell
                          onClick={() => selectUserId(row.userId || '')}
                          align={row.user ? 'left' : 'center'}
                          sx={
                            row.user
                              ? {color: purple, cursor: 'pointer'}
                              : undefined
                          }
                        >
                          {row.user ? getFullname(row.user) : '-'}
                        </TableCell>
                        <TableCell align="right" sx={{paddingRight: 0}}>
                          <ActionMenu
                            small
                            buttonIcon={<MoreVertIcon />}
                            menuItems={menuItems}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      sx={{
                        'th,td': {borderBottom: 'none'},
                        height: 73 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          page={page}
          totalItems={data.meta.totalItems}
          totalPages={data.meta.totalPages}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default DevicesTable;
