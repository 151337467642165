import {User} from 'types/users';
import {request} from '../request';
import {PaginatedResponse} from '../types';
import {Device} from 'types/devices';
import {Call} from 'types/calls';

type CreateUserPayload = {
  username: string;
  firstName: string;
  lastName: string;
};

export const createUser = async (user: CreateUserPayload): Promise<void> => {
  await request({
    method: 'post',
    url: '/users',
    data: user,
  });
};

export const deleteAccount = async (): Promise<void> => {
  await request({
    method: 'delete',
    url: '/users/me',
  });
};

export const updateUser = async (user: Partial<User>): Promise<void> => {
  const {data: updated} = await request({
    method: 'patch',
    url: '/users/me',
    data: user,
  });

  return updated;
};

export const getUser = async (): Promise<User> => {
  const {data: users} = await request({
    url: '/users/me',
  });
  return users;
};

export const listUsers = async ({
  name = '',
  url = '/users',
}): Promise<PaginatedResponse<User>> => {
  const {data} = await request({
    url,
    params: {
      name,
    },
  });
  return data;
};

export const listCalls = async (
  url = '/users/me/calls',
): Promise<PaginatedResponse<Call>> => {
  const {data} = await request({
    url,
  });

  return data;
};

export const listSearchCalls = async ({
  name = '',
  url = 'users/me/calls',
}): Promise<PaginatedResponse<Call>> => {
  const {data} = await request({
    url,
    params: {
      name,
    },
  });
  return data;
};

export const getDevices = async (): Promise<Device[]> => {
  const {data: devices} = await request({
    url: '/users/me/devices',
  });

  return devices;
};

export const disconnectDevice = async (id: string) => {
  const {data: user} = await request({
    url: `/users/me/devices/${id}`,
    method: 'delete',
  });

  return user;
};

export const updateUserDevice = async (id: string, data: Partial<Device>) => {
  const {data: user} = await request({
    url: `/users/me/devices/${id}`,
    method: 'patch',
    data,
  });

  return user;
};

export const removeCall = async (callId: string): Promise<void> => {
  await request({
    method: 'delete',
    url: `/users/me/calls/${callId}`,
  });

  return;
};
