import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import {black40} from 'view/theme/colors';

// cast type to use "component" prop on Container
export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 7.5rem;
` as typeof Box;

export const StyledFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 27.5rem;
  width: 27.5rem;
`;

export const StyledFormActions = styled(Box)`
  display: flex;
  margin-top: 1.5rem;

  .MuiButton-root {
    padding: 0.75rem 3rem;

    &.save-button {
      margin-right: 1.5rem;
    }

    &.cancel-button {
      color: ${black40};
    }
  }
`;
