export enum OS {
  Windows = 'Windows',
  'Mac OS' = 'Mac OS',
  Linux = 'Linux',
}

export function getOS() {
  const userAgent = window.navigator.userAgent;
  const platform =
    // @ts-ignore
    window.navigator.userAgentData?.platform ?? window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.indexOf(platform) !== -1) {
    return OS['Mac OS'];
  }
  if (iosPlatforms.indexOf(platform) !== -1) {
    return 'iOS';
  }
  if (windowsPlatforms.indexOf(platform) !== -1) {
    return OS.Windows;
  }
  if (/Android/.test(userAgent)) {
    return 'Android';
  }
  if (/Linux/.test(platform)) {
    return OS.Linux;
  }
}
