import React, {ReactElement} from 'react';
import styled from '@emotion/styled';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const StyledAccordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    box-shadow: none;
    background: none;
  }
`;
const StyledAccordionSummary = styled(MuiAccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0;
    background: none;
    justify-content: flex-start;
  }

  .MuiAccordionSummary-content {
    flex-grow: 0;
  }
`;
const StyledAccordionDetails = styled(MuiAccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0;
  }
`;

type AccordionProps = {
  children: ReactElement | string;
  summary: ReactElement | string;
  defaultExpanded: boolean;
  disableGutters: boolean;
  square: boolean;
};

const Accordion = ({
  children,
  summary,
  defaultExpanded,
  disableGutters,
  square,
}: AccordionProps) => (
  <StyledAccordion
    defaultExpanded={defaultExpanded}
    disableGutters={disableGutters}
    square={square}
    sx={{
      '&:before': {
        display: 'none',
      },
    }}
  >
    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography
        variant="h2"
        mr={1}
        sx={{display: 'flex', alignItems: 'center'}}
      >
        {summary}
      </Typography>
    </StyledAccordionSummary>
    <StyledAccordionDetails>{children}</StyledAccordionDetails>
  </StyledAccordion>
);

export default Accordion;
