export const ClaraData = [
  {
    id: 1,
    settingName: 'DEVICE TYPE',
  },
  {
    id: 2,
    settingName: 'DEVICE ID',
  },
  {
    id: 3,
    settingName: 'ERROR STATUS',
  },
  {
    id: 4,
    settingName: 'USAGE',
  },
  {
    id: 5,
    settingName: 'FIRMWARE VERSION',
  },
];

export const HaruData = [
  {
    id: 1,
    settingName: 'DEVICE TYPE',
  },
  {
    id: 2,
    settingName: 'DEVICE ID',
  },
  {
    id: 3,
    settingName: 'ERROR STATUS',
    name: 'No errors',
  },
  {
    id: 4,
    settingName: 'USAGE',
  },
  {
    id: 5,
    settingName: 'FIRMWARE VERSION',
  },
  {
    id: 6,
    settingName: 'LAST CALIBRATION ROUTINE RESULT',
  },
];
