export function ArrowUpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <g
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        opacity="0.54"
      >
        <path d="M12 9L9 6 6 9M9 12V6"></path>
      </g>
    </svg>
  );
}
