import {HeadCell} from 'view/components/Table/components/TableHeader';

export interface Data {
  firstName: string;
  username: string;
  deviceId: string;
  createdAt: string;
  startTime: string;
  callDuration: string;
  callMode: string;
}

export const headCells: HeadCell<Data>[] = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'User & status',
    isSortable: true,
    width: 200,
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Username',
    isSortable: true,
    width: 140,
  },
  {
    id: 'deviceId',
    numeric: true,
    disablePadding: false,
    label: 'Device id',
    width: 310,
  },
  {
    id: 'startTime',
    numeric: false,
    disablePadding: false,
    label: 'Date of the call',
    width: 170,
  },
  {
    id: 'callDuration',
    numeric: false,
    disablePadding: false,
    label: 'Call duration',
    width: 100,
  },
  {
    id: 'callMode',
    numeric: false,
    disablePadding: false,
    label: 'Call mode',
    width: 100,
  },
];
