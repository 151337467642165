import React, {useCallback, useEffect, useState} from 'react';
import debounce from 'lodash.debounce';
import Box from '@mui/material/Box';
import {
  getDevices as getDevicesApi,
  updateDeviceOwner as updateDeviceOwnerApi,
} from 'services/api/admin/devices';
import {DEVICE_TYPE, DeviceWithUser, Device} from 'types/devices';
import {User, UserStatus} from 'types/users';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import {PaginatedResponse} from 'services/api/types';
import Filters, {FiltersData} from 'view/components/Filters';
import Search from 'view/components/Search';
import PageHeader from 'view/components/PageHeader';
import Alert from 'view/components/Alert';
import {Order} from 'view/components/Table/components/TableHeader';
import DevicesTable from './components/DevicesTable';
import {StyledContainer} from './styled';
import useAdmin from 'hooks/admin';
import AdminDrawer from 'view/components/AdminDrawer';
import axios, {AxiosError} from 'axios';

const deviceFilters = {
  deviceType: {
    label: 'Device type',
    options: {
      [DEVICE_TYPE.CLARA]: {
        label: 'Clara',
        value: DEVICE_TYPE.CLARA,
        checked: false,
      },
      [DEVICE_TYPE.HARU]: {
        label: 'Haru',
        value: DEVICE_TYPE.HARU,
        checked: false,
      },
    },
  },
  availabilityStatus: {
    label: 'Availability status',
    options: {
      [UserStatus.KEEN_TO_MEET]: {
        label: UserStatus.KEEN_TO_MEET,
        value: UserStatus.KEEN_TO_MEET,
        checked: false,
      },
      [UserStatus.AVAILABLE]: {
        label: UserStatus.AVAILABLE,
        value: UserStatus.AVAILABLE,
        checked: false,
      },
      [UserStatus.AVAILABLE_IF_NEEDED]: {
        label: UserStatus.AVAILABLE_IF_NEEDED,
        value: UserStatus.AVAILABLE_IF_NEEDED,
        checked: false,
      },
      [UserStatus.NOT_AVAILABLE]: {
        label: UserStatus.NOT_AVAILABLE,
        value: UserStatus.NOT_AVAILABLE,
        checked: false,
      },
      [UserStatus.OFFLINE]: {
        label: UserStatus.OFFLINE,
        value: UserStatus.OFFLINE,
        checked: false,
      },
    },
  },
};

const Devices = () => {
  const {selectUserId, selectDeviceId} = useAdmin();

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [deviceToDisconnect, setDeviceToDisconnect] = useState<Device | null>(
    null,
  );
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState({
    items: [] as Array<DeviceWithUser>,
    meta: {totalItems: 0},
  } as PaginatedResponse<DeviceWithUser>);
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filters, setFilters] = useState<FiltersData>(deviceFilters);
  const [sorting, setSorting] = useState<[string, Order]>([
    'createdAt',
    'desc',
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchDevices = useCallback(
    debounce(
      async (
        searchQuery: string,
        filters: FiltersData,
        sorting: [string, 'asc' | 'desc'],
        page: number,
      ) => {
        setLoading(true);

        try {
          const deviceType = Object.keys(filters.deviceType.options).filter(
            (option) => {
              return filters.deviceType.options[option].checked;
            },
          );
          const availabilityStatus = Object.keys(
            filters.availabilityStatus.options,
          ).filter((option) => {
            return filters.availabilityStatus.options[option].checked;
          });
          const searchResult = await getDevicesApi({
            searchQuery,
            orderBy: sorting[0],
            order: sorting[1],
            page,
            filters: {
              deviceType,
              availabilityStatus,
            },
          });
          setDevices(searchResult);
          setLoading(false);
        } catch (err) {
          if (err instanceof Error) {
            console.error(err);
            setError(err.message);
            setLoading(false);
          }
        }
      },
      500,
    ),
    [],
  );

  useEffect(() => {
    searchDevices('', filters, sorting, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    searchDevices(searchQuery, filters, sorting, page);
  }, [searchDevices, searchQuery, filters, sorting, page]);

  const handleChangePage = async (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onDisconnectDevice = async (device: DeviceWithUser) => {
    try {
      await updateDeviceOwnerApi(device.id, null);
      setLoading(true);
      await searchDevices(searchQuery, filters, sorting, page);
      setSuccessMessage('Device was disconnected successfully');
      setDeviceToDisconnect(null);
    } catch (err) {
      console.error(err);

      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onEditUser = async (user: User) => {
    try {
      await searchDevices(searchQuery, filters, sorting, page);
      selectUserId(user.id);
    } catch (err) {
      if (err instanceof Error) {
        console.error(err);
        setError(err.message);
        setLoading(false);
      }
    }
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchQuery(e.target.value);
  };

  const onClearSearch = () => setSearchQuery('');

  const onDisconnectClick = (device: DeviceWithUser) =>
    setDeviceToDisconnect(device);

  const onDisconnectConfirmationClose = () => setDeviceToDisconnect(null);

  const onDisconnectConfirmation = () =>
    onDisconnectDevice(deviceToDisconnect as DeviceWithUser);

  return (
    <>
      <Alert
        open={!!successMessage}
        type="success"
        label={successMessage}
        position="right"
        onClose={() => setSuccessMessage('')}
      />
      <Alert
        open={!!error}
        label={error}
        type="error"
        position="right"
        onClose={() => setError('')}
      />
      <Filters
        data={filters}
        onClear={() => setFilters(deviceFilters)}
        setFilters={(filters: FiltersData) => {
          setPage(1);
          setFilters(filters);
        }}
      />
      <StyledContainer component="main">
        <PageHeader
          title="Devices"
          action={
            <Box sx={{width: '20rem'}}>
              <Search
                small
                name="devices-search"
                value={searchQuery}
                label="Search"
                onChange={onSearchChange}
                onClear={onClearSearch}
              />
            </Box>
          }
        />
        <DevicesTable
          loading={loading}
          data={devices}
          page={page}
          sorting={sorting}
          selectUserId={selectUserId}
          selectDeviceId={selectDeviceId}
          onPageChange={handleChangePage}
          onClickDisconnectDevice={onDisconnectClick}
          setSorting={setSorting}
        />
      </StyledContainer>
      {deviceToDisconnect && (
        <ConfirmationDialog
          open
          text={`Are you sure you want to disconnect ${deviceToDisconnect.id} device?`}
          confirmText="Disconnect"
          onConfirm={onDisconnectConfirmation}
          onClose={onDisconnectConfirmationClose}
        />
      )}
      <AdminDrawer onEditUser={onEditUser} setDevices={setDevices} />
    </>
  );
};

export default Devices;
