import Box from '@mui/material/Box';
import {black29, black38, black71} from 'view/theme/colors';

type HaruIconProps = {
  small?: boolean;
};

export default function Haru({small}: HaruIconProps) {
  if (small) {
    return (
      <Box
        sx={{
          width: '2.153rem',
          height: '2.153rem',
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
        }}
      >
        <svg width="35" height="34" viewBox="0 0 35 34" fill="none">
          <path
            d="M5.16291 1.68306C5.14022 1.37879 5.23932 1.07797 5.43843 0.84677C5.63753 0.615573 5.92032 0.472939 6.22459 0.450246C6.52886 0.427554 6.82968 0.52666 7.06088 0.725765C7.29208 0.92487 7.43471 1.20766 7.45741 1.51193L6.31016 1.59749L5.16291 1.68306Z"
            fill="#4B4B4B"
          />
          <path
            d="M26.9927 1.50416C27.0168 1.2 27.1608 0.917889 27.3929 0.719884C27.6251 0.52188 27.9263 0.424202 28.2305 0.448339C28.5347 0.472477 28.8168 0.616452 29.0148 0.848592C29.2128 1.08073 29.3105 1.38202 29.2863 1.68618L28.1395 1.59517L26.9927 1.50416Z"
            fill="#4B4B4B"
          />
          <path
            d="M16.1055 7.54785H18.4063V19.0522H16.1055V7.54785Z"
            fill="#B5B5B5"
          />
          <path
            d="M12.3672 5.53418H21.8583L21.5707 7.83505H12.6548L12.3672 5.53418Z"
            fill="#B5B5B5"
          />
          <path
            d="M7 31.3574H28C28 32.462 27.1046 33.3574 26 33.3574H9C7.89543 33.3574 7 32.462 7 31.3574Z"
            fill="#B5B5B5"
          />
          <path
            d="M6.53906 29.6933C6.53906 23.7746 11.3371 18.9766 17.2558 18.9766C23.1745 18.9766 27.9725 23.7746 27.9725 29.6933V30.8537C27.9725 32.0016 27.0419 32.9322 25.894 32.9322H8.61762C7.46967 32.9322 6.53906 32.0016 6.53906 30.8537V29.6933Z"
            stroke="#4B4B4B"
          />
          <circle cx="10.6416" cy="24.8042" r="0.575217" fill="#9D9D9D" />
          <circle cx="22.4346" cy="24.8042" r="0.575217" fill="#9D9D9D" />
          <circle cx="10.6416" cy="26.2422" r="0.575217" fill="#9D9D9D" />
          <circle cx="22.4346" cy="26.2422" r="0.575217" fill="#9D9D9D" />
          <circle cx="12.0791" cy="26.2422" r="0.575217" fill="#9D9D9D" />
          <circle cx="23.8721" cy="26.2422" r="0.575217" fill="#9D9D9D" />
          <circle cx="12.0791" cy="24.8042" r="0.575217" fill="#9D9D9D" />
          <circle cx="23.8721" cy="24.8042" r="0.575217" fill="#9D9D9D" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.8047 26.2417C13.8047 25.924 14.0622 25.6665 14.3799 25.6665C14.6976 25.6665 14.9551 25.924 14.9551 26.2417V27.1049H19.5566V26.2417C19.5566 25.924 19.8142 25.6665 20.1319 25.6665C20.4495 25.6665 20.7071 25.924 20.7071 26.2417V27.1045C20.7071 27.1047 20.7071 27.1048 20.7071 27.1049H20.7073C20.7073 27.7403 20.1922 28.2554 19.5569 28.2554H14.9551C14.3198 28.2554 13.8047 27.7403 13.8047 27.1049C13.8047 27.1048 13.8047 27.1047 13.8047 27.1045V26.2417Z"
            fill="#B5B5B5"
          />
          <path
            d="M0.969215 8.34055C0.887859 7.24972 0.829376 6.46065 0.832285 5.83576C0.835167 5.21647 0.8992 4.80127 1.0394 4.45282C1.32804 3.73545 1.84613 3.13386 2.51275 2.74199C2.83655 2.55165 3.23765 2.42674 3.84966 2.33203C4.4672 2.23646 5.25622 2.17724 6.34705 2.09589C7.43788 2.01453 8.22696 1.95605 8.85184 1.95896C9.47114 1.96184 9.88633 2.02587 10.2348 2.16607C10.9522 2.45471 11.5537 2.9728 11.9456 3.63942C12.1359 3.96322 12.2609 4.36432 12.3556 4.97633C12.4511 5.59388 12.5104 6.38289 12.5917 7.47372C12.6731 8.56456 12.7316 9.35363 12.7286 9.97852C12.7258 10.5978 12.6617 11.013 12.5215 11.3615C12.2329 12.0788 11.7148 12.6804 11.0482 13.0723C10.7244 13.2626 10.3233 13.3875 9.71127 13.4822C9.09373 13.5778 8.30471 13.637 7.21388 13.7184C6.12305 13.7997 5.33397 13.8582 4.70909 13.8553C4.08979 13.8524 3.6746 13.7884 3.32615 13.6482C2.60878 13.3596 2.00719 12.8415 1.61532 12.1749C1.42498 11.8511 1.30007 11.45 1.20536 10.8379C1.10978 10.2204 1.05057 9.43138 0.969215 8.34055Z"
            stroke="#4B4B4B"
          />
          <path
            d="M21.8299 7.44184C21.9164 6.35141 21.9794 5.56268 22.0779 4.9456C22.1755 4.33404 22.3023 3.93354 22.4942 3.61065C22.8892 2.9459 23.4933 2.43067 24.212 2.14544C24.5611 2.00689 24.9766 1.94483 25.5959 1.94489C26.2208 1.94495 27.0096 2.00718 28.1 2.09371C29.1905 2.18025 29.9792 2.24321 30.5963 2.34171C31.2078 2.43933 31.6083 2.56615 31.9312 2.75802C32.596 3.15304 33.1112 3.75709 33.3964 4.47583C33.535 4.82494 33.597 5.24043 33.597 5.85973C33.5969 6.48462 33.5347 7.27341 33.4482 8.36384C33.3616 9.45427 33.2987 10.243 33.2002 10.8601C33.1025 11.4716 32.9757 11.8721 32.7839 12.195C32.3888 12.8598 31.7848 13.375 31.0661 13.6602C30.7169 13.7988 30.3014 13.8608 29.6821 13.8608C29.0573 13.8607 28.2685 13.7985 27.178 13.712C26.0876 13.6254 25.2989 13.5625 24.6818 13.464C24.0702 13.3664 23.6697 13.2395 23.3468 13.0477C22.6821 12.6526 22.1669 12.0486 21.8816 11.3299C21.7431 10.9807 21.681 10.5653 21.6811 9.94595C21.6811 9.32106 21.7434 8.53227 21.8299 7.44184Z"
            stroke="#4B4B4B"
          />
          <circle
            cx="6.77969"
            cy="7.90735"
            r="1.83848"
            transform="rotate(-4.26531 6.77969 7.90735)"
            stroke="#4B4B4B"
            strokeWidth="1.5"
          />
          <circle
            cx="27.6393"
            cy="7.90281"
            r="1.83848"
            transform="rotate(4.53736 27.6393 7.90281)"
            stroke="#4B4B4B"
            strokeWidth="1.5"
          />
        </svg>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '4rem',
        height: '4rem',
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
      }}
    >
      <svg
        width="62"
        height="59"
        viewBox="0 0 62 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.20875 2.36425C9.16827 1.82149 9.34506 1.28488 9.70023 0.872462C10.0554 0.460049 10.5598 0.205616 11.1026 0.165136C11.6454 0.124656 12.182 0.301445 12.5944 0.656611C13.0068 1.01178 13.2612 1.51623 13.3017 2.05899L11.2552 2.21162L9.20875 2.36425Z"
          fill={black71}
        />
        <path
          d="M48.1495 2.04559C48.1925 1.50303 48.4494 0.999794 48.8635 0.64659C49.2776 0.293386 49.815 0.119147 50.3576 0.162204C50.9001 0.205261 51.4034 0.462086 51.7566 0.876181C52.1098 1.29028 52.284 1.82772 52.241 2.37028L50.1952 2.20794L48.1495 2.04559Z"
          fill={black71}
        />
        <path
          d="M28.73 12.8262H32.8343V33.3478H28.73V12.8262Z"
          fill={black29}
        />
        <path
          d="M22.0605 9.23438H38.9909L38.4779 13.3387H22.5736L22.0605 9.23438Z"
          fill={black29}
        />
        <path
          d="M11.7996 54.3828H50.2776C50.2776 56.9329 48.2104 59.0002 45.6603 59.0002H16.4169C13.8668 59.0002 11.7996 56.9329 11.7996 54.3828Z"
          fill={black29}
        />
        <path
          d="M11.5234 52.3299C11.5234 41.6937 20.1458 33.0713 30.782 33.0713C41.4183 33.0713 50.0406 41.6937 50.0406 52.3299V54.3998C50.0406 56.5259 48.3171 58.2494 46.191 58.2494H15.3731C13.247 58.2494 11.5234 56.5259 11.5234 54.3998V52.3299Z"
          stroke={black71}
          strokeWidth="1.5"
        />
        <circle cx="18.9826" cy="43.6091" r="1.02608" fill={black38} />
        <circle cx="40.0173" cy="43.6091" r="1.02608" fill={black38} />
        <circle cx="18.9826" cy="46.1745" r="1.02608" fill={black38} />
        <circle cx="40.0173" cy="46.1745" r="1.02608" fill={black38} />
        <circle cx="21.5476" cy="46.1745" r="1.02608" fill={black38} />
        <circle cx="42.5825" cy="46.1745" r="1.02608" fill={black38} />
        <circle cx="21.5476" cy="43.6091" r="1.02608" fill={black38} />
        <circle cx="42.5825" cy="43.6091" r="1.02608" fill={black38} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.626 46.1735C24.626 45.6069 25.0854 45.1475 25.6521 45.1475C26.2187 45.1475 26.6781 45.6069 26.6781 46.1735V47.7134H34.8864V46.1735C34.8864 45.6069 35.3458 45.1475 35.9125 45.1475C36.4792 45.1475 36.9385 45.6069 36.9385 46.1735V47.7134H36.939V47.7655C36.939 48.8701 36.0435 49.7655 34.939 49.7655H26.626C25.5214 49.7655 24.626 48.8701 24.626 47.7655V47.7648V47.7134V46.1735Z"
          fill={black29}
        />
        <rect
          x="0.803704"
          y="3.7439"
          width="21.0738"
          height="21.0738"
          rx="7.25"
          transform="rotate(-4.26531 0.803704 3.7439)"
          stroke={black71}
          strokeWidth="1.5"
        />
        <rect
          x="39.6322"
          y="2.12241"
          width="21.0738"
          height="21.0738"
          rx="7.25"
          transform="rotate(4.53736 39.6322 2.12241)"
          stroke={black71}
          strokeWidth="1.5"
        />
        <circle
          cx="12.095"
          cy="13.4672"
          r="3.61737"
          transform="rotate(-4.26531 12.095 13.4672)"
          stroke={black71}
          strokeWidth="2"
        />
        <circle
          cx="49.3021"
          cy="13.4604"
          r="3.61737"
          transform="rotate(4.53736 49.3021 13.4604)"
          stroke={black71}
          strokeWidth="2"
        />
      </svg>
    </Box>
  );
}
