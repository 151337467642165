import React, {useState} from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from 'view/components/icons/Delete';
import UserInfo from 'view/components/UserInfo';
import ActionMenu from 'view/components/ActionMenu';
import {Call, CallStatus} from 'types/calls';
import {getCallDuration, isLastWeek} from 'tools';
import {StyledCallCard} from './styled';
import Typography from '@mui/material/Typography';
import {format, isThisMonth, isToday} from 'date-fns';
import CallIncomingIcon from 'view/components/icons/CallIncoming';
import CallOutgoingIcon from 'view/components/icons/CallOutgoing';
import CallMissedIcon from 'view/components/icons/CallMissed';
import {red} from 'view/theme/colors';
import useAuth from 'hooks/auth';

type ContactBlockProps = {
  call: Call;
  divider?: boolean;
  onClickRemoveCall: React.MouseEventHandler<HTMLLIElement>;
};

export default function CallCard({
  call,
  divider,
  onClickRemoveCall,
}: ContactBlockProps) {
  const user = useAuth();
  const [elevated, setElevated] = useState(false);

  const isOutcomingCall = call?.caller?.username === user.username;
  const contact = isOutcomingCall ? call?.callee : call?.caller;

  const handleMouseEnter = !elevated ? () => setElevated(true) : undefined;
  const handleMouseLeave = () => setElevated(false);

  const handleCallIconStatus = (callStatus: CallStatus) => {
    if (callStatus === CallStatus.DECLINED) {
      return <CallMissedIcon />;
    }

    if (isOutcomingCall) {
      return <CallOutgoingIcon />;
    }
    return <CallIncomingIcon />;
  };

  const handleCallDate = (createdAt: Date) => {
    if (isToday(createdAt)) {
      return format(createdAt, 'h:mm aa');
    }
    if (isLastWeek(createdAt)) {
      return format(createdAt, 'E, h:mm aa');
    }
    if (isThisMonth(createdAt)) {
      return format(createdAt, 'MMM d, h:mm aa');
    }
    return format(createdAt, 'MMM, Y');
  };

  return (
    <>
      <StyledCallCard
        className={`${elevated ? 'elevated' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        inactiveContact={!contact.active}
      >
        <UserInfo
          username={contact.username}
          firstName={contact.firstName}
          lastName={contact.lastName}
          status={contact.status}
          color={call.status === CallStatus.DECLINED ? red : ''}
          inactive={!contact.active}
        />
        <Box display="flex" alignItems="center">
          {call.status !== CallStatus.MISSED && call.startTime && (
            <>
              <Typography variant="body2" noWrap>
                {getCallDuration(call.endTime, call.startTime)}
              </Typography>
              <Typography variant="body2" mx={1}>
                /
              </Typography>
            </>
          )}
          <Typography variant="body2" mr={2} noWrap>
            {handleCallDate(new Date(call.createdAt))}
          </Typography>
          {elevated ? (
            <ActionMenu
              buttonIcon={<MoreVertIcon />}
              handleClose={handleMouseLeave}
              menuItems={[
                {
                  label: 'Delete record',
                  icon: <DeleteIcon />,
                  onClick: (call) => onClickRemoveCall(call),
                  className: 'error',
                },
              ]}
            />
          ) : (
            <Box mr={2}>{handleCallIconStatus(call.status)}</Box>
          )}
        </Box>
      </StyledCallCard>
      {divider && <Divider />}
    </>
  );
}
