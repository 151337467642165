import {unknownStatus, white} from 'view/theme/colors';

export default function StatusUnknownIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="7.99902" r="8" fill={unknownStatus} />
      <path
        d="M6 5.49902C6 5.1012 6.18437 4.71967 6.51256 4.43836C6.84075 4.15706 7.28587 3.99902 7.75 3.99902H8.25C8.71413 3.99902 9.15925 4.15706 9.48744 4.43836C9.81563 4.71967 10 5.1012 10 5.49902C10.0184 5.82365 9.93084 6.14547 9.75048 6.41601C9.57012 6.68655 9.30674 6.89116 9 6.99902C8.69326 7.14284 8.42988 7.41565 8.24952 7.77637C8.06916 8.13709 7.98159 8.56619 8 8.99902"
        stroke={white}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.7988V11.8042"
        stroke={white}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
