import {ReactNode} from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Button from 'view/components/Button';
import ConfirmationWarningIcon from 'view/components/icons/ConfirmationWarning';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogIcon,
} from './styled';
import {black54} from 'view/theme/colors';

type ConfirmationDialogProps = {
  open: boolean;
  text: string;
  confirmText: string;
  color?: 'primary' | 'error';
  icon?: ReactNode;
  children?: React.ReactNode;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
};

export default function ConfirmationDialog({
  open = false,
  text,
  icon,
  color = 'error',
  confirmText,
  onConfirm,
  onClose,
  children = null,
}: ConfirmationDialogProps) {
  return (
    <StyledDialog
      open={open}
      aria-describedby="confirmation-dialog-description"
    >
      <StyledDialogIcon>
        {icon || <ConfirmationWarningIcon color={color} />}
      </StyledDialogIcon>
      <Box position="absolute" top={8} right={8}>
        <IconButton
          color="inherit"
          onClick={onClose}
          aria-label="clear-search"
          disableRipple
          disableFocusRipple
          sx={{
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent',
              color: black54,
            },
          }}
        >
          <ClearOutlinedIcon />
        </IconButton>
      </Box>
      <StyledDialogContent>
        <DialogContentText id="confirmation-dialog-description">
          <Typography
            variant="body1"
            align="center"
            fontWeight={500}
            sx={{wordBreak: 'break-all'}}
          >
            {text}
          </Typography>
        </DialogContentText>
        {children}
      </StyledDialogContent>
      <StyledDialogActions disableSpacing>
        <Button
          type="submit"
          label={confirmText}
          color={color}
          onClick={onConfirm}
          fullWidth
        />
        <Button variant="text" label="No, cancel" onClick={onClose} fullWidth />
      </StyledDialogActions>
    </StyledDialog>
  );
}
