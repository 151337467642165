import {CSSProperties} from 'react';
import {black} from 'view/theme/colors';

type ChevronDownIconProps = {
  style?: CSSProperties;
};

export default function ChevronDownIcon({style}: ChevronDownIconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={style}>
      <path
        d="M4 6L8 10L12 6"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
