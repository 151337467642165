import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from 'view/components/Button';
import TrashIcon from 'view/components/icons/Trash';
import {black54, black87} from 'view/theme/colors';
import {StyledFiltersContainer} from './styled';

export type FiltersData = Record<
  string,
  {
    label: string;
    options: Record<string, {label: string; value: string; checked: boolean}>;
  }
>;

type FiltersProps = {
  data: FiltersData;
  onClear: () => void;
  setFilters: (data: FiltersData) => void;
};

const Filters = ({data, onClear, setFilters}: FiltersProps) => {
  return (
    <StyledFiltersContainer>
      <Box
        pt="3.5rem"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2" mr={2}>
          Filters
        </Typography>
        <Button
          label="Clear"
          color="inherit"
          className="clear-filters"
          variant="text"
          endIcon={<TrashIcon color={black54} size={16} />}
          onClick={onClear}
        />
      </Box>
      <Box>
        {Object.keys(data).map((filter: string) => (
          <FormControl
            key={filter}
            sx={{marginTop: '2.5rem'}}
            component="fieldset"
            variant="standard"
          >
            <FormLabel
              component="legend"
              sx={{
                color: black54,
                fontSize: '0.75rem',
                fontWeight: 500,
                lineHeight: '16px',
                marginBottom: '1.25rem',
              }}
            >
              {data[filter].label}
            </FormLabel>
            <FormGroup>
              {Object.keys(data[filter].options).map((option: string) => (
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={data[filter].options[option].checked}
                      onChange={() => {
                        setFilters({
                          ...data,
                          [filter]: {
                            ...data[filter],
                            options: {
                              ...data[filter].options,
                              [option]: {
                                ...data[filter].options[option],
                                checked: !data[filter].options[option].checked,
                              },
                            },
                          },
                        });
                      }}
                      name={data[filter].options[option].value}
                    />
                  }
                  label={
                    <Typography variant="body2" color={black87}>
                      {data[filter].options[option].label}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        ))}
      </Box>
    </StyledFiltersContainer>
  );
};

export default Filters;
