import * as React from 'react';
import MuiTablePagination from '@mui/material/TablePagination';
import Pagination from '@mui/material/Pagination';
import {black40, black87} from 'view/theme/colors';

interface TablePaginationActionsProps {
  page: number;
  rowsPerPage: number;
  totalPages: number;
  totalItems: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

export default function TablePagination(props: TablePaginationActionsProps) {
  const hidePrevButton = props.totalPages === 1 || props.page === 1;
  const hideNextButton =
    props.totalPages === 1 || props.page === props.totalPages;

  return (
    <MuiTablePagination
      sx={{
        display: 'flex',
        marginTop: '1.5rem',
        '.MuiTablePagination-toolbar': {
          padding: 0,
          justifyContent: 'space-between',
          display: 'flex',
          flex: 1,
        },
        '.MuiTablePagination-spacer': {display: 'none'},
        padding: 0,
        borderBottom: 'none',
      }}
      rowsPerPageOptions={[0]}
      onPageChange={() => null}
      count={props.totalItems}
      rowsPerPage={props.rowsPerPage}
      page={props.page - 1}
      ActionsComponent={() => (
        <Pagination
          sx={{
            '.MuiPagination-ul': {justifyContent: 'end'},
            '.MuiPaginationItem-root': {
              color: black40,
              '&.Mui-selected': {
                color: black87,
              },
            },
          }}
          hidePrevButton={hidePrevButton}
          hideNextButton={hideNextButton}
          count={props.totalPages}
          page={props.page}
          onChange={props.onPageChange as any}
        />
      )}
    />
  );
}
