import styled from '@emotion/styled';
import {white} from 'view/theme/colors';
import {Badge} from '@mui/material';
import Avatar from 'view/components/Avatar';
import StatusUnknownIcon from 'view/components/icons/StatusUnknown';
import StatusTooltip from 'view/components/UserInfo/StatusTooltip';
import {UserStatus} from 'types/users';
import {userStatuses} from '../../../constants';

const StyledBadge = styled(Badge)`
  .MuiBadge-overlapCircular {
    background: ${white};
    padding: 0.15rem;
  }
`;

type UserAvatarProps = {
  firstName: string;
  lastName: string;
  status?: UserStatus;
  size?: number;
  showStatus?: boolean;
  inactive?: boolean;
};

export default function UserAvatar({
  firstName,
  lastName,
  status = UserStatus.OFFLINE,
  size,
  showStatus = true,
  inactive,
}: UserAvatarProps) {
  const showStatusBadge = showStatus && !inactive;

  const handleStatusIcon = (status: UserStatus) => {
    if (!status) {
      return <StatusUnknownIcon />;
    }

    const statusObject = userStatuses.find(
      (statusData) => statusData.label === status,
    );
    return statusObject?.icon ? statusObject.icon : <StatusUnknownIcon />;
  };

  const avatar = (
    <Avatar
      firstName={firstName}
      lastName={lastName}
      size={size}
      inactive={inactive}
    />
  );

  if (showStatusBadge) {
    return (
      <StyledBadge
        overlap="circular"
        badgeContent={
          <StatusTooltip userStatus={status}>
            {handleStatusIcon(status)}
          </StatusTooltip>
        }
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        {avatar}
      </StyledBadge>
    );
  }

  return avatar;
}
