import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {white} from 'view/theme/colors';

export const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    border: none;
  }

  .MuiButtonBase-root.close-drawer {
    background: ${white};
    border-radius: 0.25rem;

    &:hover {
      background: ${white};
    }
  }
`;

export const StylePageHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiTypography-h1 {
    line-height: 2rem;
  }
` as typeof Box;
