import {createContext, ReactElement, useCallback, useState} from 'react';
import {User} from 'types/users';
import {DeviceWithUser} from 'types/devices';
import {getUserInfo as getUserInfoApi} from 'services/api/admin/user';
import {getDeviceInfo as getDeviceInfoApi} from 'services/api/admin/devices';
import {HistoryType} from 'types/admin';
import {getLastArrayElement} from 'tools';
import {getDeviceInfoMap} from 'services/api/admin/map';
import {DevicesInfoProps} from 'types/map';

export const AdminContext = createContext({
  drawerHistory: [] as DrawerHistory[],
  deviceInfoMap: {} as DevicesInfoProps | undefined,
  selectUserId: (userId: string | '') => {},
  selectDeviceId: (deviceId: string | '') => {},
  setDrawerHistory: (item: DrawerHistory[]) => {},
  selectMapDeviceId: (id?: string) => {},
});

type Props = {
  children: ReactElement;
};

type DrawerHistory = {
  selectedItem:
    | (User & {drawerType: HistoryType})
    | (DeviceWithUser & {drawerType: HistoryType})
    | null;
};

export const AdminProvider = ({children}: Props) => {
  const [drawerHistory, setDrawerHistory] = useState<DrawerHistory[] | []>([]);
  const [deviceInfoMap, setDeviceInfoMap] = useState<
    DevicesInfoProps | undefined
  >();

  const getUserInfo = useCallback(async (userId: string) => {
    try {
      const userInfo = await getUserInfoApi(userId);

      setDrawerHistory((prevState) => {
        const existingItem = prevState.find(({selectedItem}) => {
          return selectedItem?.id === userId;
        });
        const {selectedItem} = getLastArrayElement(prevState) || {};

        if (!existingItem || selectedItem?.id !== userId) {
          return [
            ...prevState,
            {selectedItem: {...userInfo, drawerType: HistoryType.USER}},
          ];
        }

        return prevState.map(({selectedItem}) => {
          if (selectedItem?.id === userId) {
            return {selectedItem: {...selectedItem, ...userInfo}};
          }
          return {selectedItem};
        });
      });
    } catch (err) {
      if (err instanceof Error) {
        console.error(err);
      }
    }
  }, []);

  const getDeviceInfo = useCallback(async (deviceId: string) => {
    try {
      const deviceInfo = await getDeviceInfoApi(deviceId);
      setDrawerHistory((prevState) => [
        ...prevState,
        {selectedItem: {...deviceInfo, drawerType: HistoryType.DEVICE}},
      ]);
    } catch (err) {
      if (err instanceof Error) {
        console.error(err);
      }
    }
  }, []);

  const selectDeviceId = async (deviceId: string) => {
    if (deviceId) {
      await getDeviceInfo(deviceId);
    }
  };

  const selectUserId = async (userId: string) => {
    if (userId) {
      await getUserInfo(userId);
    }
  };

  const selectMapDeviceId = async (id?: string) => {
    try {
      if (id) {
        const mapDeviceInfo = await getDeviceInfoMap(id);
        if (mapDeviceInfo) {
          setDeviceInfoMap(mapDeviceInfo);
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        console.error(err);
      }
    }
  };

  const value = {
    drawerHistory,
    deviceInfoMap,
    selectUserId,
    selectDeviceId,
    setDrawerHistory,
    selectMapDeviceId,
  };

  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};
