import React, {useState} from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import DeviceInfo from 'view/components/DeviceInfo';
import Button from 'view/components/Button';
import {StyledDeviceCard} from '../styled';
import XCircleWhiteIcon from 'view/components/icons/XCirlceWhite';
import EditIcon from 'view/components/icons/Edit';
import CheckCircleIcon from 'view/components/icons/CheckCircle';
import ChangeDeviceNameDialog from 'view/pages/Devices/components/ChangeDeviceName';
import {Device} from 'types/devices';
import IconButton from '@mui/material/IconButton';

type DeviceCardProps = {
  device: Device;
  divider?: boolean;
  onClickDisconnectDevice: (device: Device) => void;
  onClickChangeDeviceName: (id: string, device: string) => void;
};

export default function LargeSizeDeviceCard({
  device,
  onClickDisconnectDevice,
  divider,
  onClickChangeDeviceName,
}: DeviceCardProps) {
  const [label, setLabel] = useState('Connected');
  const [endIcon, setEndIcon] = useState(<CheckCircleIcon />);
  const [isChangeDeviceNameDialogOpen, setIsChangeDeviceNameDialogOpen] =
    useState(false);

  const handleMouseEnter = () => {
    setLabel('Disconnect');
    setEndIcon(<XCircleWhiteIcon />);
  };
  const handleMouseLeave = () => {
    setLabel('Connected');
    setEndIcon(<CheckCircleIcon />);
  };

  return (
    <>
      <ChangeDeviceNameDialog
        displayName={device.displayName}
        open={isChangeDeviceNameDialogOpen}
        onVerify={(deviceName: string) => {
          onClickChangeDeviceName(device.id, deviceName);
          setIsChangeDeviceNameDialogOpen(false);
        }}
        onClose={() => setIsChangeDeviceNameDialogOpen(false)}
      />
      <StyledDeviceCard>
        <DeviceInfo
          name={device.displayName}
          id={device.id}
          type={device.deviceType}
          status={device.status}
          createdAt={device.createdAt}
        />
        <Box display="flex">
          <Box mr={1}>
            <IconButton
              color="inherit"
              onClick={() => setIsChangeDeviceNameDialogOpen(true)}
              aria-label="back"
              disableRipple
              disableFocusRipple
            >
              <EditIcon size={20} />
            </IconButton>
          </Box>
          <Button
            label={label}
            color="secondary"
            endIcon={endIcon}
            fullWidth
            onClick={() => onClickDisconnectDevice(device)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </Box>
      </StyledDeviceCard>
      {divider && <Divider />}
    </>
  );
}
