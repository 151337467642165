import React, {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import CallsList from 'view/pages/Callbook/components/CallsList';
import PageHeader from 'view/components/PageHeader';
import Loader from 'view/components/Loader';
import Alert from 'view/components/Alert';
import Search from 'view/components/Search';
import {Call} from 'types/calls';
import {
  listCalls,
  listSearchCalls,
  removeCall as removeCallApi,
} from 'services/api/user';
import {StyledContainer} from './styled';
import {PaginatedResponse} from 'services/api/types';
import debounce from 'lodash.debounce';
import useUser from 'hooks/user';
import axios, {AxiosError} from 'axios';

export default function Callbook() {
  const {callsData, setCallsData, removeCall} = useUser();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResult, setSearchResult] = useState<PaginatedResponse<Call>>({
    items: [] as Call[],
  } as PaginatedResponse<Call>);

  const handleRemoveCall = async (call: Call) => {
    try {
      await removeCallApi(call.id);
      removeCall(call.id);
    } catch (err) {
      console.error(err);

      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const fetchNextCalls = async (nextLink?: string) => {
    if (nextLink) {
      try {
        const callsData = await listCalls(nextLink);
        setCallsData(callsData);
      } catch (err) {
        console.error(err);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchCalls = useCallback(
    debounce(async (searchQuery: string) => {
      setLoading(true);

      try {
        const searchResult = await listSearchCalls({name: searchQuery});
        setSearchResult(searchResult);
        setLoading(false);
      } catch (err) {
        if (err instanceof Error) {
          console.error(err);
          setError(err.message);
          setLoading(false);
        }
      }
    }, 500),
    [],
  );

  const fetchNextSearchCalls = async (nextLink?: string) => {
    if (nextLink) {
      try {
        const searchResult = await listSearchCalls({
          url: nextLink,
          name: searchQuery,
        });
        setSearchResult(({items}) => ({
          ...searchResult,
          items: [...items, ...searchResult.items],
        }));
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (searchQuery) {
      setLoading(true);
      searchCalls(searchQuery);
    }
  }, [searchQuery, searchCalls]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(e.target.value);

  const onClearSearch = () => setSearchQuery('');

  return (
    <StyledContainer component="main">
      <PageHeader
        title="Callbook"
        action={
          <Box sx={{width: '20rem'}}>
            <Search
              name="callbook-search"
              small
              value={searchQuery}
              label="Search people"
              onChange={onSearchChange}
              onClear={onClearSearch}
            />
          </Box>
        }
      />
      <Alert
        open={!!error}
        type="error"
        label={error}
        position="right"
        onClose={() => setError('')}
      />
      {loading ? (
        <Loader justify="flex-start" />
      ) : searchQuery ? (
        <CallsList
          calls={searchResult?.items}
          fetchNextCalls={() => fetchNextSearchCalls(searchResult?.links?.next)}
          allCallsLoaded={!!searchResult?.links?.next}
          onClickRemoveCall={(call: Call) => handleRemoveCall(call)}
        />
      ) : (
        <CallsList
          calls={callsData?.items}
          fetchNextCalls={() => fetchNextCalls(callsData?.links?.next)}
          allCallsLoaded={!!callsData?.links?.next}
          onClickRemoveCall={(call: Call) => handleRemoveCall(call)}
        />
      )}
    </StyledContainer>
  );
}
