import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {black40} from 'view/theme/colors';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 0.5rem;
    padding: 1.5rem 1.5rem 1rem;
    max-width: 26.75rem;
    min-width: 26.75rem;
  }
`;

export const StyledDialogIcon = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const StyledDialogActions = styled(DialogActions)`
  flex-direction: column;
  padding: 0;
  margin-top: 2rem;

  .MuiButton-text {
    color: ${black40};
    margin-top: 0.5rem;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  white-space: pre-wrap;
`;
