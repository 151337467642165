import {ReactNode} from 'react';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ChevronIcon from 'view/components/icons/Chevron';
import {StyledLabel} from 'view/pages/AccountSettings/components/UserInfoRow/styled';

type DeviceInfoListItemProps = {
  children: ReactNode;
  label: string;
  divider?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export default function DeviceInfoListItem({
  label,
  children,
  divider = false,
  onClick,
}: DeviceInfoListItemProps) {
  return (
    <>
      <ListItem
        sx={{padding: typeof children === 'string' ? '24px 0' : '16px 0'}}
        disableGutters
        secondaryAction={
          onClick && (
            <IconButton disableRipple onClick={onClick}>
              <ChevronIcon />
            </IconButton>
          )
        }
      >
        <StyledLabel>{label}</StyledLabel>
        <ListItemText
          primary={children}
          sx={{margin: 0}}
          primaryTypographyProps={{
            variant: 'body1',
            fontSize: '16px',
            fontWeight: 500,
          }}
        />
      </ListItem>
      {divider && <Divider />}
    </>
  );
}
