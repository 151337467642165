import {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import MuiAlert, {AlertColor} from '@mui/material/Alert';
import Snackbar, {SnackbarCloseReason} from '@mui/material/Snackbar';
import {red, green, black87} from 'view/theme/colors';

const StyledAlert = styled(MuiAlert)`
  &.MuiAlert-root {
    width: 27.5rem;
    border-radius: 0.5rem;
    align-items: center;

    & .MuiIconButton-root {
      color: white;

      &:hover {
        background: transparent;
      }
    }
  }

  &.MuiAlert-filledError {
    background: ${red};
  }

  &.MuiAlert-filledInfo {
    background: ${black87};
  }

  &.MuiAlert-filledSuccess {
    background: ${green};
  }
`;

type ButtonProps = {
  open: boolean;
  label?: string;
  type: AlertColor;
  position?: 'right' | 'center';
  onClose?: () => void;
};

export default function Alert({
  open: propOpen,
  label,
  position = 'center',
  type,
  onClose,
}: ButtonProps) {
  const [open, setOpen] = useState(propOpen);

  useEffect(() => {
    setOpen(propOpen);
  }, [propOpen]);

  const closeHandler = (
    _event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') return;
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{vertical: 'top', horizontal: position}}
      autoHideDuration={6000}
      onClose={closeHandler}
    >
      <StyledAlert severity={type} variant="filled" onClose={closeHandler}>
        {!label && open && type === 'error' ? 'Something went wrong' : label}
      </StyledAlert>
    </Snackbar>
  );
}
