import styled from '@emotion/styled';
import Menu from '@mui/material/Menu';
import {
  black54,
  black87,
  lightPurple,
  lightRed,
  purple,
  red,
} from 'view/theme/colors';

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    border-radius: 0.5rem;
  }

  .MuiList-root {
    padding: 0.5rem;
  }

  li {
    border-radius: 0.25rem;

    &.primary,
    &.error {
      p {
        color: ${black87};
      }
      svg {
        margin-right: 0.5rem;

        path {
          stroke: ${black54};
        }
      }
    }
    &.primary {
      &:hover {
        background: ${lightPurple};
        border-color: ${red};

        p {
          color: ${purple};
        }

        svg path {
          stroke: ${purple};
        }
      }
    }

    &.error {
      &:hover {
        background: ${lightRed};

        p {
          color: ${red};
        }

        svg path {
          stroke: ${red};
        }
      }
    }
  }
`;
