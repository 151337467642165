import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box} from '@mui/material';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import {ChangeNameValidationSchema} from 'constraints';
import FormHeader from 'view/components/Form/Header';
import {updateUser} from 'services/api/admin/user';
import {User} from 'types/users';
import Alert from 'view/components/Alert';
import {StyledFormContainer, StyledFormActions} from '../../styled';
import useAuth from 'hooks/auth';
import axios, {AxiosError} from 'axios';

type ChangeNameFormData = {
  firstName: string;
  lastName: string;
};

type ChangeNameProps = {
  user: User;
  onCancel: () => void;
  onSaved: (user: User) => void;
};

export default function ChangeName({user, onCancel, onSaved}: ChangeNameProps) {
  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);
  const {setName, username: adminUsername} = useAuth();

  const {username, firstName, lastName} = user;

  const initialFormData = {
    resolver: yupResolver(ChangeNameValidationSchema),
    defaultValues: {
      firstName,
      lastName,
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm<ChangeNameFormData>(initialFormData);

  const onSubmit = async (data: ChangeNameFormData) => {
    setError('');
    setSaving(true);

    try {
      const update = await updateUser(user.id, data);

      if (adminUsername === username) {
        setName(data);
      }

      onSaved(update);
    } catch (err) {
      console.error(err);

      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setError(error.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setSaving(false);
    }
  };

  const isValuesChanged =
    firstName !== watch('firstName') || lastName !== watch('lastName');
  const submitDisabled =
    Boolean(Object.keys(errors).length) || !isValuesChanged || saving;

  return (
    <>
      <Alert open={!!error} type="error" label={error} position="right" />
      <StyledFormContainer>
        <FormHeader
          title="Full Name"
          description="Change users full name and save"
          mb={5}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" mb={2}>
            <Input
              name="firstName"
              label="First name"
              error={!!errors.firstName?.message}
              helperText={errors.firstName?.message}
              fullWidth
              control={control}
              mb={3}
            />
            <Input
              name="lastName"
              label="Last name"
              error={!!errors.lastName?.message}
              helperText={errors.lastName?.message}
              fullWidth
              control={control}
            />
          </Box>
          <StyledFormActions>
            <Button
              type="submit"
              label="Save"
              className="save-button"
              disabled={submitDisabled}
            />
            <Button
              label="Cancel"
              variant="text"
              className="cancel-button"
              onClick={onCancel}
            />
          </StyledFormActions>
        </form>
      </StyledFormContainer>
    </>
  );
}
