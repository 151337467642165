import React from 'react';
import StatusInfoIcon from 'view/components/icons/StatusInfo';
import StatusSuccessIcon from 'view/components/icons/StatusSuccess';
import StatusWarningIcon from 'view/components/icons/StatusWarning';
import StatusErrorIcon from 'view/components/icons/StatusError';
import StatusUnknownIcon from 'view/components/icons/StatusUnknown';
import {UserStatus} from 'types/users';

export const userStatuses = [
  {
    label: UserStatus.KEEN_TO_MEET,
    icon: <StatusInfoIcon />,
    text: `I'm ready to talk, you can call me`,
    color: 'primary',
  },
  {
    label: UserStatus.AVAILABLE,
    icon: <StatusSuccessIcon />,
    text: `I'm ready available to talk`,
    color: 'success',
  },
  {
    label: UserStatus.AVAILABLE_IF_NEEDED,
    icon: <StatusWarningIcon />,
    text: `I'm available if needed`,
    color: 'warning',
  },
  {
    label: UserStatus.NOT_AVAILABLE,
    icon: <StatusErrorIcon />,
    text: `I'm not available`,
    color: 'error',
  },
  {
    label: UserStatus.OFFLINE,
    icon: <StatusUnknownIcon />,
    text: 'User is offline',
    color: 'secondary',
  },
];

export const mapMarkersGroupsAccuracy = 5;
