import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ChevronIcon from 'view/components/icons/Chevron';

type BackButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
};

export default function BackButton({onClick, style = {}}: BackButtonProps) {
  const defaultBackButtonStyles = {top: 12, left: -55};
  const backButtonStyle = {...defaultBackButtonStyles, ...style};
  return (
    <Box position="absolute" style={backButtonStyle}>
      <IconButton
        color="inherit"
        onClick={onClick}
        aria-label="back"
        disableRipple
        disableFocusRipple
        sx={{background: 'transparent'}}
      >
        <ChevronIcon left large />
      </IconButton>
    </Box>
  );
}
