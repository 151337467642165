import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ChevronIcon from 'view/components/icons/Chevron';
import {StyledLabel} from './styled';
import {UserInfoRowType} from '../..';

const renderValue = (
  value: string | JSX.Element | null,
  type?: UserInfoRowType,
) => {
  if (type === 'password') {
    return '••••••••••••••';
  }

  if (type === 'username') {
    return `@${value}`;
  }

  return value;
};

type UserInfoRowProps = {
  type: UserInfoRowType;
  value: string | JSX.Element | null;
  label: string;
  divider?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export default function UserInfoRow({
  type,
  label,
  value,
  divider = false,
  onClick,
}: UserInfoRowProps) {
  return (
    <>
      <ListItem
        sx={{padding: '24px 0'}}
        disableGutters
        secondaryAction={
          onClick && (
            <IconButton disableRipple onClick={onClick}>
              <ChevronIcon />
            </IconButton>
          )
        }
      >
        <StyledLabel>{label}</StyledLabel>
        <ListItemText
          primary={renderValue(value, type)}
          primaryTypographyProps={{
            variant: 'body1',
            style: {fontWeight: 500},
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        />
      </ListItem>
      {divider && <Divider />}
    </>
  );
}
