import {ReactElement} from 'react';
import {Navigate} from 'react-router-dom';
import Loader from 'view/components/Loader';
import {SIGNIN_PATH, APP_PATH} from 'view/routes';
import useAuth from 'hooks/auth';
import useUser from 'hooks/user';

type Props = {
  children: ReactElement;
};

export default function AdminRoute({children}: Props) {
  const {isLoading: isAuthLoading, isAuthenticated, isAdmin} = useAuth();
  const {isLoading} = useUser();

  if (isAuthLoading || isLoading) {
    return <Loader height="100vh" />;
  }

  if (!isAuthenticated) {
    return <Navigate to={SIGNIN_PATH} />;
  }

  if (!isAdmin) {
    return <Navigate to={APP_PATH} />;
  }

  return children;
}
